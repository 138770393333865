import React from "react";
import { FormattedMessage } from "react-intl";
import { allLanguages } from "../../../utils/languageUtils";
import { CustomSelect, OptionItem } from "../../form/CustomSelect";

const msgKey = "languageSelect";

export const LanguageSelect = ({ value, onChange, menuPlacement, placeholder }) => {
    return (
        <CustomSelect
            value={value}
            placeholder={placeholder || <FormattedMessage id={`${msgKey}.selectLanguage`} />}
            onChange={onChange}
            isClearable
            isSearchable
            menuPlacement={menuPlacement}
            filterOption={(option, searchTerm) => {
                if (!searchTerm || !searchTerm.length) return true;
                const language = allLanguages.find((l) => l.code === option.value);
                return (
                    language.nativeName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    language.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    language.code.toLowerCase().includes(searchTerm.toLowerCase())
                );
            }}
        >
            {allLanguages.map((language) => (
                <OptionItem key={language.code} value={language.code} label={language.nativeName} />
            ))}
        </CustomSelect>
    );
};
