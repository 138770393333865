import React from "react";
import { Editor, Element as SlateElement, Transforms } from "slate";
import { useSlate } from "slate-react";
import { MenuItem } from "../../../../nav/Menu";

export const BlockItem = ({ format, icon, label, onClick, hidden }) => {
    const editor = useSlate();
    const LIST_TYPES = ["numberedList", "bulletedList"];
    const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

    const isBlockActive = (editor, format, blockType = "type") => {
        const { selection } = editor;
        if (!selection) return false;

        const [match] = Array.from(
            Editor.nodes(editor, {
                at: Editor.unhangRange(editor, selection),
                match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format
            })
        );

        return !!match;
    };

    const toggleBlock = (editor, format) => {
        const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? "align" : "type");
        const isList = LIST_TYPES.includes(format);

        Transforms.unwrapNodes(editor, {
            match: (n) =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                LIST_TYPES.includes(n.type) &&
                !TEXT_ALIGN_TYPES.includes(format),
            split: true
        });
        let newProperties;
        if (TEXT_ALIGN_TYPES.includes(format)) {
            newProperties = {
                align: isActive ? undefined : format
            };
        } else {
            newProperties = {
                type: isActive ? "paragraph" : isList ? "listItem" : format
            };
        }
        Transforms.setNodes(editor, newProperties);

        if (!isActive && isList) {
            const block = { type: format, children: [] };
            Transforms.wrapNodes(editor, block);
        }
    };

    return React.createElement(MenuItem, {
        label,
        icon,
        selected: isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"),
        hidden,
        onClick: () => {
            toggleBlock(editor, format);
            onClick && onClick();
        }
    });
};
