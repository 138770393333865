import React from "react";
import classNames from "classnames";
import style from "./task-card.scss";

import { Icon } from "../../../graphic/Icon";
import RiskMeter from "../../../gauge/RiskMeter";
import { Box } from "../../../box/Box";

export const TaskCard = ({ task, rank, number, onClick, preview }) => {
    return (
        <Box
            className={classNames(style.base, {
                [style.preview]: preview
            })}
            onClick={() => (!preview ? onClick(task) : null)}
        >
            <div className={style.number}>{number}</div>
            <div className={style.meter}>
                <div className={style.wrapper}>
                    <RiskMeter
                        level={task.riskLevel === 3 ? "high" : task.riskLevel === 2 ? "mid" : "low"}
                        percent={rank && rank.percent}
                    />
                </div>
            </div>
            <div className={style.text}>
                <div className={style.wrapper}>{task.text}</div>
            </div>
            <div className={style.arrow}>
                <Icon name="arrow-down" />
            </div>
        </Box>
    );
};
