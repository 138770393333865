import React from "react";
import ReactTypewriter from "typewriter-effect";

export const Typewriter = ({ text, delay = 10 }) => {
    return (
        <ReactTypewriter
            options={{
                cursor: null,
                delay
            }}
            onInit={(typewriter) => {
                typewriter.typeString(text).start();
            }}
        />
    );
};
