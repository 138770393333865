import React from "react";
import styles from "./CenterBox.scss";

import { ShieldLogo } from "../../graphic/ShieldLogo";

export const CenterBox = ({ header, children }) => {
    return (
        <div className={styles.base}>
            <div className={styles.header}>
                <div className={styles.logoFrame}>
                    {header || <ShieldLogo className={styles.logo} color="contrast" />}
                </div>
            </div>
            <div className={styles.box}>{children}</div>
        </div>
    );
};
