import classNames from "classnames";
import React from "react";
import { Figure } from "../../graphic/Figure";
import styles from "./CourseScoreBadge.scss";

export const CourseScoreBadge = ({ scoreMax, scoreSum, size }) => {
    const percentScore = scoreMax ? Math.round((scoreSum / scoreMax) * 100) : 0;
    return (
        <div
            className={classNames(styles.scoreBadge, {
                [styles.sizeSmall]: size === "small"
            })}
        >
            <div
                className={classNames(styles.stars, {
                    [styles.level3]: !scoreMax || percentScore === 100,
                    [styles.level2]: percentScore < 100 && percentScore >= 50,
                    [styles.level1]: percentScore < 50
                })}
            >
                <div className={styles.star}>
                    <Figure className={styles.colored} name="star" />
                    <Figure className={styles.outline} name="star-empty" />
                </div>
                <div className={styles.star}>
                    <Figure className={styles.colored} name="star" />
                    <Figure className={styles.outline} name="star-empty" />
                </div>
                <div className={styles.star}>
                    <Figure className={styles.colored} name="star" />
                    <Figure className={styles.outline} name="star-empty" />
                </div>
            </div>
        </div>
    );
};
