import classNames from "classnames";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { createEditor } from "slate";
import { withHistory } from "slate-history";
import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import viewStyles from "../RichTextView/RichTextView.scss";
import { renderElement } from "./elements";
import { LinkPrompt } from "./link/LinkPrompt";
import { Leaf } from "./parts/Leaf";
import styles from "./RichTextEditor.scss";
import { withTable } from "./table/withTable";
import { Toolbar } from "./toolbar/Toolbar";
import { htmlToSlateNodes, slateNodesToHtml } from "./utils/serialization";
import { withCustom } from "./withCustom";
import CodeEditor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-markup";
import "prismjs/themes/prism.css";
import { AuthContext } from "../../../contexts/Auth";
import { html_beautify } from "js-beautify";
import { enterKeyHandler } from "./keyHandler";
import { imageKeyHandler, withImage } from "./image/keyHandler";
import { tableKeyHandler } from "./table/keyHandler";

export const msgKey = "richTextEditor";
export const EditorContext = React.createContext();

const RichTextEditor = ({ html, onChange, onImageUpload, onImageDelete }) => {
    const { me } = useContext(AuthContext);
    const [showToolbar, setShowToolbar] = useState(false);
    const [showPrompt, setShowPrompt] = useState(null);
    const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
    const editor = useMemo(() => withCustom(withTable(withImage(withHistory(withReact(createEditor()))))), []);
    const initialValue = useMemo(() => htmlToSlateNodes(html), [html]);
    const [mode, setMode] = useState("WYSIWYG");

    const handleChange = (value) => {
        const updatedHtml = slateNodesToHtml(value);
        onChange(updatedHtml);
    };

    const handleShowPrompt = (type) => {
        setShowPrompt(type);
    };

    const handleClosePrompt = () => {
        ReactEditor.focus(editor);
        setShowPrompt(null);
    };
    useEffect(() => {
        const onKeyDown = (e) => {
            if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
                if (me.user.roles.includes("admin")) {
                    e.preventDefault();
                    setMode(mode === "WYSIWYG" ? "HTML" : "WYSIWYG");
                }
            }
        };
        document.addEventListener("keydown", onKeyDown);
        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [mode, me.user.roles]);

    return (
        <EditorContext.Provider
            value={{
                showToolbar,
                setShowToolbar,
                showPrompt,
                setShowPrompt: handleShowPrompt,
                onImageUpload,
                onImageDelete
            }}
        >
            <div className={styles.base}>
                {mode === "WYSIWYG" ? (
                    <Slate editor={editor} initialValue={initialValue} onChange={handleChange}>
                        <Toolbar />
                        {showPrompt === "link" && <LinkPrompt onClose={handleClosePrompt} />}
                        <Editable
                            placeholder={<FormattedMessage id={`${msgKey}.startTyping`} />}
                            renderElement={renderElement}
                            renderLeaf={renderLeaf}
                            onKeyDown={(e) => {
                                enterKeyHandler(editor, e);
                                imageKeyHandler(editor, e);
                                tableKeyHandler(editor, e);
                            }}
                            className={classNames(styles.editor, viewStyles.html)}
                            spellCheck
                            autoFocus
                        />
                    </Slate>
                ) : (
                    <CodeEditor
                        value={html_beautify(html)}
                        onValueChange={onChange}
                        highlight={(code) => highlight(code, languages.html)}
                        padding={10}
                        style={{
                            fontFamily: '"Fira code", "Fira Mono", monospace',
                            fontSize: 14
                        }}
                    />
                )}
            </div>
        </EditorContext.Provider>
    );
};

export default RichTextEditor;
