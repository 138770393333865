import React, { useContext, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import SignaturePadLib from "signature_pad";
import { AppContext } from "../../../App";
import { Button } from "../../button/Button";
import { Col } from "../../grid/Col/Col";
import { Row } from "../../grid/Row/Row";
import styles from "./SignaturePad.scss";

const msgKey = "signaturePad";

export const SignaturePad = ({ onChange }) => {
    const { pageInfo } = useContext(AppContext);
    const baseRef = useRef(null);
    const canvasRef = useRef(null);
    const padRef = useRef(null);

    useEffect(() => {
        const instance = new SignaturePadLib(canvasRef.current, {
            minWidth: 0.5,
            maxWidth: 2,
            penColor: "#0554ff"
        });
        instance.addEventListener("endStroke", handleChange);
        padRef.current = instance;
        handleResize();
        return () => {
            instance.removeEventListener("endStroke", handleChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = () => {
        onChange && onChange(padRef.current?.toDataURL());
    };

    const handleClear = () => {
        padRef.current?.clear();
        onChange && onChange(null);
    };

    const handleResize = () => {
        const canvas = canvasRef.current;
        canvas.width = 0;
        const ratio = 1; //Math.max(window.devicePixelRatio || 1, 1);
        const dimensions = baseRef.current.getBoundingClientRect();
        canvas.width = dimensions.width * ratio;
        canvas.height = dimensions.width * 0.5 * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
        padRef.current?.clear();
    };
    useEffect(handleResize, [pageInfo]);

    return (
        <div ref={baseRef} className={styles.base}>
            <div className={styles.measure}></div>
            <div className={styles.pad}>
                <canvas ref={canvasRef}></canvas>
            </div>
            <div className={styles.controls}>
                <Row gutter={["tiny", "tiny"]}>
                    <Col grow align={["left", "center"]}>
                        <div className={styles.label}>
                            <FormattedMessage id={`${msgKey}.sign`} />
                        </div>
                    </Col>
                    <Col shrink align={["right", "top"]}>
                        <Button color="silent" size="small" onClick={handleClear}>
                            <FormattedMessage id={`${msgKey}.erase`} />
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
