import React, { useContext, useState } from "react";
import styles from "./MeMenu.scss";
import classNames from "classnames";
import Image from "../../../media/Image";
import { Clickable } from "../../../button/Clickable";
import { Menu, MenuItem } from "../../../nav/Menu";
import { Restricted } from "../../../security/Restricted";
import { AuthContext } from "../../../../contexts/Auth";
import { FormattedMessage } from "react-intl";
import iosUtils from "../../../../utils/iosUtils";
import { PopOver } from "../../../nav/PopOver";
import { Row } from "../../../grid/Row/Row";
import { Col } from "../../../grid/Col/Col";

const msgKey = "meMenu";

export const MeMenu = ({ showName, contrast }) => {
    const { me } = useContext(AuthContext);
    const [showMenu, setShowMenu] = useState(false);

    return me ? (
        <PopOver
            visible={showMenu}
            size="large"
            arrow="primary"
            padding="none"
            align="end"
            layer="nav"
            onClose={() => setShowMenu(false)}
            content={<MenuContent user={me.user} onClose={() => setShowMenu(false)} />}
        >
            <Clickable
                block
                className={classNames(styles.meButton, {
                    [styles.placeholder]: !me.user.picture?.url,
                    [styles.active]: showMenu,
                    [styles.contrast]: contrast
                })}
                onClick={() => setShowMenu(!showMenu)}
            >
                {showName && <div className={styles.name}>{me.user.firstName}</div>}
                <Image
                    rounded
                    icon={showMenu ? "close" : "user"}
                    iconColor="primary"
                    className={styles.picture}
                    src={(!showMenu && me.user.picture?.url) || null}
                />
            </Clickable>
        </PopOver>
    ) : null;
};

const MenuContent = ({ user, onClose }) => {
    const [iosRestrictions] = useState(iosUtils.isIOSWebView());
    return (
        <div className={classNames(styles.meCard)}>
            {/* Header */}
            <div className={styles.header}>
                <div className={styles.content}>
                    <div className={styles.image}>
                        <Image
                            className={styles.picture}
                            icon="user"
                            iconColor="primary"
                            src={user.picture?.url}
                            size="fill"
                            rounded
                        />
                    </div>
                    <div className={styles.details}>
                        <div className={styles.name}>{user.fullName}</div>
                        <div className={styles.email}>{user.email}</div>
                    </div>
                </div>
            </div>

            {/* Menu */}
            <div className={styles.menu}>
                <Row>
                    <Col span={24}>
                        <Menu onClick={onClose}>
                            <MenuItem
                                label={<FormattedMessage id={`${msgKey}.home`} />}
                                link={`/me`}
                                icon="structure"
                            />
                            <MenuItem
                                label={<FormattedMessage id={`${msgKey}.courses`} />}
                                link={`/me/courses`}
                                icon="course"
                            />
                            <MenuItem
                                label={<FormattedMessage id={`${msgKey}.profile`} />}
                                link={`/me/profile`}
                                icon="user"
                            />
                            <MenuItem
                                label={<FormattedMessage id={`${msgKey}.billing`} />}
                                link={`/me/accounts`}
                                icon="credit-card"
                                hidden={iosRestrictions}
                            />
                            <MenuItem
                                label={<FormattedMessage id={`${msgKey}.settings`} />}
                                link={`/me/settings`}
                                icon="gear"
                            />
                        </Menu>
                    </Col>
                    <Col span={24}>
                        <Menu onClick={onClose}>
                            <Restricted admin>
                                <MenuItem
                                    label={<FormattedMessage id={`${msgKey}.administration`} />}
                                    link="/admin"
                                    icon="tools"
                                />
                            </Restricted>
                            <MenuItem
                                label={<FormattedMessage id={`${msgKey}.help`} />}
                                link={`/me/help`}
                                icon="question-circle"
                            />
                            <MenuItem
                                label={<FormattedMessage id={`${msgKey}.signOut`} />}
                                link={`/signout`}
                                icon="signout"
                            />
                        </Menu>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
