import { Editor, Transforms } from "slate";

export const deleteTable = (tablePath, editor) => {
    const parentNode = Editor.parent(editor, tablePath);
    if (parentNode) {
        Transforms.removeNodes(editor, {
            at: parentNode[1]
        });
    }
};
