import React from "react";
import classNames from "classnames";
import styles from "./Alert.scss";

import { Icon } from "../../graphic/Icon";

export const Alert = ({ type, title, icon, children, className }) => {
    return (
        <div
            className={classNames(
                styles.base,
                {
                    [styles.info]: type === "info",
                    [styles.success]: type === "success",
                    [styles.warning]: type === "warning",
                    [styles.error]: type === "error",
                    [styles.note]: type === "note"
                },
                className
            )}
        >
            {/* Icon */}
            {icon ? (
                <div className={styles.icon}>
                    <Icon name={icon} />
                </div>
            ) : null}

            <div className={styles.text}>
                {/* Title */}
                {title ? <div className={styles.title}>{title}</div> : null}

                {/* Message */}
                <div className={styles.message}>{children}</div>
            </div>
        </div>
    );
};
