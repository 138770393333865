import React from "react";
import style from "./style.scss";

import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { ShieldLogo } from "../../graphic/ShieldLogo/ShieldLogo";
import { FrontBar } from "../FrontBar";
import { SignUpButton } from "../SignUpButton";

const msgKey = "front";

export const FrontHero = ({ scrollY, isMobile, screenHeight }) => {
    return (
        <div
            className={classNames(style.base, { [style.continued]: scrollY > 300 })}
            style={isMobile ? { height: `${screenHeight}px` } : undefined}
        >
            <FrontBar />
            <div className={style.content}>
                <div className={style.wrapper}>
                    <div className={style.shield}>
                        <ShieldLogo />
                    </div>
                    <div className={style.message}>
                        <div className={style.mainline}>
                            <div className={style.carousel}>
                                <div className={style.words}>
                                    <FormattedMessage id={`${msgKey}.managementWords`} values={{ br: <br /> }} />
                                </div>
                                <div className={style.spacer}>
                                    <FormattedMessage id={`${msgKey}.safety`} />
                                </div>
                            </div>
                            <FormattedMessage id={`${msgKey}.management`} />
                        </div>
                        <div className={style.tagline}>
                            <FormattedMessage id={`${msgKey}.tagline`} values={{ u: (msg) => <u>{msg}</u> }} />
                        </div>
                    </div>
                    <div className={style.action}>
                        <SignUpButton>
                            <FormattedMessage id={`${msgKey}.getStarted`} />
                        </SignUpButton>
                    </div>
                </div>
            </div>
            <div className={style.arrow}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1920">
                    <path
                        fillRule="evenodd"
                        d="M902.83 0v1699.925l-476.465-476.467L345 1304.823 960.177 1920l615.407-615.177-81.365-81.365-476.466 476.467V0z"
                    />
                </svg>
            </div>
        </div>
    );
};
