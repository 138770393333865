import React from "react";
import classNames from "classnames";
import styles from "./Steps.scss";
import { FormattedMessage } from "react-intl";
import { Icon } from "../../graphic/Icon";

export const Step = ({ number, current, title, description }) => {
    return (
        <div
            className={classNames(styles.step, {
                [styles.isActive]: number === current,
                [styles.isComplete]: current > number
            })}
        >
            {/* Symbol */}
            <div className={styles.symbol}>
                <span className={styles.number}>{number}</span>
                <Icon size="small" className={styles.icon} name="check" />
            </div>

            {/* Trail */}
            <div className={styles.trail}></div>

            {/* Title */}
            <div className={styles.title}>
                <FormattedMessage id={title} />
            </div>

            {/* Description */}
            <div className={styles.description}>
                <FormattedMessage id={description} />
            </div>
        </div>
    );
};
