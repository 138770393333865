import React from "react";
import classNames from "classnames";
import style from "./style.scss";

import { Spinner } from "../../loaders/Spinner";

export default class RadioList extends React.Component {
    /**
     * Handle toggle
     */
    _handleItemToggle = (value) => {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    render() {
        // Properties
        const { value, error, busy, heading, children } = this.props;

        // Radio choices
        const childrenWithProps = React.Children.map(children, (child) =>
            React.cloneElement(child, {
                ...child.props,
                groupValue: value,
                onChange: this._handleItemToggle
            })
        );

        // Classes
        const classes = classNames(style.base, {
            [style.error]: error
        });

        return (
            <div className={classes}>
                {/* Loader */}
                {busy ? (
                    <div className={style.loader}>
                        <Spinner primary className={style.spinner} />
                    </div>
                ) : null}

                {/* Heading */}
                {heading ? (
                    <div className={style.heading}>
                        <div className={style.label}>{heading}</div>
                    </div>
                ) : null}

                {childrenWithProps}
            </div>
        );
    }
}
