import React from "react";
import classNames from "classnames";
import style from "./style.scss";

export default class PopupButtons extends React.Component {
    render() {
        const { children, filled } = this.props;

        let mainButton = null;
        let extraButton = null;

        React.Children.map(children, (child) => {
            if (child) {
                const clonedChild = React.cloneElement(child, {
                    className: classNames(child.props.className, style.buttonElement)
                });

                if (!mainButton) {
                    mainButton = clonedChild;
                } else {
                    extraButton = clonedChild;
                }
            }
        });

        const classes = classNames(style.buttons, {
            [style.twoButtons]: extraButton !== null,
            [style.filled]: filled
        });

        return (
            <div className={classes}>
                {mainButton ? <div className={style.mainButton}>{mainButton}</div> : null}

                {extraButton ? <div className={style.extraButton}>{extraButton}</div> : null}
            </div>
        );
    }
}
