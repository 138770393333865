import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../../../button/Button";
import { CourseScoreBadge } from "../../../course/CourseScoreBadge";
import { CheckIcon } from "../../../graphic/CheckIcon";
import { ShieldLogo } from "../../../graphic/ShieldLogo";
import { TextLogo } from "../../../graphic/TextLogo";
import Col from "../../../grid/Col";
import Row from "../../../grid/Row";
import styles from "./CourseCompleteCard.scss";

const msgKey = "courseCompleteCard";

export const CourseCompleteCard = ({ showing, course, courseResult, onRestartCourse, onCloseCourse }) => {
    const { scoreMax, scoreSum } = courseResult || {};
    const randomTitleNumber = useMemo(() => Math.floor(Math.random() * 5) + 1, []);

    return showing ? (
        <div className={styles.base}>
            <div className={styles.wrapper}>
                <div className={styles.symbol}>
                    <CheckIcon />
                </div>

                <div className={styles.message}>
                    <div className={styles.title}>
                        <FormattedMessage id={`${msgKey}.doneTitle_${randomTitleNumber}`} />
                    </div>
                    <div className={styles.description}>
                        <FormattedMessage
                            id={`${msgKey}.doneMessage`}
                            values={{ title: course.title, strong: (msg) => <strong>{msg}</strong> }}
                        />
                    </div>
                </div>

                {scoreMax && scoreMax > 0 ? (
                    <div className={styles.score}>
                        <CourseScoreBadge scoreMax={scoreMax} scoreSum={scoreSum} />
                        <div className={styles.points}>
                            <FormattedMessage
                                id={`${msgKey}.scorePoints`}
                                values={{
                                    score: scoreSum * 10,
                                    total: scoreMax * 10,
                                    strong: (msg) => <strong>{msg}</strong>
                                }}
                            />
                        </div>
                    </div>
                ) : null}

                <div className={styles.content}>
                    <Row gutter={["small", "small"]}>
                        <Col span={24}>
                            <Button fill pill size="big" color="primary" onClick={onCloseCourse}>
                                <FormattedMessage id={`${msgKey}.continue`} />
                            </Button>
                        </Col>
                        <Col span={24}>
                            <Button fill pill size="big" onClick={onRestartCourse}>
                                <FormattedMessage id={`${msgKey}.restart`} />
                            </Button>
                        </Col>
                    </Row>
                </div>

                <div className={styles.branding}>
                    <div className={styles.shield}>
                        <ShieldLogo />
                    </div>
                    <div className={styles.textLogo}>
                        <TextLogo size="small" />
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};
