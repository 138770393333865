import React from "react";
import classNames from "classnames";
import styles from "./style.scss";

export const PageButtons = ({ children, embedded }) => {
    return (
        <div
            className={classNames(styles.base, {
                [styles.embedded]: embedded
            })}
        >
            {children}
        </div>
    );
};
