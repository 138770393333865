import moment from "moment";
import React from "react";
import style from "./style.scss";

import PropTypes from "prop-types";
import { SingleDatePicker } from "../../time/SingleDatePicker";
import { TimePickerInput } from "../TimePicker/TimePickerInput";

export const DateTimePicker = ({
    value,
    onChange,
    error,
    blockFromDate,
    blockToDate,
    datePlaceholder,
    timePlaceholder
}) => {
    const handleChangeDate = (editedValue) => {
        if (!onChange) return;
        if (editedValue && value) {
            const editedDateValue = new Date(editedValue);
            const dateValue = new Date(value);
            editedDateValue.setHours(dateValue.getHours());
            editedDateValue.setMinutes(dateValue.getMinutes());
            editedDateValue.setSeconds(dateValue.getSeconds());
            onChange(moment(editedDateValue).format());
        } else if (editedValue) {
            onChange(moment(editedValue).format());
        } else {
            onChange(null);
        }
    };

    return (
        <div className={style.base}>
            <div className={style.date}>
                <SingleDatePicker
                    value={value}
                    onChange={handleChangeDate}
                    error={error}
                    blockFromDate={blockFromDate}
                    blockToDate={blockToDate}
                    placeholder={datePlaceholder}
                />
            </div>
            <div className={style.time}>
                <TimePickerInput value={value} onChange={onChange} error={error} placeholder={timePlaceholder} />
            </div>
        </div>
    );
};

DateTimePicker.propTypes = {
    value: PropTypes.instanceOf(Date)
};
