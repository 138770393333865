import classNames from "classnames";
import React from "react";
import { ReactEditor, useSelected, useSlate } from "slate-react";
import { PopOver } from "../../../../nav/PopOver";
import styles from "./Table.scss";
import { TableControls } from "./TableControls";

export const Table = ({ element, attributes, children }) => {
    const editor = useSlate();
    const selected = useSelected();
    const tablePath = ReactEditor.findPath(editor, element.children?.[0]);

    return (
        <div
            className={classNames(styles.base, {
                [styles.selected]: selected
            })}
        >
            <PopOver
                visible={selected}
                positions={["top", "bottom"]}
                padding="small"
                size="auto"
                content={
                    <TableControls
                        tablePath={tablePath}
                        doAction={(action) => {
                            action(tablePath, editor);
                        }}
                    />
                }
            >
                <table {...attributes} slate-table-element="table">
                    {children}
                </table>
            </PopOver>
        </div>
    );
};
