import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import Image from "../../../media/Image";
import { Progress } from "../Progress";
import styles from "./Stack.scss";
import { Uploading } from "../Uploading/Uploading";
import { FormattedMessage } from "react-intl";

const msgKey = "stack";

export const Stack = ({
    children,
    backgroundUrl,
    locked,
    currentIndex,
    onChange,
    onChanged,
    uploading,
    tools,
    badge,
    user,
    hideProgress
}) => {
    const sliderRef = useRef(null);
    const [numberOfCards, setNumberOfCards] = useState(null);

    useEffect(() => {
        setNumberOfCards(React.Children.toArray(children)?.length || 0);
    }, [children]);

    useEffect(() => {
        sliderRef?.current?.slickGoTo(currentIndex);
    }, [currentIndex]);

    // Throttle wheel event
    const handleWheel = (e) => {
        if (locked) return;
        if (e.deltaY > 0) {
            sliderRef?.current?.slickNext();
        } else {
            sliderRef?.current?.slickPrev();
        }
    };

    return (
        <div
            className={classNames(styles.base, {
                [styles.uploading]: uploading !== null
            })}
            onWheel={handleWheel}
        >
            <div className={styles.wrapper}>
                <div className={styles.cards}>
                    {/* Error */}
                    <div className={styles.error}>
                        <FormattedMessage id={`${msgKey}.screenTooSmall`} />
                    </div>

                    {/*  Uploading */}
                    {uploading !== null && (
                        <div className={styles.uploading}>
                            <Uploading percent={uploading} />
                        </div>
                    )}

                    {/* Controls */}
                    <div className={styles.controls}>
                        <div className={styles.leftSide}>
                            <Progress hidden={hideProgress} current={currentIndex + 1} total={numberOfCards} />
                            {badge && <div className={styles.badge}>{badge}</div>}
                        </div>
                        <div className={styles.rightSide}>
                            {!!user && <div className={styles.user}>{user}</div>}
                            <div className={styles.tools}>{tools}</div>
                        </div>
                    </div>
                    <Slider
                        ref={sliderRef}
                        className={styles.slider}
                        vertical
                        verticalSwiping
                        adaptiveHeight
                        swipeToSlide
                        infinite={false}
                        arrows={false}
                        speed={200}
                        slidesToShow={1}
                        slidesToScroll={1}
                        buttons={false}
                        beforeChange={(_, nextIndex) => onChange(nextIndex)}
                        afterChange={onChanged}
                        swipe={!locked}
                    >
                        {React.Children.map(children, (child, index) => {
                            return child ? React.cloneElement(child, { index }) : null;
                        })}
                    </Slider>
                </div>
            </div>
            <div className={styles.background}>
                <div className={styles.diffuse} />
                <Image size="fit" src={backgroundUrl} />
            </div>
        </div>
    );
};
