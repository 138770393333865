import React from "react";

export const ColorDot = ({ color }) => {
    return (
        <svg
            width={16}
            height={16}
            style={{
                verticalAlign: "middle"
            }}
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 29.107 29.107"
        >
            <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z" />
        </svg>
    );
};
