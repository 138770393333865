import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useCallback, useRef } from "react";
import { ArrowContainer, Popover } from "react-tiny-popover";
import { hasParentWithClassName } from "../../../utils/domUtils";
import { CUSTOM_SELECT_CLASS } from "../../form/CustomSelect/constants";
import styles from "./PopOver.scss";

export const PopOver = ({
    visible = false,
    onClose,
    children,
    content,
    arrow = "contrast",
    align = "center",
    size,
    padding = "regular",
    distance = 0,
    positions = ["bottom", "top"],
    layer
}) => {
    const popRef = useRef(null);

    const handleClickOutside = useCallback(
        (e) => {
            if (
                !popRef.current?.contains(e.target) &&
                !hasParentWithClassName(e.target, CUSTOM_SELECT_CLASS) // Prevent from closing when clicking on CustomSelect component option
            ) {
                onClose && onClose(e);
            }
        },
        [onClose]
    );

    return (
        <Popover
            isOpen={visible}
            positions={positions}
            padding={distance}
            onClickOutside={handleClickOutside}
            containerClassName={classNames(styles.base, {
                [styles.layerNav]: layer === "nav"
            })}
            reposition={true}
            align={align}
            content={({ position, childRect, popoverRect }) =>
                arrow !== "none" ? (
                    <ArrowContainer
                        position={position}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        arrowSize={8}
                        arrowColor="blue"
                        className={classNames(styles.arrow, {
                            [styles.trimPadding]: align !== "center",
                            [styles.colorPrimary]: arrow === "primary",
                            [styles.positionTop]: position === "top",
                            [styles.positionBottom]: position === "bottom"
                        })}
                        arrowClassName="popover-arrow"
                    >
                        <Content popRef={popRef} size={size} padding={padding} content={content} />
                    </ArrowContainer>
                ) : (
                    <Content popRef={popRef} size={size} padding={padding} content={content} />
                )
            }
        >
            {children}
        </Popover>
    );
};

const Content = ({ popRef, size, padding, content }) => (
    <div
        ref={popRef}
        className={classNames(styles.pop, {
            [styles.sizeLarge]: size === "large",
            [styles.sizeAuto]: size === "auto",
            [styles.paddingNone]: padding === "none",
            [styles.paddingSmall]: padding === "small",
            [styles.paddingRegular]: padding === "regular",
            [styles.paddingMedium]: padding === "medium",
            [styles.paddingLarge]: padding === "large"
        })}
    >
        {content}
    </div>
);

PopOver.propTypes = {
    /**
     * Show pop over
     */
    visible: PropTypes.bool,
    /**
     * Close callback triggered when clicking outside the pop over
     */
    onClose: PropTypes.func,
    /**
     * The content inside the pop over
     */
    content: PropTypes.any,
    /**
     * The color of the pop over arrow
     */
    arrow: PropTypes.oneOf(["none", "contrast", "primary"]),
    /**
     * Alignment of the pop over
     */
    align: PropTypes.oneOf(["start", "center", "end"]),
    /**
     * Width of the pop over
     */
    size: PropTypes.oneOf([null, "large", "auto"]),
    /**
     * The element that will show the pop over, usually a button
     */
    children: PropTypes.element,
    /**
     * Padding around content
     */
    padding: PropTypes.oneOf([true, "small", "regular", "medium", "large", "none"])
};
