import React from "react"
import classNames from "classnames"
import style from "./style.scss"

import Radio from "../../form/Radio"


export default class Item extends React.Component {


    _handleOnChange = () => {
        if(this.props.onChange){
            this.props.onChange(this.props.value);
        }
    };

    render(){

        // Checked
        const { checked, value, groupValue, symbol, children, cy } = this.props;

        // Classes
        const classes = classNames(style.item, {
            [style.checked]: checked
        });

        return (
            <div className={classes}>
                <Radio value={value} groupValue={groupValue} className={style.radio} onToggle={this._handleOnChange} cy={cy}>
                    {children}

                    {symbol ? (
                        <div className={style.symbol}>
                            <div className={style.wrapper}>
                                {symbol}
                            </div>
                        </div>
                    ) : null}
                </Radio>
            </div>
        )
    }
}
