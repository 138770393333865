import classNames from "classnames";
import React from "react";
import style from "./style.scss";

import { Col } from "../../grid/Col/Col";
import { Row } from "../../grid/Row/Row";

export default class Item extends React.Component {
    render() {
        // Properties
        const { variant, title, image, children } = this.props;

        const itemClasses = classNames(style.item, {
            [style.variant]: variant
        });

        return (
            <div className={itemClasses}>
                <div className={style.message}>
                    <Row>
                        <Col span={24}>
                            <div className={style.title}>{title}</div>
                        </Col>
                        <Col span={24}>
                            <div className={style.description}>{children}</div>
                        </Col>
                        {/* <Col span={24}>
                            <Clickable className={style.button}>{<FormattedMessage id="front.readMore" />}</Clickable>
                        </Col> */}
                    </Row>
                </div>
                <div className={style.illustration}>
                    <img loading="lazy" className={style.image} src={image} alt={title} />
                </div>
            </div>
        );
    }
}
