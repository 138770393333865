import React from "react";
import { useIntl } from "react-intl";
import intlUtils from "../../../utils/intlUtils";
import style from "./style.scss";

export const Container = ({ children }) => {
    const intl = useIntl();
    intlUtils.setIntl(intl);

    return <div className={style.base}>{children}</div>;
};
