import React from "react";
import classNames from "classnames";
import style from "./style.scss";

import { Button } from "../../button/Button";
import ButtonRow from "../../button/ButtonRow";

export default class Fullscreen extends React.Component {
    render() {
        // Properties
        const { title, children, onClose, onSettingsClick } = this.props;

        // Classes
        const classes = classNames(style.base);

        return (
            <div className={classes}>
                <div className={style.titlebar}>
                    <div className={style.title}>{title}</div>
                    <div className={style.buttons}>
                        <ButtonRow>
                            {onSettingsClick ? (
                                <Button square icon="gear" iconColor="primary" onClick={onSettingsClick} />
                            ) : null}

                            <Button onClick={onClose} color="primary" square icon="close" />
                        </ButtonRow>
                    </div>
                </div>
                <div className={style.content}>{children}</div>
            </div>
        );
    }
}
