import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Editor } from "slate";
import { useSlate, useSlateSelection } from "slate-react";
import { Button } from "../../../../button/Button";
import { Menu, MenuHeading } from "../../../../nav/Menu";
import { PopOver } from "../../../../nav/PopOver";
import { msgKey } from "../../RichTextEditor";
import { BlockItem } from "./BlockItem";
import { MarkItem } from "./MarkItem";

export const TextFormatting = ({ expanded, setExpanded }) => {
    const editor = useSlate();
    const selection = useSlateSelection();
    const [insideBlock, setInsideBlock] = useState(null);

    useEffect(() => {
        const insideBlock = Editor.above(editor, {
            match: (n) => ["table"].includes(n.type)
        });
        setInsideBlock(insideBlock?.[0]?.type || null);
    }, [editor, selection]);

    return (
        <PopOver
            visible={expanded === "TEXT_FORMATTING"}
            onClose={() => setExpanded(null)}
            positions={["bottom", "top"]}
            align="start"
            size="auto"
            arrow="none"
            content={
                <Menu>
                    <MenuHeading label={<FormattedMessage id={`${msgKey}.textFormatting`} />} />
                    <BlockItem
                        format="headingOne"
                        icon="heading"
                        label={<FormattedMessage id={`${msgKey}.headingOne`} />}
                        hidden={["table"].includes(insideBlock)}
                    />
                    <BlockItem
                        format="headingTwo"
                        icon="heading"
                        label={<FormattedMessage id={`${msgKey}.headingTwo`} />}
                        hidden={["table"].includes(insideBlock)}
                    />
                    <BlockItem
                        format="headingThree"
                        icon="heading"
                        label={<FormattedMessage id={`${msgKey}.headingThree`} />}
                        hidden={["table"].includes(insideBlock)}
                    />
                    <BlockItem
                        format="headingFour"
                        icon="heading"
                        label={<FormattedMessage id={`${msgKey}.headingFour`} />}
                        hidden={["table"].includes(insideBlock)}
                    />
                    <MarkItem format="bold" icon="bold" label={<FormattedMessage id={`${msgKey}.boldText`} />} />
                    <MarkItem format="italic" icon="italic" label={<FormattedMessage id={`${msgKey}.italicText`} />} />
                    <MarkItem
                        format="underline"
                        icon="underline"
                        label={<FormattedMessage id={`${msgKey}.underlineText`} />}
                    />
                    <MarkItem
                        format="strikeThrough"
                        icon="strikeThrough"
                        label={<FormattedMessage id={`${msgKey}.strikeThroughText`} />}
                    />
                </Menu>
            }
        >
            <Button
                square
                color="toolbar"
                active={expanded === "TEXT_FORMATTING"}
                icon="text"
                onClick={() => {
                    setExpanded(expanded === "TEXT_FORMATTING" ? null : "TEXT_FORMATTING");
                }}
            />
        </PopOver>
    );
};
