import React from "react";
import { jsx } from "slate-hyperscript";
import { v4 as uuid } from "uuid";
import { ImageComponent } from "./image/ImageComponent";
import { LinkComponent } from "./link/LinkComponent";
import { ParagraphComponent } from "./paragraph/ParagraphComponent";
import { Cell } from "./table/components/Cell";
import { Table } from "./table/components/Table";
import { escapeHtml } from "./utils/escapeHtml";

export const ElementType = {
    paragraph: {
        toHtml: ({ children, style }) => {
            return `<p${style}>${children}</p>`;
        },
        toNode: ({ children, properties }) => {
            return jsx("element", { type: "paragraph", ...properties }, children);
        },
        component: ({ children, attributes, style }) => (
            <ParagraphComponent style={style} attributes={attributes} children={children} />
        )
    },
    blockQuote: {
        toHtml: ({ children }) => {
            return `<blockquote>${children}</blockquote>`;
        },
        toNode: ({ children }) => {
            return jsx("element", { type: "blockQuote" }, children);
        },
        component: ({ children, attributes }) => <blockquote {...attributes}>{children}</blockquote>
    },
    numberedList: {
        toHtml: ({ children, style }) => {
            return `<ol${style}>${children}</ol>`;
        },
        toNode: ({ children }) => {
            return jsx("element", { type: "numberedList" }, children);
        },
        component: ({ children, attributes, style }) => (
            <ol style={style} {...attributes}>
                {children}
            </ol>
        )
    },
    bulletedList: {
        toHtml: ({ children, style }) => {
            return `<ul${style}>${children}</ul>`;
        },
        toNode: ({ children }) => {
            return jsx("element", { type: "bulletedList" }, children);
        },
        component: ({ children, attributes, style }) => (
            <ul style={style} {...attributes}>
                {children}
            </ul>
        )
    },
    listItem: {
        toHtml: ({ children, style }) => {
            return `<li${style}>${children}</li>`;
        },
        toNode: ({ children }) => {
            return jsx("element", { type: "listItem" }, children);
        },
        component: ({ children, attributes, style }) => (
            <li style={style} {...attributes}>
                {children}
            </li>
        )
    },
    link: {
        toHtml: ({ node, children }) => {
            return `<a href="${escapeHtml(node.url)}">${children}</a>`;
        },
        toNode: ({ el, children }) => {
            return jsx("element", { type: "link", url: el.getAttribute("href") }, children);
        },
        component: ({ children, element, attributes }) => (
            <LinkComponent element={element} attributes={attributes} children={children} />
        )
    },
    headingOne: {
        toHtml: ({ children, style }) => {
            return `<h1${style}>${children}</h1>`;
        },
        toNode: ({ children, properties }) => {
            return jsx("element", { type: "headingOne", ...properties }, children);
        },
        component: ({ children, attributes, style }) => (
            <h1 style={style} {...attributes}>
                {children}
            </h1>
        )
    },
    headingTwo: {
        toHtml: ({ children, style }) => {
            return `<h2${style}>${children}</h2>`;
        },
        toNode: ({ children, properties }) => {
            return jsx("element", { type: "headingTwo", ...properties }, children);
        },
        component: ({ children, attributes, style }) => (
            <h2 style={style} {...attributes}>
                {children}
            </h2>
        )
    },
    headingThree: {
        toHtml: ({ children, style }) => {
            return `<h3${style}>${children}</h3>`;
        },
        toNode: ({ children, properties }) => {
            return jsx("element", { type: "headingThree", ...properties }, children);
        },
        component: ({ children, attributes, style }) => (
            <h3 style={style} {...attributes}>
                {children}
            </h3>
        )
    },
    headingFour: {
        toHtml: ({ children, style }) => {
            return `<h4${style}>${children}</h4>`;
        },
        toNode: ({ children, properties }) => {
            return jsx("element", { type: "headingFour", ...properties }, children);
        },
        component: ({ children, attributes, style }) => (
            <h4 style={style} {...attributes}>
                {children}
            </h4>
        )
    },
    table: {
        toHtml: ({ children }) => {
            return `<table>${children}</table>`;
        },
        toNode: ({ children }) => {
            return jsx("element", { type: "table" }, children);
        },
        component: ({ children, element, attributes }) => (
            <Table element={element} attributes={attributes} children={children} />
        )
    },
    "table-head": {
        toHtml: ({ children }) => {
            return `<thead>${children}</thead>`;
        },
        toNode: ({ children }) => {
            return jsx("element", { type: "table-head" }, children);
        },
        component: ({ children, attributes }) => (
            <thead {...attributes} slate-table-element="thead">
                {children}
            </thead>
        )
    },
    "table-body": {
        toHtml: ({ children }) => {
            return `<tbody>${children}</tbody>`;
        },
        toNode: ({ children }) => {
            return jsx("element", { type: "table-body" }, children);
        },
        component: ({ children, attributes }) => (
            <tbody {...attributes} slate-table-element="tbody">
                {children}
            </tbody>
        )
    },
    "table-row": {
        toHtml: ({ children }) => {
            return `<tr>${children}</tr>`;
        },
        toNode: ({ children }) => {
            return jsx("element", { type: "table-row" }, children);
        },
        component: ({ children, attributes }) => (
            <tr {...attributes} slate-table-element="tr">
                {children}
            </tr>
        )
    },
    "table-cell": {
        toHtml: ({ node, children }) => {
            return node.isHeader ? `<th>${children}</th>` : `<td>${children}</td>`;
        },
        toNode: ({ el, children }) => {
            return jsx(
                "element",
                { type: "table-cell", key: `cell_${uuid()}`, isHeader: el.nodeName === "TH" },
                children
            );
        },
        component: ({ children, element, attributes }) => (
            <Cell element={element} attributes={attributes} children={children} />
        )
    },
    image: {
        toHtml: ({ node }) => {
            if (!node.src || !node.refId) {
                console.warn("[VP] Image node is missing src or refId");
                return "";
            }

            // Start building the img tag
            let imgTag = `<img src="${node.src}" data-ref="${node.refId}"`;

            // Initialize style attribute
            let style = "";
            if (node.width && node.width !== "0") {
                style += `width: ${node.width};`; // Convert width to max-width
            }

            if (node.align === "center") {
                style += `display: block; margin-left: auto; margin-right: auto;`;
            }

            if (style) {
                imgTag += ` style="${style}"`;
            }

            // Close the img tag
            imgTag += " />";

            return imgTag;
        },
        toNode: ({ el, children }) => {
            return jsx(
                "element",
                {
                    type: "image",
                    src: el.getAttribute("src"),
                    refId: el.getAttribute("data-ref"),
                    width: el.style.width,
                    align: el.style.display === "block" ? "center" : "left"
                },
                children
            );
        },
        component: ({ children, element, attributes, style }) => (
            <ImageComponent element={element} attributes={attributes} children={children} style={style} />
        )
    }
};

export const tagElementTypeMap = {
    P: ElementType.paragraph,
    BLOCKQUOTE: ElementType.blockQuote,
    A: ElementType.link,
    H1: ElementType.headingOne,
    H2: ElementType.headingTwo,
    H3: ElementType.headingThree,
    H4: ElementType.headingFour,
    TABLE: ElementType.table,
    TR: ElementType["table-row"],
    TD: ElementType["table-cell"],
    TH: ElementType["table-cell"],
    TBODY: ElementType["table-body"],
    OL: ElementType.numberedList,
    UL: ElementType.bulletedList,
    LI: ElementType.listItem,
    IMG: ElementType.image
};

export const getElementStyle = (element) => {
    const style = {};
    if (element.align) {
        style.textAlign = element.align;
    }
    return style;
};

export const renderElement = ({ attributes, children, element }) => {
    const elementStyle = getElementStyle(element);
    const elementType = ElementType[element.type];
    if (elementType) {
        return elementType.component({ children, element, attributes, style: elementStyle });
    } else {
        return ElementType.paragraph.component({ children, element, attributes, style: elementStyle });
    }
};
