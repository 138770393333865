import React from "react";
import style from "./style.scss";

import { countryList, getCountryDialCode } from "../../../utils/countryUtil";

import { FormattedMessage } from "react-intl";
import Input from "../Input";
import Flag from "../../graphic/Flag";
import Paragraph from "../../typography/Paragraph";
import { Text } from "../../typography/Text";
import { Popup, PopupContent, PopupButtons } from "../../notify/Popup";
import { Select, Option } from "../Select";
import { Button } from "../../button/Button";

export default class PhoneInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
            countryCode: null,
            chosenCountryCode: null,
            dialCode: null,
            showCountryCodePopup: false
        };
    }

    UNSAFE_componentWillMount() {
        this._handlePropChanges(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this._handlePropChanges(nextProps);
    }

    /**
     * Handle property changes
     */
    _handlePropChanges(props) {
        this.setState({
            value: props.value,
            countryCode: props.countryCode,
            chosenCountryCode: props.countryCode,
            dialCode: props.countryCode ? getCountryDialCode(props.countryCode) : null
        });
    }

    /**
     * Handle number change
     * @param e
     * @returns {null}
     * @private
     */
    _handleOnChange = (e) => {
        let value = e.target.value;
        e.target.value = value ? value.replace(/\D/g, "") : null;

        if (this.props.onChange) {
            this.props.onChange(e);
        }
    };

    /**
     * Confirm country chosen
     * @private
     */
    _handleConfirmSelection = () => {
        if (this.props.onCountryCodeChange) {
            this.props.onCountryCodeChange(this.state.chosenCountryCode);
        }
        this.setState({
            showCountryCodePopup: false
        });
    };

    /**
     * Cancel country selection
     * @private
     */
    _handleCancelSelection = () => {
        this.setState({
            chosenCountryCode: this.state.countryCode,
            showCountryCodePopup: false
        });
    };

    render() {
        // Variables
        const { value, countryCode, chosenCountryCode, dialCode, showCountryCodePopup } = this.state;

        // Flag and dial code
        const inputPrepend = (
            <div className={style.prepend}>
                <Flag code={countryCode} className={style.flag} />
                {dialCode ? (
                    <Text color="base60" className={style.dialCode}>
                        +{dialCode}
                    </Text>
                ) : null}
            </div>
        );

        return (
            <div className={style.base}>
                <Input
                    {...this.props}
                    type="tel"
                    maxLength={20}
                    value={value}
                    prepend={inputPrepend}
                    onPrependClick={() => this.setState({ showCountryCodePopup: true })}
                    onChange={this._handleOnChange}
                />

                {showCountryCodePopup ? (
                    <Popup>
                        <PopupContent>
                            <Paragraph>
                                <Text size="medium" weight="semiBold" color="base80">
                                    <FormattedMessage id="phoneInput.chooseCountryDialCode" />:
                                </Text>
                            </Paragraph>
                            <Select
                                value={chosenCountryCode}
                                onChange={(countryCode) => this.setState({ chosenCountryCode: countryCode })}
                            >
                                {countryList.map((country, index) => (
                                    <Option key={index} value={country.iso}>
                                        <FormattedMessage id={`countries.${country.iso}`} />
                                    </Option>
                                ))}
                            </Select>
                        </PopupContent>
                        <PopupButtons>
                            <Button icon="check" color="primary" onClick={this._handleConfirmSelection}>
                                <FormattedMessage id="phoneInput.choose" />
                            </Button>
                            <Button onClick={this._handleCancelSelection}>
                                <FormattedMessage id="phoneInput.cancel" />
                            </Button>
                        </PopupButtons>
                    </Popup>
                ) : null}
            </div>
        );
    }
}
