import React, { useContext, useEffect, useRef } from "react";
import classNames from "classnames";
import style from "./style.scss";
import Disclaimer from "../../Disclaimer";
import { AppContext } from "../../../../App";

export const MainPane = ({ children, noBar }) => {
    const paneRef = useRef(null);
    const { hasSidebar, setScrollContainerElement } = useContext(AppContext);

    useEffect(() => {
        setScrollContainerElement(paneRef.current);
    }, [setScrollContainerElement]);

    return (
        <div
            id="main-pane"
            ref={paneRef}
            className={classNames(style.base, {
                [style.noBar]: noBar,
                [style.hasSidebar]: hasSidebar
            })}
        >
            {children}
            <div className={style.disclaimer}>
                <Disclaimer />
            </div>
        </div>
    );
};
