import React from "react";
import styles from "./CourseNewCard.scss";
import { FormattedMessage } from "react-intl";
import { Figure } from "../../../graphic/Figure";

const msgKey = "courseNewCard";

export const CourseNewCard = () => {
    return (
        <div className={styles.base}>
            <div className={styles.wrapper}>
                <div className={styles.arrow}>
                    <Figure name="arrow-doodle" />
                </div>
                <div className={styles.description}>
                    <FormattedMessage id={`${msgKey}.description`} />
                </div>
            </div>
        </div>
    );
};
