import React from "react"
import style from "./style.scss"


export default class FeatureList extends React.Component {
    render(){

        // Properties
        const { children } = this.props;

        // Loop children
        const items = React.Children.map(children, (child, index) => {
            return React.cloneElement(child, {
                variant: index % 2 === 0
            })
        });

        return (
            <div className={style.base}>
                {items}
            </div>
        )
    }
}
