import React, { useContext, useEffect, useState } from "react";
import { Route } from "react-router-dom";

import { VesselContext } from "../../../routes/vessel/VesselRoot";
import { BuyVesselSubscriptionMessage } from "../../messages/BuyVesselSubscriptionMessage";
import NoAccessMessage from "../../messages/NoAccessMessage";
import TrialExpiredMessage from "../../messages/TrialExpiredMessage";
import { Page } from "../../page/Page";

export const RouteVessel = (props) => {
    const { vessel, vesselAccount, vesselRole } = useContext(VesselContext);
    const { restrictTo, hasValidSubscription } = props;
    const [noAccess, setNoAccess] = useState(null);
    const [activeAccount, setActiveAccount] = useState(null);

    useEffect(() => {
        setNoAccess(restrictTo && restrictTo.indexOf(vesselRole) < 0);
    }, [restrictTo, vesselRole]);

    useEffect(() => {
        setActiveAccount(vesselAccount && vesselAccount.isActive);
    }, [vesselAccount]);

    return noAccess === null || activeAccount === null ? null : (!hasValidSubscription || activeAccount) &&
      !noAccess ? (
        <Route {...props} />
    ) : (
        <Page>
            {noAccess ? (
                <NoAccessMessage />
            ) : vesselAccount.status === "TRIAL" ? (
                <TrialExpiredMessage vessel={vessel} />
            ) : (
                <BuyVesselSubscriptionMessage vessel={vessel} vesselRole={vesselRole} />
            )}
        </Page>
    );
};
