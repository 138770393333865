import React, { useState } from "react";

import { FormattedMessage } from "react-intl";
import { Button } from "../../../button/Button";
import Form from "../../../form/Form";
import { FormItem } from "../../../form/FormItem";
import { Option, Select } from "../../../form/Select";
import { Textarea } from "../../../form/Textarea";
import { Popup, PopupButtons, PopupContent, PopupTitle } from "../../../notify/Popup";

const msgKey = "riskTable";

export const NewHazardPopup = ({ task, onClose, onCreate }) => {
    const [description, setDescription] = useState(null);
    const [rank, setRank] = useState(null);
    const [measures, setMeasures] = useState(null);
    const [busyCreating, setBusyCreating] = useState(false);

    const handleCreateHazard = () => {
        setBusyCreating(true);
        onCreate(task, { description, rank, measures }, () => {
            onClose();
        });
    };

    return (
        <Popup onClose={onClose}>
            <PopupTitle subtitle={task.text}>
                <FormattedMessage id={`${msgKey}.addHazard`} />
            </PopupTitle>
            <PopupContent>
                <Form>
                    <FormItem compact label={<FormattedMessage id={`${msgKey}.hazard`} />}>
                        <Textarea
                            value={description}
                            placeholder={<FormattedMessage id={`${msgKey}.writeHere`} />}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </FormItem>
                    <FormItem label={<FormattedMessage id={`${msgKey}.riskAssessment`} />} compact>
                        <Select value={rank} onChange={setRank}>
                            <Option value={0}>
                                <FormattedMessage id="riskTable.rank-none" />
                            </Option>
                            <Option value={1}>
                                <FormattedMessage id="riskTable.rank-low" />
                            </Option>
                            <Option value={2}>
                                <FormattedMessage id="riskTable.rank-mid" />
                            </Option>
                            <Option value={3}>
                                <FormattedMessage id="riskTable.rank-high" />
                            </Option>
                        </Select>
                    </FormItem>
                    <FormItem compact label={<FormattedMessage id={`${msgKey}.measures`} />}>
                        <Textarea
                            value={measures}
                            placeholder={<FormattedMessage id={`${msgKey}.writeHere`} />}
                            onChange={(e) => setMeasures(e.target.value)}
                        />
                    </FormItem>
                </Form>
            </PopupContent>
            <PopupButtons>
                <Button
                    type="submit"
                    onClick={handleCreateHazard}
                    color="primary"
                    icon="check"
                    disabled={!description?.trim().length}
                    busy={busyCreating}
                >
                    <FormattedMessage id={`${msgKey}.createHazard`} />
                </Button>
            </PopupButtons>
        </Popup>
    );
};
