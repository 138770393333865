import React from "react"
import style from "./style.scss"


export default class RadioGroup extends React.Component {


    /**
     * Handle toggle radiobutton
     * @param value
     * @private
     */
    _handleRadioToggle = (value) => {
        if(this.props.onChange){
            this.props.onChange(value);
        }
    };


    render(){

        const radioButtons = React.Children.map(this.props.children, (child, index) => {
            return React.cloneElement(child, {
                groupValue: this.props.value,
                onToggle: this._handleRadioToggle
            })
        });

        return (
            <div className={style.group}>
                {radioButtons}
            </div>
        )
    }
}
