import React from "react";
import classNames from "classnames";
import style from "./style.scss";
import { Clickable } from "../../button/Clickable";
import { Icon } from "../../graphic/Icon";
import { useLocation } from "react-router-dom";

export const PageButton = ({ icon, label, count, active, onClick, link, exact = true }) => {
    const location = useLocation();

    return (
        <Clickable
            className={classNames(style.button, {
                [style.active]: active || (exact ? link === location.pathname : location.pathname.startsWith(link))
            })}
            link={link}
            replace={false}
            onClick={!active ? onClick : null}
        >
            <div className={style.icon}>
                <Icon name={icon} />
            </div>
            <div className={style.label}>{label}</div>

            {count ? (
                <div className={style.count}>
                    <div className={style.number}>{count}</div>
                </div>
            ) : null}
        </Clickable>
    );
};
