import React from "react";
import style from "./style.scss";

import Row from "../../grid/Row";
import Col from "../../grid/Col";
import CircleMeter from "../../gauge/CircleMeter";
import { Box } from "../../box/Box";

export const RiskContextItem = ({ title, description, lowCount, midCount, highCount, onClick }) => {
    const hazardCount = lowCount + midCount + highCount;
    const lowPercent = Math.round((lowCount / hazardCount) * 100);
    const midPercent = Math.round((midCount / hazardCount) * 100);
    const highPercent = Math.round((highCount / hazardCount) * 100);

    return (
        <Box onClick={onClick}>
            <div className={style.item}>
                <div className={style.details}>
                    <div className={style.title}>{title}</div>
                    <div className={style.description}>{description}</div>
                </div>
                <div className={style.gauges}>
                    <Row gutter={["small", "small"]}>
                        <Col span={8}>
                            <CircleMeter rankHigh value={highPercent || 0} stroke={6} label={highCount} />
                        </Col>
                        <Col span={8}>
                            <CircleMeter rankMid value={midPercent || 0} stroke={6} label={midCount} />
                        </Col>
                        <Col span={8}>
                            <CircleMeter rankLow value={lowPercent || 0} stroke={6} label={lowCount} />
                        </Col>
                    </Row>
                </div>
            </div>
        </Box>
    );
};
