import { Editor, Transforms } from "slate";

export const tableHasHeaderRow = (tablePath, editor) => {
    try {
        // Get first row
        const [firstRow] = Editor.nodes(editor, {
            match: (n) => n.type === "table-row",
            at: tablePath
        });
        if (!firstRow) return false;

        const headerCells = Editor.nodes(editor, {
            match: (n) => n.type === "table-cell",
            at: firstRow[1]
        });

        // Get last header cell
        const lastHeaderCell = [...headerCells].pop();
        return !!lastHeaderCell && lastHeaderCell[0].isHeader;
    } catch (e) {
        return false;
    }
};

export const addTableHeaderRow = (tablePath, editor) => {
    const { selection } = editor;
    if (!selection || !tablePath) return;

    // Get first row
    const [firstRow] = Editor.nodes(editor, {
        match: (n) => n.type === "table-row",
        at: tablePath
    });
    if (!firstRow) return;

    Transforms.setNodes(editor, { isHeader: true }, { at: firstRow[1], match: (n) => n.type === "table-cell" });
};

export const removeTableHeaderRow = (tablePath, editor) => {
    const { selection } = editor;
    if (!selection || !tablePath) return;

    // Get first row
    const [firstRow] = Editor.nodes(editor, {
        match: (n) => n.type === "table-row",
        at: tablePath
    });
    if (!firstRow) return;

    Transforms.setNodes(editor, { isHeader: false }, { at: firstRow[1], match: (n, p) => n.type === "table-cell" });

    // Add back header column if it was removed
    if (tableHasHeaderColumn(tablePath, editor)) {
        addTableHeaderColumn(tablePath, editor);
    }
};

export const toggleTableHeaderRow = (tablePath, editor) => {
    if (tableHasHeaderRow(tablePath, editor)) {
        removeTableHeaderRow(tablePath, editor);
    } else {
        addTableHeaderRow(tablePath, editor);
    }
};

export const tableHasHeaderColumn = (tablePath, editor) => {
    // Get rows
    const rows = Editor.nodes(editor, {
        match: (n) => n.type === "table-row",
        at: tablePath
    });
    if (!rows) return false;

    let lastRow;
    try {
        lastRow = [...rows].pop();
    } catch (e) {
        return false;
    }

    if (!lastRow) return false;

    const [firstCell] = Editor.nodes(editor, {
        match: (n) => n.type === "table-cell",
        at: lastRow[1]
    });

    return !!firstCell && firstCell[0].isHeader;
};

export const addTableHeaderColumn = (tablePath, editor) => {
    const { selection } = editor;
    if (!selection || !tablePath) return;

    // Get rows
    const rows = Editor.nodes(editor, {
        match: (n) => n.type === "table-row",
        at: tablePath
    });
    if (!rows) return;

    for (const row of rows) {
        const [firstCell] = Editor.nodes(editor, {
            match: (n) => n.type === "table-cell",
            at: row[1]
        });
        if (firstCell) {
            Transforms.setNodes(
                editor,
                { isHeader: true },
                { at: firstCell[1], match: (n) => n.type === "table-cell" }
            );
        }
    }
};

export const removeTableHeaderColumn = (tablePath, editor) => {
    const { selection } = editor;
    if (!selection || !tablePath) return;

    // Get rows
    const rows = Editor.nodes(editor, {
        match: (n) => n.type === "table-row",
        at: tablePath
    });
    if (!rows) return;

    for (const row of rows) {
        const [firstCell] = Editor.nodes(editor, {
            match: (n) => n.type === "table-cell",
            at: row[1]
        });
        if (firstCell) {
            Transforms.setNodes(
                editor,
                { isHeader: false },
                { at: firstCell[1], match: (n) => n.type === "table-cell" }
            );
        }
    }

    // Add back header row if it was removed
    if (tableHasHeaderRow(tablePath, editor)) {
        addTableHeaderRow(tablePath, editor);
    }
};

export const toggleTableHeaderColumn = (tablePath, editor) => {
    if (tableHasHeaderColumn(tablePath, editor)) {
        removeTableHeaderColumn(tablePath, editor);
    } else {
        addTableHeaderColumn(tablePath, editor);
    }
};
