import classNames from "classnames";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import coverImage from "../../../assets/images/misc/course-cover.webp";
import { AuthContext } from "../../../contexts/Auth";
import { Icon } from "../../graphic/Icon";
import Col from "../../grid/Col";
import Row from "../../grid/Row";
import { Spinner } from "../../loaders/Spinner";
import Image from "../../media/Image";
import Badge from "../../misc/Badge";
import { Menu, MenuItem } from "../../nav/Menu";
import { PopOver } from "../../nav/PopOver";
import { PopupYesNo } from "../../notify/Popup";
import { Tooltip, Tip } from "../../notify/Tooltip";
import styles from "./CourseCoverItem.scss";

const msgKey = "courseCoverItem";

export const CourseCoverItem = ({
    course,
    required,
    completed,
    busy,
    disabled,
    onDelete,
    onRestore,
    onAdd,
    onRemove,
    onRequired
}) => {
    const baseRef = useRef(null);
    const linkRef = useRef(null);
    const { me } = useContext(AuthContext);
    const [showMenu, setShowMenu] = useState(false);
    const [width, setWidth] = useState(0);
    const [showPrompt, setShowPrompt] = useState(null);

    useEffect(() => {
        if (!baseRef.current) {
            return;
        }

        // Observe base element size
        const resizeObserver = new ResizeObserver(() => {
            setWidth(baseRef.current?.offsetWidth);
            setShowMenu(false);
        });
        resizeObserver.observe(baseRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <div
            ref={baseRef}
            className={classNames(styles.base, {
                [styles.disabled]: disabled,
                [styles.unpublished]: !course.published
            })}
            style={{ fontSize: `${Math.max(12 * (width / 200), 12)}px` }}
        >
            <Link
                ref={linkRef}
                draggable="false"
                to={{
                    pathname: `/courses/${course.uuid}`,
                    state: {
                        from: window.location.pathname
                    }
                }}
                className={styles.link}
            />
            {busy ? (
                <button className={classNames(styles.cornerButton, styles.more)} disabled>
                    <Spinner primary />
                </button>
            ) : onDelete || onRestore ? (
                <PopOver
                    visible={showMenu}
                    onClose={() => setShowMenu(false)}
                    content={
                        <Menu>
                            {!!onRequired && (
                                <MenuItem
                                    label={
                                        required ? (
                                            <FormattedMessage id={`${msgKey}.notRequiredCourse`} />
                                        ) : (
                                            <FormattedMessage id={`${msgKey}.requiredCourse`} />
                                        )
                                    }
                                    icon="exclamation-circle"
                                    onClick={() => setShowMenu(false) || onRequired(required)}
                                />
                            )}
                            {!course.deleted && !!onDelete && (
                                <MenuItem
                                    label={<FormattedMessage id={`${msgKey}.deleteCourse`} />}
                                    icon="trash"
                                    onClick={() => setShowPrompt("DELETE")}
                                />
                            )}
                            {!!course.deleted && !!onRestore && (
                                <MenuItem
                                    label={<FormattedMessage id={`${msgKey}.restoreCourse`} />}
                                    icon="reset"
                                    onClick={() => setShowPrompt("RESTORE")}
                                />
                            )}
                        </Menu>
                    }
                >
                    <button
                        className={classNames(styles.cornerButton, styles.more, { [styles.active]: showMenu })}
                        onClick={() => setShowMenu(!showMenu)}
                    >
                        <Icon name="more" />
                    </button>
                </PopOver>
            ) : onAdd ? (
                <button className={classNames(styles.cornerButton, styles.add)} onClick={onAdd}>
                    <Icon name="plus" />
                </button>
            ) : onRemove ? (
                <button className={classNames(styles.cornerButton, styles.remove)} onClick={onRemove}>
                    <Icon name="close" />
                </button>
            ) : null}
            <div className={styles.status}>
                <Row gutter={["tiny", "tiny"]}>
                    {/* Completed */}
                    {!!completed && course.published && (
                        <Col>
                            <Tip id="course_cover_item" msg={<FormattedMessage id={`${msgKey}.completedCourse`} />}>
                                <Badge icon="check" color="success" fill />
                            </Tip>
                        </Col>
                    )}

                    {/* Public (admin) */}
                    {course.open && me?.user.roles.includes("admin") && (
                        <Col>
                            <Badge fill>
                                <FormattedMessage id={`${msgKey}.public`} />
                            </Badge>
                        </Col>
                    )}

                    {/* Unpublished */}
                    {!course.published && (
                        <Col>
                            <Tip id="course_cover_item" msg={<FormattedMessage id={`${msgKey}.unpublishedCourse`} />}>
                                <Badge fill color="error">
                                    <FormattedMessage id={`${msgKey}.unpublished`} />
                                </Badge>
                            </Tip>
                        </Col>
                    )}

                    {/* Required */}
                    {required && !completed && (
                        <Col>
                            <Tip
                                id="course_cover_item"
                                msg={<FormattedMessage id={`${msgKey}.requiredCourseDescription`} />}
                            >
                                <Badge icon="exclamation-circle" color="warning" fill>
                                    <FormattedMessage id={`${msgKey}.required`} />
                                </Badge>
                            </Tip>
                        </Col>
                    )}
                </Row>
            </div>
            <h2 className={styles.title} lang={course.language || "en"}>
                {course.title}
            </h2>
            <div className={styles.background}>
                <Image
                    squared
                    grayscale={disabled || !course.published}
                    size="fit"
                    src={course.cover?.url || coverImage}
                    alt={course.title}
                />
            </div>
            {showPrompt === "DELETE" && (
                <PopupYesNo
                    title={<FormattedMessage id={`${msgKey}.deleteCourse`} />}
                    onYes={() => setShowPrompt(null) ?? onDelete()}
                    onNo={() => setShowPrompt(null)}
                >
                    <FormattedMessage id={`${msgKey}.deleteCourseConfirmation`} values={{ title: course.title }} />
                </PopupYesNo>
            )}
            {showPrompt === "RESTORE" && (
                <PopupYesNo
                    title={<FormattedMessage id={`${msgKey}.restoreCourse`} />}
                    onYes={() => setShowPrompt(null) ?? onRestore()}
                    onNo={() => setShowPrompt(null)}
                >
                    <FormattedMessage id={`${msgKey}.restoreCourseConfirmation`} values={{ title: course.title }} />
                </PopupYesNo>
            )}
            <Tooltip id="course_cover_item" />
        </div>
    );
};
