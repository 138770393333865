import React from "react";
import PropTypes from "prop-types";
import style from "./style.scss";
import classNames from "classnames";

import Row from "../../grid/Row";
import Col from "../../grid/Col";
import { Text } from "../../typography/Text";

export const FormItem = ({ fieldCol = 12, labelCol = 6, label, error, compact, spacing, children }) => {
    // Add props to children
    let hasTextContent = false;
    const childrenWithProps = React.Children.map(children, (child) => {
        // Check if null
        if (!child) {
            return null;
        }

        // Check if text
        if (child.type === Text) {
            hasTextContent = true;
        }

        // Check span
        if (child.type === "span") {
            return child;
        }

        // Clone element
        if (child.type === Row) {
            return child;
        } else {
            return React.cloneElement(child, {
                error: error
            });
        }
    });

    return (
        <div
            className={classNames(style.base, {
                [style.error]: error,
                [style.isCompact]: compact,
                [style.hasText]: hasTextContent,

                [style.spacingSmall]: spacing === "small",
                [style.spacingMedium]: spacing === "medium",
                [style.spacingNone]: spacing === "none"
            })}
        >
            <Row gutter={["none", "none"]}>
                {/* Label */}
                {!compact || (compact && label) ? (
                    <Col className={style.label} lg={labelCol} xl={7}>
                        {label ? (
                            label === true ? (
                                <label>&nbsp;</label>
                            ) : (
                                <label>
                                    {label}
                                    <span className={style.colon}>:</span>
                                </label>
                            )
                        ) : (
                            <span />
                        )}
                    </Col>
                ) : null}

                {/* Content */}
                <Col className={style.field} lg={fieldCol} xl={10}>
                    {childrenWithProps}
                </Col>
            </Row>
        </div>
    );
};

FormItem.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]),
    labelCol: PropTypes.number,
    fieldCol: PropTypes.number,
    error: PropTypes.any,
    compact: PropTypes.bool,
    spacing: PropTypes.string
};
