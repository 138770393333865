import React from "react";
import styles from "./H3.scss";
import classNames from "classnames";

export const H3 = ({ children, margin }) => {
    return (
        <h3
            className={classNames(styles.h3, {
                [styles.marginNone]: margin === "none"
            })}
        >
            {children}
        </h3>
    );
};
