import React from "react";
import styles from "./ButtonGroup.scss";
import classNames from "classnames";

import { Button } from "../Button";

export const ButtonGroup = ({ children }) => {
    return (
        <div className={styles.base}>
            {React.Children.map(children, (child) => {
                if (child && (child.type === Button || child.type.name?.includes("Button"))) {
                    return React.cloneElement(child, {
                        className: classNames(styles.item, {
                            [styles.active]: child.props.active
                        })
                    });
                }
            })}
        </div>
    );
};
