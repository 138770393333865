import React from "react";
import ReactCookieConsent from "react-cookie-consent";
import { FormattedMessage } from "react-intl";
import styles from "./CookieConsent.scss";

const msgKey = "cookieConsent";

export const CookieConsent = () => {
    return (
        <ReactCookieConsent
            location="bottom"
            buttonText={<FormattedMessage id={`${msgKey}.consent`} />}
            cookieName="cookieConsent"
            buttonClasses={styles.button}
            contentClasses={styles.content}
            containerClasses={styles.container}
            expires={150}
        >
            <FormattedMessage
                id={`${msgKey}.message`}
                values={{
                    a: (msg) => (
                        <a href="/privacy" target="_blank">
                            {msg}
                        </a>
                    )
                }}
            />
        </ReactCookieConsent>
    );
};
