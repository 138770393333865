import React from "react";
import classNames from "classnames";
import style from "./style.scss";
import { Clickable } from "../../button/Clickable";

export const SignUpButton = ({ className, children }) => {
    return (
        <Clickable className={classNames(style.base, className)} link={`/signup`}>
            {children}
        </Clickable>
    );
};
