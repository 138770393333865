import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Popup from "../../notify/Popup";
import styles from "./TimePicker.scss";
import { TimeSelector } from "./parts/TimeSelector";

export const TimePicker = ({ onChange, onClose, step = 5, value }) => {
    const [hours, setHours] = useState(null);
    const [minutes, setMinutes] = useState(null);
    const [selectorType, setSelectorType] = useState("HOURS");

    useEffect(() => {
        const date = parseDate(value);
        setHours(date.getHours());
        setMinutes(date.getMinutes());
    }, [value]);

    const handleChangeHours = (newHours) => {
        setHours(newHours);
        const date = parseDate(value);
        date.setHours(newHours);
        onChange && onChange(date);
    };

    const handleChangeMinutes = (newMinutes) => {
        setMinutes(newMinutes);
        const date = parseDate(value);
        date.setMinutes(newMinutes);
        onChange && onChange(date);
    };

    return (
        <Popup onClose={onClose}>
            <div className={styles.preview}>
                <button
                    className={classNames(styles.segment, {
                        [styles.selected]: selectorType === "HOURS"
                    })}
                    onClick={() => setSelectorType("HOURS")}
                >
                    {hours?.toString().length < 2 ? "0" + hours : hours}
                </button>
                <span className={styles.colon}>:</span>
                <button
                    className={classNames(styles.segment, {
                        [styles.selected]: selectorType === "MINUTES"
                    })}
                    onClick={() => setSelectorType("MINUTES")}
                >
                    {minutes?.toString().length < 2 ? "0" + minutes : minutes}
                </button>
            </div>
            <div className={styles.picker}>
                <TimeSelector
                    visible={selectorType === "HOURS"}
                    type="HOURS"
                    value={hours}
                    onChange={handleChangeHours}
                    onNext={() => setSelectorType("MINUTES")}
                />
                <TimeSelector
                    visible={selectorType === "MINUTES"}
                    type="MINUTES"
                    step={step}
                    value={minutes}
                    onChange={handleChangeMinutes}
                    onNext={onClose}
                />
            </div>
        </Popup>
    );
};

const parseDate = (value) => {
    if (!value) return new Date();
    const date = value instanceof Date ? value : new Date(value);
    return isNaN(date) ? new Date() : date;
};
