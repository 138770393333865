import React from "react";
import style from "./style.scss";

import { Button } from "../Button";

export default class ButtonColumn extends React.Component {
    render() {
        // Iterate buttons
        const buttons = React.Children.map(this.props.children, (child, index) => {
            if (child.type === Button) {
                return React.cloneElement(child, {
                    className: style.button
                });
            }
        });

        return (
            <div className={style.base}>
                {buttons &&
                    buttons.map((button, index) => (
                        <div key={index} className={style.buttonWrapper}>
                            {button}
                        </div>
                    ))}
            </div>
        );
    }
}
