import React from "react";

import { FormattedMessage } from "react-intl";
import { PageMessage } from "../../page/PageMessage";
import { Text } from "../../typography/Text";
import { ShieldLogo } from "../../graphic/ShieldLogo";

const msgKey = "noAccessMessage";

export default class NoAccessMessage extends React.Component {
    render() {
        return (
            <PageMessage title={<FormattedMessage id={`${msgKey}.title`} />} figure={<ShieldLogo size={100} />}>
                <Text block size="medium" color="base80">
                    <FormattedMessage id={`${msgKey}.message`} />
                </Text>
            </PageMessage>
        );
    }
}
