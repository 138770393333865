import classNames from "classnames";
import React from "react";
import styles from "./PageHeader.scss";

import { Icon } from "../../graphic/Icon";
import { ContentWrapper } from "../../layout/ContentWrapper";
import { Choices } from "./parts/Choices";

export const PageHeader = ({ icon, title, description, image, choices, pinned }) => {
    return (
        <div
            className={classNames(styles.base, {
                [styles.hasImage]: image,
                [styles.pinned]: pinned
            })}
        >
            <div className={styles.bar}>
                <ContentWrapper>
                    <div className={styles.wrapper}>
                        <div className={styles.titles}>
                            {icon ? (
                                <div className={styles.icon}>
                                    <Icon name={icon} />
                                </div>
                            ) : image ? (
                                <div className={styles.image}>{image}</div>
                            ) : null}
                            <div className={styles.title}>
                                {title}
                                {description ? <div className={styles.description}>{description}</div> : null}
                            </div>
                        </div>
                        {choices ? (
                            <div className={styles.choices}>
                                <Choices choices={choices} pinned={pinned} />
                            </div>
                        ) : null}
                    </div>
                </ContentWrapper>
            </div>
        </div>
    );
};

// TODO: Remove permanently if crumbs is not going back
// const Crumbs = ({ crumbs }) => {
//     return crumbs?.length ? (
//         <div className={styles.crumbs}>
//             <ContentWrapper>
//                 <Breadcrumbs>
//                     {crumbs.map((item, index) => (
//                         <Crumb key={index} label={item.label} text={item.text} link={item.link} />
//                     ))}
//                 </Breadcrumbs>
//             </ContentWrapper>
//         </div>
//     ) : null;
// };
