import React from "react";
import style from "./style.scss";

import Row from "../../grid/Row";
import Col from "../../grid/Col";
import Image from "../../media/Image";
import { Box, BoxContent } from "../../box/Box";

export const PageMessage = (props) => {
    const { noBox } = props;
    return noBox ? (
        <Content {...props} />
    ) : (
        <Box>
            <BoxContent>
                <Content {...props} />
            </BoxContent>
        </Box>
    );
};

const Content = ({ image, figure, title, children }) => {
    return (
        <Row>
            <Col sm={{ span: 14, offset: 5 }}>
                <div className={style.base}>
                    {figure ? <div className={style.figure}>{figure}</div> : null}

                    {image ? (
                        <div className={style.image}>
                            <Image src={image} size="actual" />
                        </div>
                    ) : null}

                    {title ? <div className={style.title}>{title}</div> : null}

                    {children ? <div className={style.content}>{children}</div> : null}
                </div>
            </Col>
        </Row>
    );
};
