import React from "react";
import styles from "./Uploading.scss";
import { Progress } from "../../../form/Progress";

export const Uploading = ({ percent }) => (
    <div className={styles.base}>
        <div className={styles.indicator}>
            <Progress circle contrast percent={percent}>
                <div className={styles.percent}>{percent}%</div>
            </Progress>
        </div>
    </div>
);
