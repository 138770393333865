import React from "react"
import classNames from "classnames"
import style from "./style.scss"


export default class RiskMeter extends React.Component {
    render(){

        // Properties
        const { level, percent } = this.props;

        // Base class
        const baseClass = classNames(style.base, {
            [style.low]: level === "low",
            [style.mid]: level === "mid",
            [style.high]: level === "high"
        });


        // Dot classes
        const classFirst = classNames(style.dot, {
            [style.isOn]: percent > 0
        });

        const classSecond = classNames(style.dot, {
            [style.isOn]: percent > ((1/3)*100)
        });

        const classThird = classNames(style.dot, {
            [style.isOn]: percent > ((2/3)*100)
        });


        return (
            <div className={baseClass}>
                <div className={classFirst}></div>
                <div className={classSecond}></div>
                <div className={classThird}></div>
            </div>
        )
    }
}
