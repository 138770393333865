import { Editor, Transforms, Range } from "slate";

export const withCustom = (editor) => {
    const { isVoid, isInline, deleteBackward } = editor;
    editor.isInline = (element) => ["link"].includes(element.type) || isInline(element);
    editor.isVoid = (element) => ["image"].includes(element.type) || isVoid(element);

    editor.deleteBackward = (...args) => {
        const { selection } = editor;

        // Delete empty line if on top of document
        if (
            selection &&
            Range.isCollapsed(selection) &&
            selection.focus.offset === 0 &&
            Editor.string(editor, selection.anchor.path) === ""
        ) {
            const [, parentPath] = Editor.parent(editor, selection.focus.path);
            const pointBefore = Editor.before(editor, parentPath);

            // Set selection inside table cell
            if (!pointBefore) {
                Transforms.removeNodes(editor, {
                    at: editor.selection
                });
                return;
            }
        }

        deleteBackward(...args);
    };

    return editor;
};
