import React from "react";
import { Button } from "../../../button/Button";
import { ButtonGroup } from "../../../button/ButtonGroup";
import { deleteImage } from "./commands";
import { useSlate } from "slate-react";

export const ImageControls = ({ element, onUpdate, onDelete }) => {
    const editor = useSlate();
    return (
        <div onMouseDown={(event) => event.preventDefault()}>
            <ButtonGroup>
                <Button
                    square
                    icon="text-align-left"
                    active={!element.align || element.align === "left"}
                    onClick={() => {
                        onUpdate({ align: null });
                    }}
                />
                <Button
                    square
                    icon="text-align-center"
                    active={element.align === "center"}
                    onClick={() => {
                        onUpdate({ align: "center" });
                    }}
                />
                <Button
                    square
                    icon="trash"
                    onClick={() => {
                        onDelete();
                        deleteImage(editor);
                    }}
                />
            </ButtonGroup>
        </div>
    );
};
