import { Editor, Transforms } from "slate";
import { splittedTable } from "../selection";
import { splitCell } from "./splitCell";
import { addTableHeaderColumn, removeTableHeaderColumn, tableHasHeaderColumn } from "./tableHeader";

export function deleteColumn(tablePath, editor) {
    const { selection } = editor;
    if (!selection || !tablePath) return;
    const hasHeaderColumn = tableHasHeaderColumn(tablePath, editor);
    if (hasHeaderColumn) {
        removeTableHeaderColumn(tablePath, editor);
    }

    const { gridTable, getCol } = splittedTable(editor, tablePath);
    const xIndex = tablePath.length + 1;

    const [start, end] = Editor.edges(editor, selection);
    const [startNode] = Editor.nodes(editor, {
        match: (n) => n.type === "table-cell",
        at: start
    });

    const [endNode] = Editor.nodes(editor, {
        match: (n) => n.type === "table-cell",
        at: end
    });

    const [startCol] = getCol((col) => col.cell.key === startNode[0].key);
    const [endCol] = getCol((col) => col.cell.key === endNode[0].key);

    const xLeft = startCol.path[xIndex];
    const xRight = endCol.path[xIndex];

    const topLeftCol = gridTable[0][xLeft];
    const bottomRight = gridTable[gridTable.length - 1][xRight];

    Transforms.setSelection(editor, {
        anchor: Editor.point(editor, topLeftCol.originPath),
        focus: Editor.point(editor, bottomRight.originPath)
    });

    splitCell(tablePath, editor);

    const { gridTable: splittedGridTable } = splittedTable(editor, tablePath);

    const removedCells = splittedGridTable.reduce((p, c) => {
        const cells = c.slice(xLeft, xRight + 1);
        return [...p, ...cells];
    }, []);

    removedCells.forEach((cell) => {
        Transforms.removeNodes(editor, {
            at: tablePath,
            match: (n) => n.key === cell.cell.key
        });
    });

    Transforms.removeNodes(editor, {
        at: tablePath,
        match: (n) => {
            if (n.type !== "table-row") {
                return false;
            }

            if (!n.children || n.children.findIndex((cell) => cell.type === "table-cell") < 0) {
                return true;
            }

            return false;
        }
    });

    const rows = Editor.nodes(editor, {
        at: tablePath,
        match: (n) => n.type === "table-row"
    });

    for (const row of rows) {
        let minRowHeight = Infinity;
        row[0].children.forEach((cell) => {
            const { rowspan = 1 } = cell;
            if (rowspan < minRowHeight) {
                minRowHeight = rowspan;
            }
        });

        if (minRowHeight > 1 && minRowHeight < Infinity) {
            row[0].children.forEach((cell) => {
                Transforms.setNodes(
                    editor,
                    {
                        rowspan: (cell.rowspan || 1) - minRowHeight + 1
                    },
                    {
                        at: tablePath,
                        match: (n) => n.key === cell.key
                    }
                );
            });
        }
    }

    const { gridTable: removedGridTable } = splittedTable(editor, tablePath);

    // Remove table if empty
    const [contentAfterRemove] = Editor.nodes(editor, {
        at: tablePath,
        match: (n) => n.type === "table-row"
    });
    if (!contentAfterRemove) {
        const parentNode = Editor.parent(editor, tablePath);
        if (parentNode) {
            Transforms.removeNodes(editor, {
                at: parentNode[1]
            });
        }
        return;
    }

    for (let idx = 0; idx < removedGridTable[0].length; idx++) {
        let allColumnIsReal = true;
        let minColWidth = Infinity;

        for (let j = 0; j < removedGridTable.length; j++) {
            if (!removedGridTable[j][idx].isReal) {
                allColumnIsReal = false;
            } else {
                const { colspan = 1 } = removedGridTable[j][idx].cell;
                if (colspan < minColWidth) {
                    minColWidth = colspan;
                }
            }
        }

        if (allColumnIsReal && minColWidth < Infinity && minColWidth > 1) {
            for (let j = 0; j < removedGridTable.length; j++) {
                const { cell } = removedGridTable[j][idx];
                Transforms.setNodes(
                    editor,
                    {
                        colspan: (cell.colspan || 1) - minColWidth + 1
                    },
                    {
                        at: tablePath,
                        match: (n) => n.key === cell.key
                    }
                );
            }
        }
    }

    if (hasHeaderColumn) {
        addTableHeaderColumn(tablePath, editor);
    }
}
