import React from "react";

import { FormattedMessage } from "react-intl";
import { mimeTypeGroups } from "../../../utils/fileTypeUtils";

export default class FileTypeText extends React.Component {
    render() {
        // Value
        const value = this.props.value ? this.props.value.toLowerCase() : null;

        // File type text prop
        let fileType = "unknown";

        // Check for file type
        mimeTypeGroups.map((group) => {
            if (group.types.indexOf(value) >= 0) {
                fileType = group.name;
            }
            return group;
        });

        return <FormattedMessage id={`fileTypes.${fileType}`} />;
    }
}
