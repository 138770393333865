export const calculateTaskRank = (hazards) => {
    let rank = {
        total: 0,
        rank0: 0,
        rank1: 0,
        rank2: 0,
        rank3: 0
    };

    // Count up ranks
    if (hazards?.length) {
        for (let i = 0; i < hazards.length; i++) {
            if (hazards[i].assessment?.rank) {
                rank.total++;
                switch (hazards[i].assessment.rank) {
                    case 0: {
                        rank.rank0++;
                        break;
                    }
                    case 1: {
                        rank.rank1++;
                        break;
                    }
                    case 2: {
                        rank.rank2++;
                        break;
                    }
                    case 3: {
                        rank.rank3++;
                        break;
                    }
                    default:
                        break;
                }
            }
        }
    }

    return rank;
};

export const calculateRiskPercentage = (taskList) => {
    if (!taskList) {
        return null;
    }

    var highMax = 0;
    var midMax = 0;
    var lowMax = 0;

    // Find max and risk group
    for (var y = 0; y < taskList.length; y++) {
        let task = taskList[y].task;
        const rank = calculateTaskRank(taskList[y].hazards);
        taskList[y].rank = rank;

        if (!rank) {
            continue;
        }

        // High
        if (rank.rank3 > 0) {
            task.riskLevel = 3;
            if (rank.rank3 > highMax) {
                highMax = rank.rank3;
            }
        }

        // Medium
        else if (rank.rank2 > 0) {
            task.riskLevel = 2;
            if (rank.rank2 > midMax) {
                midMax = rank.rank2;
            }
        }

        // Low
        else if (rank.rank1 > 0) {
            task.riskLevel = 1;
            if (rank.rank1 > lowMax) {
                lowMax = rank.rank1;
            }
        }
    }

    // Calculate percentage
    for (var x = 0; x < taskList.length; x++) {
        let task = taskList[x].task;
        const rank = taskList[x].rank;
        if (!rank) {
            continue;
        }

        if (task.riskLevel === 3) {
            rank.percent = (rank.rank3 / highMax) * 100;
        } else if (task.riskLevel === 2) {
            rank.percent = (rank.rank2 / midMax) * 100;
        } else if (task.riskLevel === 1) {
            rank.percent = (rank.rank1 / lowMax) * 100;
        }
    }

    return taskList;
};

export const sortTaskList = (a, b) => {
    const aLevel = a.task.riskLevel;
    const bLevel = b.task.riskLevel;
    const aPercent = a.task.percent;
    const bPercent = b.task.percent;
    const aHazards = a.hazards ? a.hazards.length : 0;
    const bHazards = b.hazards ? b.hazards.length : 0;

    if (aLevel && bLevel) {
        return aLevel > bLevel
            ? -1
            : aLevel < bLevel
            ? 1
            : aPercent > bPercent
            ? -1
            : aPercent < bPercent
            ? 1
            : aHazards > bHazards
            ? -1
            : aHazards < bHazards
            ? 1
            : 0;
    } else {
        return aLevel && !bLevel ? -1 : !aLevel && bLevel ? 1 : aHazards > bHazards ? -1 : aHazards < bHazards ? 1 : 0;
    }
};

export const sortHazardList = (a, b) => {
    const aRank = a.assessment ? a.assessment.rank : null;
    const bRank = b.assessment ? b.assessment.rank : null;

    if (aRank !== null && bRank !== null) {
        return aRank < bRank ? 1 : aRank > bRank ? -1 : a.id > b.id ? -1 : a.id < b.id ? 1 : 0;
    } else {
        return aRank === null && bRank !== null
            ? 1
            : aRank !== null && bRank === null
            ? -1
            : a.id > b.id
            ? -1
            : a.id < b.id
            ? 1
            : 0;
    }
};
