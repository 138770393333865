import React from "react";
import moment from "moment";

import { FormattedMessage, FormattedNumber } from "react-intl";
import { Text } from "../../../components/typography/Text";
import { DataTable, Column, Cell } from "../../../components/table/DataTable";
import InvoiceStatusBadge from "../../../components/subscription/InvoiceStatusBadge";
import { Box, BoxTitle, BoxContent } from "../../../components/box/Box";
import Row from "../../../components/grid/Row";
import Col from "../../../components/grid/Col";
import Link from "../../button/Link";
import { Button } from "../../button/Button";

const msgKey = "invoiceList";

export const InvoiceList = ({ busy, invoiceList, hasMore, onLoadMore }) => {
    const handleInvoiceOpen = (invoice) => {
        window.open(invoice.hostedInvoiceUrl);
    };

    return (
        <Box>
            <BoxTitle>
                <FormattedMessage id={`${msgKey}.invoices`} />
            </BoxTitle>
            <BoxContent>
                <DataTable
                    rows={invoiceList}
                    busy={busy}
                    emptyCell={
                        <Cell>
                            <Text color="base40">
                                <FormattedMessage id={`${msgKey}.noInvoices`} />
                            </Text>
                        </Cell>
                    }
                >
                    <Column
                        label={<FormattedMessage id={`${msgKey}.status`} />}
                        cell={({ row }) => (
                            <Cell>
                                <InvoiceStatusBadge status={row.status} />
                            </Cell>
                        )}
                    />
                    <Column
                        label={<FormattedMessage id={`${msgKey}.invoiceNumber`} />}
                        cell={({ row }) => <Cell>{row.number}</Cell>}
                    />
                    <Column
                        label={<FormattedMessage id={`${msgKey}.date`} />}
                        cell={({ row }) => {
                            return <Cell>{moment(row.created * 1000).format("LL")}</Cell>;
                        }}
                    />
                    <Column
                        label={<FormattedMessage id={`${msgKey}.amount`} />}
                        cell={({ row }) => (
                            <Cell>
                                <FormattedNumber value={row.total / 100} currency={row.currency} style={`currency`} />
                            </Cell>
                        )}
                    />
                    <Column
                        cell={({ row }) => (
                            <Cell>
                                <Link onClick={() => handleInvoiceOpen(row)}>
                                    <FormattedMessage id={row.paid ? `${msgKey}.download` : `${msgKey}.payNow`} />
                                </Link>
                            </Cell>
                        )}
                    />
                </DataTable>
            </BoxContent>
            {hasMore ? (
                <BoxContent border="top">
                    <Row>
                        <Col sm={{ span: 12, offset: 6 }}>
                            <Button
                                onClick={() => onLoadMore(invoiceList[invoiceList.length - 1].id)}
                                fill
                                icon="repeat"
                                iconColor="primary"
                                busy={busy}
                            >
                                <FormattedMessage id={`${msgKey}.loadOlderInvoices`} />
                            </Button>
                        </Col>
                    </Row>
                </BoxContent>
            ) : null}
        </Box>
    );
};
