import React from "react";
import { FormattedMessage } from "react-intl";
import { CustomSelect, OptionItem } from "../../form/CustomSelect";
import { countryList } from "../../../utils/countryUtil";
import Flag from "../../graphic/Flag";

const msgKey = "countrySelect";

export const CountrySelect = ({ value, onChange, menuPlacement }) => {
    return (
        <CustomSelect
            value={value}
            placeholder={<FormattedMessage id={`${msgKey}.selectCountry`} />}
            onChange={onChange}
            isClearable
            isSearchable
            menuPlacement={menuPlacement}
            filterOption={(option, searchTerm) => {
                if (!searchTerm || !searchTerm.length) return true;
                const country = countryList.find((c) => c.iso === option.value?.toUpperCase());
                return (
                    country.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    country.dialCode.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    country.iso.toLowerCase().includes(searchTerm.toLowerCase())
                );
            }}
        >
            {countryList.map((country) => (
                <OptionItem
                    key={country.iso}
                    value={country.iso}
                    symbol={<Flag code={country.iso} />}
                    label={<FormattedMessage id={`countries.${country.iso}`} />}
                />
            ))}
        </CustomSelect>
    );
};
