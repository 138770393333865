import React from "react";
import classNames from "classnames";
import style from "./style.scss";

import { FormattedMessage } from "react-intl";
import { SignUpButton } from "../SignUpButton";
import { TextLogo } from "../../graphic/TextLogo";
import { Clickable } from "../../button/Clickable";
import { Icon } from "../../graphic/Icon";

export default class FrontFooter extends React.Component {
    _sendMail = () => {
        const first = "support";
        const second = "vesselplus";
        const third = "com";

        window.location.href = `mailto:${first}@${second}.${third}`;
    };

    render() {
        return (
            <div className={style.base}>
                <div className={style.action}>
                    <SignUpButton className={style.button}>
                        <FormattedMessage id="front.getStarted" />
                    </SignUpButton>
                    <div className={style.tagline}>
                        <q>
                            <FormattedMessage id="front.taglineBottom" />
                        </q>
                    </div>
                </div>
                <div className={style.info}>
                    <div className={style.logo}>
                        <TextLogo size="medium" color="contrast" />
                    </div>
                    <div className={style.social}>
                        <Clickable
                            href="https://www.facebook.com/vesselplus"
                            target="_blank"
                            className={classNames(style.button, style.facebook)}
                        >
                            <Icon className={style.icon} name="facebook" />
                        </Clickable>
                        <Clickable
                            href="https://twitter.com/vesselplus"
                            target="_blank"
                            className={classNames(style.button, style.twitter)}
                        >
                            <Icon className={style.icon} name="twitter" />
                        </Clickable>
                        <Clickable onClick={this._sendMail} className={classNames(style.button, style.email)}>
                            <Icon className={style.icon} name="envelope" />
                        </Clickable>
                    </div>
                    <div className={style.copy}>
                        <FormattedMessage id="front.madeIn" />
                        <div className={style.silent}>
                            <FormattedMessage id="front.madeBy" values={{ year: new Date().getFullYear() }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
