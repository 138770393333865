import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Icon } from "../../graphic/Icon";
import Input from "../Input";
import { TimePicker } from "./TimePicker";
import styles from "./TimePicker.scss";
import { FormattedMessage } from "react-intl";

const msgKey = "timePicker";

export const TimePickerInput = ({ value = null, onChange, placeholder, error }) => {
    const [focused, setFocused] = useState(false);

    const handleInputClick = () => {
        document.activeElement.blur();
        setFocused(true);
    };

    return (
        <div className={styles.base}>
            <Input
                placeholder={placeholder || <FormattedMessage id={`${msgKey}.setTime`} />}
                error={error}
                prepend={<Icon name="clock" className={styles.icon} />}
                onPrependClick={handleInputClick}
                value={value && moment(value).format("HH:mm")}
                onClick={handleInputClick}
            />

            {focused ? <TimePicker value={value} onChange={onChange} onClose={() => setFocused(false)} /> : null}
        </div>
    );
};

TimePickerInput.propTypes = {
    /**
     * Value of time picker
     */
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    /**
     * Change callback function
     */
    onChange: PropTypes.func,
    /**
     * Placeholder text
     */
    placeholder: PropTypes.string,
    /**
     * Error indicator
     */
    error: PropTypes.bool
};
