import React from "react";
import styles from "./LoaderHorizontal.scss";

export const LoaderHorizontal = () => {
    return (
        <div className={styles.base}>
            <div className={styles.bar}></div>
            <div className={styles.bkg}></div>
        </div>
    );
};
