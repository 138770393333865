import Popup from "./Popup";
import PopupTitle from "./PopupTitle";
import PopupFooter from "./PopupFooter";
import PopupButtons from "./PopupButtons";
import PopupContent from "./PopupContent";
import PopupAlert from "./PopupAlert";
import PopupYesNo from "./PopupYesNo";

export { Popup, PopupTitle, PopupFooter, PopupButtons, PopupContent, PopupAlert, PopupYesNo };

export default Popup;
