import React from "react";
import styles from "./Progress.scss";
import classNames from "classnames";

export const Progress = ({ current, total, hidden }) => {
    return total > 1 ? (
        <div
            className={classNames(styles.base, {
                [styles.hidden]: hidden,
                [styles.complete]: current === total
            })}
        >
            <div className={styles.items}>
                {Array.from({ length: total }, (_, idx) => (
                    <div
                        key={idx}
                        className={classNames(styles.item, {
                            [styles.current]: current === idx + 1,
                            [styles.passed]: current > idx
                        })}
                    ></div>
                ))}
            </div>
        </div>
    ) : null;
};
