import { v4 as uuid } from "uuid";

export function createTable(columns, rows) {
    const body = createTableBody(columns, rows);
    return {
        type: "table",
        children: [body],
        data: {}
    };
}

export function createTableBody(columns, rows) {
    const rowNodes = [...new Array(rows)].map(() => createRow(columns));
    return {
        type: "table-body",
        children: rowNodes,
        data: {}
    };
}

export function createRow(columns) {
    const cellNodes = [...new Array(columns)].map(() => createCell());
    return {
        type: "table-row",
        key: `row_${uuid()}`,
        data: {},
        children: cellNodes
    };
}

export function createCell({ elements, colspan, rowspan, height, width } = {}) {
    return {
        type: "table-cell",
        key: `cell_${uuid()}`,
        children: [{ text: "" }],
        width: width,
        height: height,
        colspan,
        rowspan
    };
}
