import React, { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";
import style from "./style.scss";

export const Textarea = ({
    name,
    placeholder,
    disabled,
    maxLength = 2000,
    rows = 3,
    value,
    type,
    children,
    className,
    error,
    focusOn,
    onChange
}) => {
    const intl = useIntl();
    const textareaRef = useRef(null);

    useEffect(() => {
        if (focusOn || !!error) {
            setTimeout(() => {
                textareaRef.current?.focus();
            }, 50);
        }
    }, [focusOn, error]);

    const handleChange = (e) => {
        // Check id exceeding max length
        if (maxLength && e.target.value?.length > maxLength) {
            e.target.value = e.target.value.slice(0, this.props.maxLength);
        }

        onChange && onChange(e);
    };

    return (
        <textarea
            ref={textareaRef}
            name={name}
            className={classNames(
                style.base,
                {
                    [style.error]: error
                },
                className
            )}
            placeholder={
                placeholder && typeof placeholder === "object"
                    ? intl.formatMessage({ id: placeholder.props.id })
                    : placeholder
            }
            disabled={disabled}
            maxLength={maxLength}
            rows={rows}
            value={value || ""}
            onChange={handleChange}
            type={type}
        >
            {children}
        </textarea>
    );
};
