import { Editor, Transforms } from "slate";
import { createTable } from "../creator";
import { getBlockInsertPath } from "../utils";

export function insertTable(editor) {
    if (!editor.selection) return;

    const table = createTable(3, 3);
    const nextPath = getBlockInsertPath(editor);

    // Insert table below selection
    Transforms.insertNodes(editor, table, {
        at: nextPath
    });

    // Set selection in first cell
    const cellsNodes = Editor.nodes(editor, {
        at: nextPath,
        match: (n) => n.type === "table-cell"
    });

    const firstCellPath = [...cellsNodes][0][1];
    Transforms.select(editor, firstCellPath);
}
