import { Editor, Element, Transforms } from "slate";

export const getLinkNode = (editor) => {
    const nodes = Editor.nodes(editor, {
        match: (n) => n.type === "link"
    });
    const nodeArray = [...nodes];
    return nodeArray?.length === 1 ? nodeArray[0] : null;
};

export const insertLink = (editor, url) => {
    const { selection } = editor;
    if (!!selection) {
        // Remove existing links
        removeLink(editor);

        // Wrap the currently selected range of text into a Link
        Transforms.wrapNodes(
            editor,
            {
                type: "link",
                url: url
            },
            { split: true }
        );

        // Remove the highlight and move the cursor to the end of the highlight
        Transforms.collapse(editor, { edge: "end" });
    }
};

export const removeLink = (editor, opts = {}) => {
    Transforms.unwrapNodes(editor, {
        ...opts,
        match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === "link"
    });
};
