import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import { CourseCoverItem } from "../CourseCoverItem";
import styles from "./CourseCarousel.scss";

export const CourseCarousel = ({ list }) => {
    const sliderRef = useRef(null);
    const clickedRef = useRef(false);
    const history = useHistory();
    return (
        <div>
            <Slider
                ref={sliderRef}
                swipeToSlide
                infinite={true}
                arrows={false}
                speed={200}
                buttons={false}
                centerMode
                className={styles.base}
            >
                {list?.map(({ course, required, completed }) => (
                    <div key={course.id} className={styles.item}>
                        <div
                            className={styles.overlay}
                            onMouseDown={() => (clickedRef.current = true)}
                            onMouseMove={() => (clickedRef.current = false)}
                            onMouseUp={(e) => {
                                if (clickedRef.current) {
                                    history.push(`/courses/${course.uuid}`, {
                                        from: window.location.pathname
                                    });
                                }
                            }}
                        />
                        <div className={styles.content}>
                            <CourseCoverItem course={course} required={required} completed={completed} />
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};
