import React from "react";
import style from "./style.scss";

import { Icon } from "../../graphic/Icon";

export const BoxTitle = ({ icon, onToggleCollapse, badge, action, children }) => {
    return (
        <div className={style.title}>
            {/* Icon */}
            {icon ? (
                <div className={style.icon}>
                    <Icon name={icon} />
                </div>
            ) : null}

            {/* Label */}
            <div className={style.label}>{children}</div>

            {/* Badge */}
            {badge ? <div className={style.badge}>{badge}</div> : null}

            {/* Actions */}
            <ul className={style.actions}>
                {action ? <li>{action}</li> : null}

                {/* Collapse toggle */}
                {onToggleCollapse ? (
                    <li className={style.collapseButton} onClick={onToggleCollapse}>
                        <Icon name="caret-down" className={style.caret} />
                    </li>
                ) : null}
            </ul>
        </div>
    );
};
