import React from "react";
import { Spinner } from "../../loaders/Spinner";
import { Popup } from "../Popup";
import styles from "./PopupLoader.scss";

export const PopupLoader = ({ label, description }) => {
    return (
        <Popup size="wrap">
            <div className={styles.base}>
                <Spinner large contrast />
                {(label || description) && (
                    <div className={styles.message}>
                        {label && <div className={styles.label}>{label}</div>}
                        {description && <div className={styles.description}>{description}</div>}
                    </div>
                )}
            </div>
        </Popup>
    );
};
