import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import style from "./style";

export const Clickable = React.forwardRef(
    ({ link, replace, href, activeClassName, className, target, block, children, onClick, onMouseDown, cy }, ref) => {
        const handleClick = (event) => {
            if (onClick) {
                onClick(event);
            }
        };

        if (link) {
            return (
                <NavLink
                    exact
                    to={link}
                    replace={replace}
                    onClick={handleClick}
                    className={classNames(style.base, className)}
                    activeClassName={activeClassName}
                    target={target}
                    data-cy={cy}
                >
                    {children}
                </NavLink>
            );
        } else if (href) {
            return (
                <a href={href} target={target} className={classNames(style.base, className)} data-cy={cy}>
                    {children}
                </a>
            );
        } else if (block) {
            return (
                <div ref={ref} onClick={handleClick} className={classNames(style.base, className)} data-cy={cy}>
                    {children}
                </div>
            );
        } else {
            return (
                <span
                    ref={ref}
                    onClick={handleClick}
                    onMouseDown={onMouseDown}
                    className={classNames(style.base, className)}
                    data-cy={cy}
                >
                    {children}
                </span>
            );
        }
    }
);
