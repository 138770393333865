import React, { useState, useEffect } from "react";
import { countryList, getCountryByIso } from "../../../utils/countryUtil";
import Row from "../../grid/Row";
import Col from "../../grid/Col";
import { Select, Option } from "../Select";
import Input from "../Input";
import { FormattedMessage } from "react-intl";
import { Text } from "../../typography/Text";

const msgKey = "taxIdInput";

export const TaxIdInput = ({ countryCode, taxType, taxId, onChange }) => {
    const [country, setCountry] = useState(countryCode ? getCountryByIso(countryCode) : null);
    const [taxTypeEntry, setTaxTypeEntry] = useState(null);

    useEffect(() => {
        const country = getCountryByIso(countryCode);
        setCountry(country);
        if (taxType) {
            setTaxTypeEntry(country?.taxTypes?.length ? country.taxTypes.find((t) => t.key === taxType) : null);
        } else {
            setTaxTypeEntry(country?.taxTypes?.[0]);
        }
    }, [countryCode, taxType]);

    const handleChange = (change) => {
        onChange({
            countryCode,
            taxType,
            taxId,
            ...change
        });
    };

    return (
        <Row gutter={["small", "small"]}>
            <Col span={24}>
                <Select
                    value={countryCode}
                    onChange={(value) =>
                        handleChange({
                            countryCode: value,
                            taxType: getCountryByIso(value).taxTypes?.[0].key,
                            taxId: null
                        })
                    }
                    placeholder={<FormattedMessage id={`${msgKey}.selectCountry`} />}
                >
                    {countryList.map((country) => (
                        <Option key={country.iso} value={country.iso}>
                            <FormattedMessage id={`countries.${country.iso}`} />
                        </Option>
                    ))}
                </Select>
            </Col>
            {country?.taxTypes?.length > 1 ? (
                <Col span={24}>
                    <Select
                        value={taxType}
                        onChange={(key) =>
                            handleChange({ taxType: key }) |
                            setTaxTypeEntry(country.taxTypes.find((t) => t.key === key))
                        }
                    >
                        {country?.taxTypes.map((t) => (
                            <Option key={t.key} value={t.key}>
                                {t.description}
                            </Option>
                        ))}
                    </Select>
                </Col>
            ) : null}
            {taxTypeEntry ? (
                <Col span={24}>
                    <Input
                        prepend={
                            <Text color="base60">
                                <FormattedMessage id={`${msgKey}.taxId`} />
                            </Text>
                        }
                        placeholder={taxTypeEntry?.example || "123456789"}
                        value={taxId}
                        onChange={(e) =>
                            handleChange({ taxId: e.target.value, ...(taxTypeEntry && { taxType: taxTypeEntry.key }) })
                        }
                        disabled={!country}
                    />
                </Col>
            ) : null}
        </Row>
    );
};
