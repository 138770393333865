import React from "react";
import styles from "./CenterBoxTitle.scss";

export const CenterBoxTitle = ({ children, subtitle }) => {
    return (
        <div className={styles.base}>
            <div className={styles.title}>{children}</div>
            {subtitle ? <div className={styles.subtitle}>{subtitle}</div> : null}
        </div>
    );
};
