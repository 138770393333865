import React from "react"
import style from "./style.scss"


export default class FrontPage extends React.Component {
    render(){
        return (
            <div className={style.base}>
                {this.props.children}
            </div>
        )
    }
}
