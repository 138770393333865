import { useContext } from "react";
import { AuthContext } from "../../../contexts/Auth";
import { VesselContext } from "../../../routes/vessel/VesselRoot";

export const RestrictedVessel = ({ admin, owner, captain, member, selfWithId, children }) => {
    const { vesselRole } = useContext(VesselContext);
    const { me } = useContext(AuthContext);

    if (
        (admin && vesselRole === "ADMIN") ||
        (owner && vesselRole === "OWNER") ||
        (captain && vesselRole === "CAPTAIN") ||
        (member && ["MEMBER", "CAPTAIN"].includes(vesselRole)) ||
        (selfWithId && selfWithId === me?.user.id)
    ) {
        return children;
    } else {
        return null;
    }
};
