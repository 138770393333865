import React, { Suspense, lazy } from "react";
import { PopupLoader } from "../../notify/PopupLoader";

const Component = lazy(() =>
    import(/* webpackChunkName: "ui-dates" */ "./SingleDatePicker").then((module) => ({
        default: module.SingleDatePicker
    }))
);

export const SingleDatePicker = (props) => {
    return (
        <Suspense fallback={<PopupLoader />}>
            <Component {...props} />
        </Suspense>
    );
};
