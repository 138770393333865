import React from "react";
import styles from "./ContentWrapper.scss";
import classNames from "classnames";

export const ContentWrapper = ({ children }) => {
    return (
        <div className={classNames(styles.base)}>
            <div className={styles.wrapper}>{children}</div>
        </div>
    );
};
