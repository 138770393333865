import React from "react";
import classNames from "classnames";
import style from "./style.scss";

import { Progress } from "../../form/Progress";

export default class CircleMeter extends React.Component {
    render() {
        // Properties
        const { label, value, rankLow, rankMid, rankHigh, stroke } = this.props;

        // Classes
        const classes = classNames(style.base, {
            [style.rankLow]: rankLow,
            [style.rankMid]: rankMid,
            [style.rankHigh]: rankHigh
        });

        return (
            <div className={classes}>
                <Progress
                    circle
                    percent={value}
                    rankLow={rankLow}
                    rankMid={rankMid}
                    rankHigh={rankHigh}
                    strokeWidth={stroke}
                    trailWidth={stroke}
                />
                <div className={style.label}>{label}</div>
            </div>
        );
    }
}
