import React from "react"
import classNames from "classnames"
import style from "./style.scss"

export default class FormError extends React.Component {
    render(){

        const classes = classNames(style.base);

        return (
            <div className={classes}>
                {this.props.children}
            </div>
        )
    }
}
