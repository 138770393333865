import React from "react";
import { FormattedMessage } from "react-intl";
import { CustomSelect, OptionItem } from "../../form/CustomSelect";
import { Icon } from "../../graphic/Icon";

const msgKey = "deviationPriority";

export const PrioritySelect = ({ value, onChange }) => {
    return (
        <CustomSelect
            value={value}
            placeholder={<FormattedMessage id={`${msgKey}.selectPriority`} />}
            onChange={onChange}
            isClearable
        >
            <OptionItem
                value={2}
                symbol={<Icon name="priority-up-2" color="error" />}
                label={<FormattedMessage id={`${msgKey}.value_2`} />}
            />
            <OptionItem
                value={1}
                symbol={<Icon name="priority-up-1" color="error" />}
                label={<FormattedMessage id={`${msgKey}.value_1`} />}
            />
            <OptionItem
                value={-1}
                symbol={<Icon name="priority-down-1" color="info" />}
                label={<FormattedMessage id={`${msgKey}.value_-1`} />}
            />
            <OptionItem
                value={-2}
                symbol={<Icon name="priority-down-2" color="info" />}
                label={<FormattedMessage id={`${msgKey}.value_-2`} />}
            />
        </CustomSelect>
    );
};
