import { useState } from "react";
import { useIntl } from "react-intl";
import { getLanguageByLocale } from "../../../utils/languageUtils";

export const Word = ({ data, placeholder, children }) => {
    const intl = useIntl();
    const [isoLocale] = useState(getLanguageByLocale(intl.locale)?.iso);

    let value = data?.map[isoLocale]?.text;
    if (!data) {
        return placeholder || "";
    } else if (!value) {
        console.log(`[VP] (Word) Missing translation`, data);
    }

    const output = value || `${Object.values(data.map)?.[0]?.text}˟`;
    if (typeof children === "function") {
        return children(output);
    } else {
        return output;
    }
};
