import { Editor, Range, Transforms } from "slate";

export const deleteSelectionText = (editor, callback) => {
    const selectedCells = Editor.nodes(editor, {
        match: (n) => {
            return n.type === "table-cell";
        },
        reverse: Range.isBackward(editor.selection)
    });

    // Iterate cells and delete text
    const selection = editor.selection;
    for (let [, path] of selectedCells) {
        const cellRange = Editor.range(editor, path);

        // Check if selection intersects with the cell range
        if (Range.intersection(selection, cellRange)) {
            const intersection = Range.intersection(selection, cellRange);

            // If the intersection range is valid, set the selection to it and delete the fragment
            if (intersection) {
                Transforms.setSelection(editor, intersection);
                callback();
            }
        }
    }
};
