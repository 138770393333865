import React from "react"
import style from "./style.scss"


export default class Code extends React.Component {
    render(){
        return (
            <code className={style.base}>
                {this.props.children}
            </code>
        )
    }
}
