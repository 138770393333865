import React from "react";
import classNames from "classnames";
import styles from "./Spinner.scss";

export const Spinner = ({ small, large, primary, contrast, error, className }) => {
    return (
        <div
            className={classNames(
                styles.base,
                {
                    [styles.sizeSmall]: small,
                    [styles.sizeLarge]: large,
                    [styles.colorPrimary]: primary,
                    [styles.colorContrast]: contrast,
                    [styles.colorError]: error
                },
                className
            )}
        >
            <svg className={styles.circular} viewBox="25 25 50 50">
                <circle className={styles.trail} cx="50" cy="50" r="20" fill="none" />
                <circle className={styles.path} cx="50" cy="50" r="20" fill="none" />
            </svg>
        </div>
    );
};
