import { Editor, Transforms, Range } from "slate";

export const imageKeyHandler = (editor, event) => {
    const imageNode = Editor.above(editor, {
        match: (n) => n.type === "image"
    });
    if (!imageNode) return;

    // Add new line when pressing Enter
    if (event.key === "Enter") {
        event.preventDefault();
        Transforms.insertNodes(editor, {
            type: "paragraph",
            children: [{ text: "" }]
        });
    }
};

export const withImage = (editor) => {
    const { deleteBackward } = editor;

    editor.deleteBackward = (...args) => {
        const { selection } = editor;

        // Check if going backwards into image
        if (selection && Range.isCollapsed(selection) && selection.focus.offset === 0) {
            const [, parentPath] = Editor.parent(editor, selection.focus.path);
            const pointBefore = Editor.before(editor, parentPath);

            // Set selection inside table cell
            if (pointBefore) {
                const isImage = Editor.above(editor, {
                    match: (n) => n.type === "image",
                    at: pointBefore.path
                });
                if (isImage) {
                    if (Editor.string(editor, selection.anchor.path) === "") {
                        Transforms.removeNodes(editor, {
                            at: editor.selection
                        });
                    }
                    Transforms.select(editor, isImage[1]);
                    return;
                }
            }
        }

        deleteBackward(...args);
    };

    return editor;
};
