import React from "react";
import style from "./style.scss";
import ReCAPTCHA from "react-google-recaptcha";

export const Captcha = ({ onChange }) => {
    return (
        <div className={style.base}>
            <ReCAPTCHA type="image" sitekey="6Ld_cSUUAAAAAPeePhluBUaXSEkEL-aLwX9Nq58f" onChange={onChange} />
        </div>
    );
};
