import React from "react";

import { FormattedMessage } from "react-intl";

import { Button } from "../../button/Button";
import { Popup, PopupButtons, PopupContent, PopupTitle } from "./";
import { Text } from "../../typography/Text";

export default class PopupAlert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    _onOk = () => {
        if (this.props.onOk) {
            this.props.onOk();
        }
    };

    render() {
        // Properties
        const { title, message, type, onOk } = this.props;

        return (
            <Popup onClose={onOk} type={type}>
                <PopupTitle>{title}</PopupTitle>
                <PopupContent>
                    <Text size="medium">{message}</Text>
                </PopupContent>
                <PopupButtons>
                    <Button onClick={this._onOk}>
                        <FormattedMessage id="popupAlert.ok" />
                    </Button>
                </PopupButtons>
            </Popup>
        );
    }
}
