import React from "react";
import styles from "./FrontChecklist.scss";
import { ContentWrapper } from "../../layout/ContentWrapper";
import { FormattedMessage } from "react-intl";
import { SignUpButton } from "../SignUpButton";

const numberOfPoints = 5;
const msgKey = "frontChecklist";

export const FrontChecklist = () => {
    return (
        <ContentWrapper>
            <div className={styles.base}>
                <ul>
                    {Array.from({ length: numberOfPoints }).map((_, idx) => (
                        <li key={idx}>
                            <div className={styles.point}>
                                <FormattedMessage id={`${msgKey}.title.${idx + 1}`} />
                            </div>
                            <div className={styles.description}>
                                <FormattedMessage id={`${msgKey}.description.${idx + 1}`} />
                            </div>
                        </li>
                    ))}
                </ul>
                <div className={styles.action}>
                    <SignUpButton>
                        <FormattedMessage id={`${msgKey}.getStarted`} />
                    </SignUpButton>
                </div>
            </div>
        </ContentWrapper>
    );
};
