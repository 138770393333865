import React from "react";
import style from "./style.scss";

import { FrontBar } from "../FrontBar";

export const FrontPageHeader = ({ children }) => {
    return (
        <div className={style.base}>
            <FrontBar />
            <h1 className={style.title}>{children}</h1>
        </div>
    );
};
