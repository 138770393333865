import React from "react";
import classNames from "classnames";
import style from "./style.scss";

import { Icon } from "../../graphic/Icon";

export default class Checkbox extends React.Component {
    _toggleChecked = (e) => {
        e.stopPropagation();

        if (this.props.onToggle) {
            this.props.onToggle(this.props.checked);
        }
    };

    render() {
        // Properties
        const { noMargin, checked, className, children } = this.props;

        // Base class
        const baseClasses = classNames(
            style.base,
            {
                [style.isChecked]: checked,
                [style.noMargin]: noMargin
            },
            className
        );

        return (
            <div className={baseClasses} onClick={this._toggleChecked}>
                <div className={style.check}>
                    <Icon name="check" className={style.icon} />
                </div>

                {/* Label */}
                {children ? <div className={style.label}>{children}</div> : null}
            </div>
        );
    }
}
