import React, { useEffect, useState, useContext } from "react";
import style from "./style.scss";

import { FormattedMessage } from "react-intl";
import { Textarea } from "../Textarea";
import { Clickable } from "../../button/Clickable";
import Flag from "../../graphic/Flag";
import { Text } from "../../typography/Text";
import { appLanguages } from "../../../utils/languageUtils";
import { LanguageContext } from "../../../contexts/Language";
import wordUtils from "../../../utils/wordUtils";

export const TextareaWord = ({ word, onChange, ...props }) => {
    const { wordIso, setWordIso } = useContext(LanguageContext);
    const [languageIndex, setLanguageIndex] = useState(0);

    useEffect(() => {
        const index = appLanguages.findIndex((l) => l.iso === wordIso);
        setLanguageIndex(index);
    }, [wordIso]);

    const nextLanguage = () => {
        if (languageIndex >= appLanguages.length - 1) {
            setWordIso(appLanguages[0].iso);
        } else {
            setWordIso(appLanguages[languageIndex + 1].iso);
        }
    };

    const handleChange = (e) => {
        const changedWord = wordUtils.setValue(appLanguages[languageIndex].locale, word, e.target.value);
        onChange && onChange(changedWord);
    };

    return (
        <div className={style.base}>
            <Textarea
                {...props}
                className={style.textarea}
                value={word?.map?.[appLanguages[languageIndex].iso]?.text}
                onChange={handleChange}
            />
            <Clickable className={style.append} onClick={nextLanguage}>
                <Flag code={appLanguages[languageIndex].flag} className={style.flag} />
                <Text color="base60" className={style.lang}>
                    <FormattedMessage id={`languages.${appLanguages[languageIndex].locale}`} />
                </Text>
            </Clickable>
        </div>
    );
};
