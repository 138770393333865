import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import styles from "./Wheel.scss";
import { disablePullRefresh, enablePullRefresh } from "../../../../utils/scrollUtils";

export const Wheel = ({ onChange, onClick, label }) => {
    const wheelRef = useRef();
    const steps = 24;
    const [startAngle, setStartAngle] = useState(0);
    const [rotation, setRotation] = useState(0);
    const [value, setValue] = useState({ deg: 0, dir: 0 });

    useEffect(() => {
        disablePullRefresh();
        return () => {
            enablePullRefresh();
        };
    }, []);

    useEffect(() => {
        if (typeof navigator?.vibrate === "function") {
            navigator.vibrate(1);
        }
        onChange && onChange(value.dir);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        setValue((val) => {
            let deg = Math.floor(rotation / (360 / steps)) * (360 / steps);
            if (deg > val.deg) {
                return { deg, dir: 1 };
            } else if (deg < val.deg) {
                return { deg, dir: -1 };
            }
            return val;
        });
    }, [rotation]);

    const calculateRotation = (e) => {
        const posX = e.clientX || e.touches[0].clientX;
        const posY = e.clientY || e.touches[0].clientY;
        const R2D = 180 / Math.PI;
        const { height, width, x, y } = wheelRef.current.getBoundingClientRect();
        const centerX = x + width / 2;
        const centerY = y + height / 2;
        return R2D * Math.atan2(posY - centerY, posX - centerX);
    };

    const handleDragStart = (e) => {
        document.addEventListener("dragover", preventDrop);
        const rotation = calculateRotation(e);
        setStartAngle(rotation);
    };

    const handleDrag = (e) => {
        const rotation = calculateRotation(e);
        setRotation(rotation - startAngle);
    };

    const handleDragStop = (e) => {
        const rotation = calculateRotation(e);
        setRotation(rotation - startAngle);
        document.removeEventListener("dragover", preventDrop);
    };

    const handleMouseWheel = (e) => {
        e.stopPropagation();
        setRotation(() => (e.deltaY > 0 ? rotation + 15 : rotation - 15));
    };

    return (
        <div className={styles.base}>
            <div className={styles.wheel}>
                <div
                    draggable="true"
                    onMouseDown={handleDragStart}
                    onDrag={handleDrag}
                    onDragEnd={handleDragStop}
                    onTouchStart={handleDragStart}
                    onTouchMove={handleDrag}
                    onWheel={handleMouseWheel}
                    className={styles.dragger}
                />
                <div
                    ref={wheelRef}
                    className={styles.spinner}
                    style={{ transform: `rotate(${Math.floor(rotation / (360 / steps)) * (360 / steps)}deg)` }}
                >
                    {new Array(12).fill(0).map((_, index) => {
                        const angle = 360 / 12;
                        const rotation = Math.round(270 + index * angle);
                        return (
                            <div
                                key={index}
                                className={classNames(styles.line)}
                                style={{
                                    transform: `rotate(${rotation}deg)`
                                }}
                            />
                        );
                    })}
                </div>
                <button className={styles.button} draggable="true" onClick={onClick} onWheel={handleMouseWheel}>
                    {label}
                </button>
            </div>
        </div>
    );
};

const preventDrop = (e) => {
    e.preventDefault();
};
