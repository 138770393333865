import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import style from "./style.scss";

import { PhotoView } from "react-photo-view";
import { getImageSizeUrls } from "../../../utils/fileUtils";
import { useIsMounted } from "../../../utils/hooks";
import { Clickable } from "../../button/Clickable";
import { Icon } from "../../graphic/Icon";
import { Spinner } from "../../loaders/Spinner";
import { PopupYesNo } from "../../notify/Popup";
import { FormattedMessage } from "react-intl";

export const Image = ({
    src,
    busy,
    size,
    rounded,
    squared,
    framed,
    icon,
    onClick,
    onRemove,
    onFileSelect,
    multiple = false,
    iconColor,
    alt,
    grayscale,
    lightbox,
    className
}) => {
    const [lightboxUrl, setLightboxUrl] = useState(null);
    const imageRef = useRef(null);
    const dropZoneRef = useRef();
    const isMountedRef = useIsMounted();
    const [imageUrl, setImageUrl] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [promptDelete, setPromptDelete] = useState(false);

    useEffect(() => {
        if (!isMountedRef.current || !imageRef?.current) {
            return;
        }

        const { thumbUrl, displayUrl } = getImageSizeUrls(src, imageRef.current);
        if (thumbUrl !== imageUrl) {
            setLoaded(false);
        }

        setImageUrl(thumbUrl);
        setLightboxUrl(displayUrl);
    }, [src, isMountedRef, imageUrl]);

    const handleClick = () => {
        if (onClick) {
            onClick();
        } else if (onFileSelect) {
            dropZoneRef.current.open();
        }
    };

    const handleFileSelect = (files) => {
        onFileSelect(multiple ? files : files?.[0]);
    };

    const element = (
        <div
            ref={imageRef}
            className={classNames(
                style.base,
                {
                    // Sizes
                    [style.small]: size === "small",
                    [style.medium]: size === "medium",
                    [style.large]: size === "large",
                    [style.xlarge]: size === "xlarge",
                    [style.fill]: size === "fill",
                    [style.fit]: size === "fit",
                    [style.actual]: size === "actual",

                    // Styles
                    [style.rounded]: rounded,
                    [style.squared]: squared,
                    [style.framed]: framed,
                    [style.grayscale]: grayscale,

                    // Icon color
                    [style.iconPrimary]: iconColor === "primary",
                    [style.iconSuccess]: iconColor === "success",
                    [style.iconWarning]: iconColor === "warning",
                    [style.iconError]: iconColor === "error",

                    // Clickable
                    [style.clickable]: onClick || onFileSelect || lightbox,
                    [style.dashed]: onFileSelect && !src,

                    [style.hasSource]: imageUrl,
                    [style.actualLoading]: imageUrl && !loaded && size === "actual"
                },
                className
            )}
            style={{
                width: typeof size === "number" ? `${size}px` : null,
                height: typeof size === "number" ? `${size}px` : null
            }}
            onClick={handleClick}
        >
            {/* Remove button */}
            {onRemove && imageUrl && !busy ? (
                <Clickable className={style.remove} onClick={(e) => e.stopPropagation() ?? setPromptDelete(true)}>
                    <Icon name="close" className={style.icon} />
                </Clickable>
            ) : null}

            {/* Prompt delete */}
            {promptDelete ? (
                <PopupYesNo
                    title={<FormattedMessage id="image.deleteTitle" />}
                    onYes={() => {
                        setPromptDelete(false);
                        onRemove();
                    }}
                    onNo={() => setPromptDelete(false)}
                >
                    <FormattedMessage id="image.deleteConfirm" />
                </PopupYesNo>
            ) : null}

            {/* Wrapper */}
            <div className={style.wrapper}>
                {/* Loading */}
                {busy || (imageUrl && !loaded) ? (
                    <div className={style.busy}>
                        <Spinner primary className={style.spinner} />
                    </div>
                ) : null}

                {/* Image */}
                {imageUrl ? <img alt={alt} draggable={false} src={imageUrl} onLoad={() => setLoaded(true)} /> : null}

                {/* Icon placeholder */}
                {!imageUrl && !busy ? (
                    <Icon className={style.icon} name={!icon || icon === true ? "image" : icon} />
                ) : null}
            </div>

            {/* File select dropzone */}
            {onFileSelect ? (
                <Dropzone
                    className={style.dropzone}
                    ref={dropZoneRef}
                    onDrop={handleFileSelect}
                    multiple={multiple}
                    accept={"image/*"}
                />
            ) : null}
        </div>
    );

    return lightbox && lightboxUrl ? <PhotoView src={lightboxUrl}>{element}</PhotoView> : element;
};

Image.propTypes = {
    // Image url
    src: PropTypes.string,

    // Icon placeholder
    icon: PropTypes.string,

    // Size of avatar, either a pixel number or a size value (medium|large|fill)
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    // Render a squared avatar
    squared: PropTypes.bool
};
