import React from "react";

export const Leaf = ({ attributes, children, leaf }) => {
    const { bold, code, italic, underline, strikeThrough, color, backgroundColor } = leaf;
    if (bold) {
        children = <strong>{children}</strong>;
    }

    if (code) {
        children = <code>{children}</code>;
    }

    if (italic) {
        children = <i>{children}</i>;
    }

    if (underline) {
        children = <u>{children}</u>;
    }

    if (strikeThrough) {
        children = <s>{children}</s>;
    }

    return (
        <span {...attributes} style={{ color, backgroundColor }}>
            {children}
        </span>
    );
};
