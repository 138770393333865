import React from "react";
import classNames from "classnames";
import style from "./style.scss";

import { Clickable } from "../Clickable";
import { Icon } from "../../graphic/Icon";

export const Adder = ({ onClick, link, label }) => {
    return (
        <Clickable className={classNames(style.base)} onClick={onClick} link={link}>
            <div className={style.content}>
                <div className={style.icon}>
                    <Icon name="plus" />
                </div>
                {label ? <div className={style.label}>{label}</div> : null}
            </div>
        </Clickable>
    );
};
