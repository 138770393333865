import React from "react";
import styles from "./H4.scss";
import classNames from "classnames";

export const H4 = ({ children, margin }) => {
    return (
        <h4
            className={classNames(styles.h4, {
                [styles.marginNone]: margin === "none"
            })}
        >
            {children}
        </h4>
    );
};
