import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ItemList } from "../../../list/ItemList";
import Image from "../../../media/Image";
import Badge from "../../../misc/Badge";
import { Text } from "../../../typography/Text";
import styles from "./MonthLogSummaryList.scss";
import { Button } from "../../../button/Button";
import { TitleDescriptionText } from "../../../typography/TitleDescriptionText";

const msgKey = "userTimeLog";

export const MonthLogSummaryList = ({ busy, list, onClickMonth, onDownloadReview }) => {
    return (
        <div className={styles.base}>
            <ItemList
                busy={busy}
                onItemClick={(e) => onClickMonth(e.month)}
                emptyMessage={
                    <Text color="base40">
                        <FormattedMessage id={`${msgKey}.noLogEntries`} />
                    </Text>
                }
            >
                {list?.map((entry, index) => (
                    <ItemList.Item
                        key={index}
                        value={entry}
                        prepend={<Image icon="clock" />}
                        append={[
                            entry.review ? (
                                <Badge color="success">
                                    <FormattedMessage id={`${msgKey}.reviewed`} />
                                </Badge>
                            ) : entry.month === new Date().getMonth() + 1 ? (
                                <Badge color="silent">
                                    <FormattedMessage id={`${msgKey}.current`} />
                                </Badge>
                            ) : (
                                <Badge color="error">
                                    <FormattedMessage id={`${msgKey}.notReviewed`} />
                                </Badge>
                            ),
                            <Button
                                square
                                size="small"
                                icon="download"
                                iconColor="primary"
                                disabled={!entry.review}
                                onClick={() => onDownloadReview(entry.review)}
                            />
                        ]}
                    >
                        <TitleDescriptionText
                            title={moment.months(entry.month - 1)}
                            description={
                                <Text>
                                    {entry.hourSum} <FormattedMessage id={`${msgKey}.hours`} />
                                </Text>
                            }
                        />
                    </ItemList.Item>
                ))}
            </ItemList>
        </div>
    );
};
