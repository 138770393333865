import React, { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./TextLogo.scss";

export const TextLogo = ({ size, color, className }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
    }, []);

    return loaded ? (
        <div
            className={classNames(
                styles.base,
                {
                    [styles.colorContrast]: color === "contrast",
                    [styles.colorPrimary]: color === "primary",
                    [styles.sizeMedium]: size === "medium",
                    [styles.sizeSmall]: size === "small"
                },
                className
            )}
        >
            <span>vessel</span>
            <span>plus</span>
        </div>
    ) : null;
};
