import React from "react";
import style from "./style.scss";
import { FormattedMessage, useIntl } from "react-intl";

export const Tip = ({ id, msg, children }) => {
    const intl = useIntl();

    let message = msg;
    if (msg && typeof msg === "object" && msg.type && msg.type === FormattedMessage) {
        message = intl.formatMessage({ id: msg.props.id }, msg.props.values);
    }

    return (
        <div data-for={`${id}`} className={style.tip} data-tip={message}>
            {children}
        </div>
    );
};
