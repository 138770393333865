import React from "react";
import styles from "./TitleDescriptionText.scss";

export const TitleDescriptionText = ({ title, description }) => {
    return (
        <div className={styles.base}>
            <div className={styles.title}>{title}</div>
            {description ? <div className={styles.description}>{description}</div> : null}
        </div>
    );
};
