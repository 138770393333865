import React from "react"
import classNames from "classnames"
import style from "./style.scss"


import unknownCard from "../../../assets/images/cards/unknown.png"
import visaImage from "../../../assets/images/cards/visa.png"
import mastercardImage from "../../../assets/images/cards/mastercard.png"
import americanExpressImage from "../../../assets/images/cards/american-express.png"



export default class Card extends React.Component {
    render(){

        // Properties
        const { brand, silent } = this.props;

        // Image source
        let imageSource = null;
        switch(brand.toUpperCase()){
            case "VISA": imageSource = visaImage; break;
            case "MASTERCARD": imageSource = mastercardImage; break;
            case "AMEX": imageSource = americanExpressImage; break;
            default: imageSource = unknownCard;
        }

        // Classes
        const baseClasses = classNames(style.base, {
            [style.silent]: silent
        });

        return (
            <div className={baseClasses}>
                <img alt="card" className={style.image} src={imageSource} />
            </div>
        )
    }
}
