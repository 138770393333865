import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import styles from "./ScrollableArea.scss";
import iosUtils from "../../../utils/iosUtils";

const isIOSAndSafari = iosUtils.isIOSAndSafari();

export const ScrollableArea = ({ children, onScrollX, onScrollY, suppressScrollX, suppressScrollY, silent }) => {
    const areaRef = useRef(null);
    const updateTimeout = useRef(null);

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("resize", handleResize, false);
        }
        return () => {
            if (typeof window !== "undefined") {
                window.removeEventListener("resize", handleResize, false);
            }
            clearTimeout(updateTimeout.current);
        };
    }, []);

    const handleResize = () => {
        clearTimeout(updateTimeout.current);
        updateTimeout.current = setTimeout(() => {
            areaRef.current.updateScroll();
        }, 300);
    };

    return (
        <PerfectScrollbar
            ref={areaRef}
            className={classNames(styles.base, {
                [styles.silent]: silent
            })}
            options={{
                suppressScrollX,
                suppressScrollY,
                // NOTE: handlers "click-rail" and "drag-thumb" are omitted on IOS/Safari because a bug makes the user have to click twice in order to reach the content in the scrolled content.
                handlers: isIOSAndSafari
                    ? ["keyboard", "wheel", "touch"]
                    : ["keyboard", "wheel", "touch", "click-rail", "drag-thumb"]
            }}
            onScrollX={onScrollX}
            onScrollY={onScrollY}
        >
            {children}
        </PerfectScrollbar>
    );
};
