import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { AuthContext } from "../../../contexts/Auth";
import { CustomSelect, OptionItem } from "../../form/CustomSelect";
import Image from "../../media/Image";
import { Text } from "../../typography/Text";

const msgKey = "userSelect";

export const UserSelect = ({ value, onChange, busy, userList, placeholder }) => {
    const { me } = useContext(AuthContext);
    return (
        <CustomSelect
            value={value}
            busy={busy}
            onChange={onChange}
            placeholder={placeholder || <FormattedMessage id={`${msgKey}.selectUser`} />}
            isClearable={true}
        >
            {userList?.map((user) => (
                <OptionItem
                    key={user.id}
                    value={user.id}
                    symbol={<Image src={user.picture?.url} rounded size="fit" icon="user" />}
                    label={
                        <>
                            {user.fullName}
                            {me?.user.id === user.id ? (
                                <Text color="base60">
                                    &nbsp;(
                                    <FormattedMessage id={`${msgKey}.me`} />)
                                </Text>
                            ) : null}
                        </>
                    }
                />
            ))}
        </CustomSelect>
    );
};
