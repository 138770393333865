import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import classNames from "classnames";
import style from "./style.scss";

import { Icon } from "../../graphic/Icon";
import { Clickable } from "../../button/Clickable";

const Input = (props) => {
    const {
        focusOn,
        error,
        maxLength = 255,
        onChange,
        onPrependClick,
        onAppendClick,
        big,
        icon,
        prepend,
        append,
        emphasize,
        className,
        id,
        name,
        placeholder,
        inputClassName,
        disabled,
        max,
        min,
        step,
        value = "",
        type = "text",
        intl,
        onClick,
        onBlur,
        onEnter,
        counter,
        autocomplete
    } = props;
    const inputRef = useRef();

    useEffect(() => {
        if (focusOn) {
            focusInput();
        }
    }, [focusOn]);

    useEffect(() => {
        if (error) {
            focusInput();
        }
    }, [error]);

    const handleChange = (e) => {
        let value = e.target.value;

        // Check id exceeding max length
        if (maxLength && value.length > maxLength) {
            value = value.slice(0, maxLength);
        }

        // Callback
        onChange && onChange(e);
    };

    /**
     * Set focus to input
     * @private
     */
    const focusInput = () => {
        setTimeout(() => {
            inputRef.current.focus();
        }, 50);
    };

    /**
     * Handle key press
     * @private
     */
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            onEnter && onEnter(e);
        }
    };

    return (
        <div
            className={classNames(
                style.base,
                {
                    [style.big]: big,
                    [style.error]: error,
                    [style.inputIcon]: icon,
                    [style.inputPrepend]: prepend,
                    [style.inputAppend]: append,
                    [style.emphasize]: emphasize
                },
                className
            )}
        >
            {/* ICON */}
            {icon ? <Icon name={icon} className={style.icon} size={!big ? "small" : null} /> : null}

            {/* PREPEND */}
            {prepend ? (
                <Clickable
                    className={classNames(style.prepend, {
                        [style.clickable]: !disabled && onPrependClick,
                        [style.disabled]: disabled
                    })}
                    onClick={!disabled && onPrependClick}
                >
                    <div className={style.content}>{prepend}</div>
                </Clickable>
            ) : null}

            {/* INPUT */}
            <input
                id={id}
                name={name}
                className={classNames(style.input, inputClassName)}
                placeholder={
                    placeholder && typeof placeholder === "object"
                        ? intl.formatMessage({ id: placeholder.props.id })
                        : placeholder
                }
                autoComplete={autocomplete}
                disabled={disabled}
                ref={inputRef}
                maxLength={maxLength}
                max={max}
                min={min}
                step={step}
                onChange={handleChange}
                onClick={onClick}
                onBlur={onBlur}
                onKeyPress={handleKeyPress}
                type={type}
                value={value || ""}
            />

            {/* APPEND */}
            {append ? (
                <Clickable
                    className={classNames(style.append, {
                        [style.clickable]: onAppendClick
                    })}
                    onClick={!disabled && onAppendClick}
                >
                    <div className={style.content}>{append}</div>
                </Clickable>
            ) : null}

            {/* COUNTER */}
            {counter ? <div className={style.counter}>{this.state.characters}</div> : null}
        </div>
    );
};

Input.propTypes = {
    big: PropTypes.bool, // Render big input
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Input placeholder
    onChange: PropTypes.func, // On value change callback
    type: PropTypes.string, // Input type (text, password, tel etc.)
    maxLength: PropTypes.number, // Maximum allowed characters
    max: PropTypes.number, // Max value if number type
    min: PropTypes.number, // Min value if number type
    step: PropTypes.number, // Step
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]), // Render input error indication
    counter: PropTypes.bool, // Show character counter
    icon: PropTypes.string, // Show a prepended icon
    prepend: PropTypes.object, // Prepend icon or content
    onPrependClick: PropTypes.func // Callback for when prepend is clicked
};

export default injectIntl(Input);
