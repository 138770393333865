import React from "react";
import wordUtils from "../../../utils/wordUtils";
import { injectIntl } from "react-intl";

class HighlightText extends React.Component {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const { term, children } = this.props;
        const { newTerm, newChildren } = nextProps;
        return term !== newTerm || children !== newChildren;
    }

    render() {
        const { term, word, children, intl } = this.props;
        if (!children && !word) return null;

        if (term && children) {
            return this._renderText(term, children);
        } else if (word) {
            const wordValue = wordUtils.getValue(intl.locale, word);
            return term ? this._renderText(term, wordValue) : wordValue;
        } else {
            return children;
        }
    }

    _renderText(term, children) {
        const regex = new RegExp(term, "gi");
        const content = children.replace(regex, (match) => {
            return `<mark>${match}</mark>`;
        });

        return <div dangerouslySetInnerHTML={{ __html: content }} />;
    }
}

export default injectIntl(HighlightText);
