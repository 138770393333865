import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import * as inventoryTypeService from "../../../api/vessel/inventory/inventoryType";
import * as inventoryTypeAdminService from "../../../api/admin/inventory/inventoryType";
import { VesselContext } from "../../../routes/vessel/VesselRoot";
import { CustomSelect, OptionItem } from "../../form/CustomSelect";
import { Word } from "../../lang/Word";
import Image from "../../media/Image";
import wordUtils from "../../../utils/wordUtils";
import { Text } from "../../typography/Text";
import orderIndexUtils from "../../../utils/orderIndexUtils";

const msgKey = "inventoryTypeSelect";

export const InventoryTypeSelect = ({ value, onChange, onCreate }) => {
    const { vessel } = useContext(VesselContext);
    const [typeList, setTypeList] = useState(null);

    useEffect(() => {
        if (vessel) {
            inventoryTypeService.getTypeList({ vesselId: vessel.id }).then(setTypeList);
        } else {
            inventoryTypeAdminService.getTypeList().then(setTypeList);
        }
    }, [vessel]);

    return (
        <CustomSelect
            busy={!typeList}
            value={value}
            onChange={onChange}
            isSearchable
            isClearable
            placeholder={<FormattedMessage id={`${msgKey}.selectType`} />}
            noOptionsMessage={({ inputValue }) =>
                onCreate ? (
                    <OptionItem
                        symbol={<Image size="fit" icon="plus-small" iconColor="success" />}
                        label={
                            <FormattedMessage
                                id={`${msgKey}.createNewType`}
                                values={{ name: <strong>{inputValue}</strong> }}
                            />
                        }
                        onClick={() => onCreate(inputValue)}
                    />
                ) : (
                    <FormattedMessage id={`${msgKey}.noResults`} />
                )
            }
            filterOption={(option, searchTerm) => {
                if (!searchTerm || !searchTerm.length) return true;
                const typeEntry = typeList.find((t) => t.id === option.value);
                const wordString = wordUtils.formatToString(typeEntry.name).toLowerCase();
                return (
                    wordString.includes(searchTerm.toLowerCase()) || typeEntry.keywords?.join(" ").includes(searchTerm)
                );
            }}
        >
            {typeList
                ?.sort((a, b) => orderIndexUtils.sort(a, b, "category"))
                .map((entry) => (
                    <OptionItem
                        key={entry.id}
                        value={entry.id}
                        symbol={<Image size="fit" src={entry.picture?.url} icon="tools" />}
                        label={<Word data={entry.name} />}
                        description={
                            entry.category ? (
                                <Text block size="small" color="base40">
                                    <Word data={entry.category.name} />
                                </Text>
                            ) : entry.customOnVessel ? (
                                <Text block size="small" color="base40">
                                    <FormattedMessage id={`${msgKey}.custom`} />
                                </Text>
                            ) : null
                        }
                    />
                ))}
        </CustomSelect>
    );
};
