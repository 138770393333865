import React, { useEffect, useState } from "react";
import classNames from "classnames";
import style from "./style.scss";
import { getCountryByIso } from "../../../utils/countryUtil";

// Webpack: https://webpack.js.org/guides/dependency-management/#requirecontext
require.context("../../../assets/images/flags", true);

const Flag = ({ code = "_unknown", large, muted, className }) => {
    const [flagFile, setFlagFile] = useState(null);

    useEffect(() => {
        const importFlag = async () => {
            try {
                const flagFile = await import(
                    `../../../assets/images/flags/${!code ? "_unknown" : code.toLowerCase()}.svg`
                );
                setFlagFile(flagFile.default);
            } catch (err) {
                const flagFile = await import(`../../../assets/images/flags/_unknown.svg`);
                setFlagFile(flagFile.default);
                console.info("[VP]: Missing flag: " + code);
            }
        };
        importFlag();
    }, [code]);

    return (
        <div
            className={classNames(
                style.base,
                {
                    [style.large]: large,
                    [style.muted]: muted
                },
                className
            )}
            style={{ height: `${large ? 64 : 24}px` }}
            title={code ? getCountryByIso(code)?.name : null}
        >
            <img alt={code} className={style.image} src={flagFile} />
        </div>
    );
};

export default Flag;
