import React from "react";
import style from "./style.scss";

import Row from "../../grid/Row";
import Col from "../../grid/Col";
import { Button } from "../../button/Button";
import FileSelectButton from "../../button/FileSelectButton";

export default class FormButtons extends React.Component {
    render() {
        // Properties
        const { full, children } = this.props;

        // Check if children
        if (!children || (children.constructor === Array && !children.length)) {
            return null;
        }

        // Only keep buttons
        const buttons = React.Children.map(children, (child) => {
            if (child.type === Button || child.type === FileSelectButton) {
                return React.cloneElement(child, {
                    block: true
                });
            }
        });

        // Correct column with based on the number fo buttons
        let columnProps = {
            bottomSpacing: true,
            sm: {
                span: 24 / buttons.length
            }
        };

        if (buttons.length === 1 || full) {
            columnProps = {
                sm: {
                    span: 24
                }
            };
        }

        return (
            <div className={style.base}>
                <Row gutter={["small", "small"]}>
                    {buttons.map((button, index) => (
                        <Col key={index} {...columnProps}>
                            {button}
                        </Col>
                    ))}
                </Row>
            </div>
        );
    }
}
