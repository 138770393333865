import React, { useContext, useEffect, useState } from "react";
import style from "./style.scss";
import wordUtils from "../../../utils/wordUtils";
import { FormattedMessage } from "react-intl";
import Flag from "../../graphic/Flag";
import Input from "../Input";
import { Text } from "../../typography/Text";
import { appLanguages } from "../../../utils/languageUtils";
import { LanguageContext } from "../../../contexts/Language";

export const InputWord = ({ word, onChange }) => {
    const { wordIso, setWordIso } = useContext(LanguageContext);
    const [languageIndex, setLanguageIndex] = useState(0);

    useEffect(() => {
        const index = appLanguages.findIndex((l) => l.iso === wordIso);
        setLanguageIndex(index);
    }, [wordIso]);

    const nextLanguage = () => {
        if (languageIndex >= appLanguages.length - 1) {
            setWordIso(appLanguages[0].iso);
        } else {
            setWordIso(appLanguages[languageIndex + 1].iso);
        }
    };

    const handleInputChange = (e) => {
        const changedWord = wordUtils.setValue(appLanguages[languageIndex].locale, word, e.target.value);
        onChange && onChange(changedWord);
    };

    return (
        <div className={style.base}>
            <Input
                value={word?.map?.[appLanguages[languageIndex].iso]?.text}
                append={
                    <div className={style.append}>
                        <Flag code={appLanguages[languageIndex].flag} className={style.flag} />
                        <Text color="base60" className={style.lang}>
                            <FormattedMessage id={`languages.${appLanguages[languageIndex].locale}`} />
                        </Text>
                    </div>
                }
                onChange={handleInputChange}
                onAppendClick={nextLanguage}
            />
        </div>
    );
};
