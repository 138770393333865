import React from "react";
import classNames from "classnames";
import style from "./style.scss";

export const BoxContent = ({ border, padding, children, grow, color }) => {
    return (
        <div
            className={classNames(style.content, {
                [style.borderTop]: border?.indexOf("top") >= 0,
                [style.borderBottom]: border?.indexOf("bottom") >= 0,
                [style.borderRight]: border?.indexOf("right") >= 0,
                [style.borderLeft]: border?.indexOf("left") >= 0,
                [style.paddingNone]: padding === "none",
                [style.paddingSmall]: padding === "small",
                [style.grow]: grow,
                [style.colorNote]: color === "note",
                [style.colorAttention]: color === "attention",
                [style.colorSuccess]: color === "success",
                [style.colorError]: color === "error",
                [style.colorSilent]: color === "silent"
            })}
        >
            <div className={style.wrapper}>{children}</div>
        </div>
    );
};
