import { Transforms } from "slate";

export const addSpaceAbove = (tablePath, editor) => {
    Transforms.insertNodes(
        editor,
        {
            type: "paragraph",
            children: [{ text: "" }]
        },
        {
            at: [tablePath[0]],
            select: true
        }
    );
};

export const addSpaceBelow = (tablePath, editor) => {
    Transforms.insertNodes(
        editor,
        {
            type: "paragraph",
            children: [{ text: "" }]
        },
        {
            at: [tablePath[0] + 1],
            select: true
        }
    );
};
