import React, { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../../contexts/Auth";
import { PillSelector } from "../../../button/PillSelector";
import Col from "../../../grid/Col";
import Row from "../../../grid/Row";
import { Word } from "../../../lang/Word";
import { ItemList, Item } from "../../../list/ItemList";
import Image from "../../../media/Image";
import { PopOver } from "../../../nav/PopOver";
import { Text } from "../../../typography/Text";
import { TitleDescriptionText } from "../../../typography/TitleDescriptionText";

const msgKey = "quickSelector";

export const QuickSelector = () => {
    const history = useHistory();
    const { me } = useContext(AuthContext);
    const [visible, setVisible] = useState(false);

    return (
        <PopOver
            size="large"
            visible={visible}
            onClose={() => setVisible(false)}
            align="start"
            layer="nav"
            content={
                <Row>
                    {!!me?.orgList?.length && (
                        <Col span={24}>
                            <ItemList
                                onItemClick={(orgId) => setVisible(false) || history.push(`/org/${orgId}`)}
                                hideIfEmpty
                            >
                                {me?.orgList?.map(({ organization }) => (
                                    <Item
                                        key={organization.id}
                                        value={organization.id}
                                        prepend={
                                            <Image
                                                src={organization.logo?.url}
                                                icon="organization"
                                                iconColor="primary"
                                            />
                                        }
                                    >
                                        <TitleDescriptionText
                                            title={organization.name}
                                            description={
                                                <Text>
                                                    <FormattedMessage id={`${msgKey}.organization`} />,{" "}
                                                    <FormattedMessage id={`countries.${organization.countryCode}`} />
                                                </Text>
                                            }
                                        />
                                    </Item>
                                ))}
                            </ItemList>
                        </Col>
                    )}

                    <Col span={24}>
                        <ItemList
                            onItemClick={(v) => setVisible(false) || history.push(`/vessels/${v.id}`)}
                            hideIfEmpty
                        >
                            {me?.vesselList?.map(({ vessel }) => (
                                <Item
                                    key={vessel.id}
                                    value={vessel}
                                    prepend={
                                        <Image
                                            src={vessel.picture && vessel.picture.url}
                                            icon="vessel"
                                            iconColor="primary"
                                        />
                                    }
                                >
                                    <TitleDescriptionText
                                        title={vessel.name}
                                        description={
                                            <Text>
                                                <Word data={vessel.type.name} />,{" "}
                                                <FormattedMessage id={`countries.${vessel.countryCode}`} />
                                            </Text>
                                        }
                                    />
                                </Item>
                            ))}
                        </ItemList>
                    </Col>
                </Row>
            }
        >
            <PillSelector
                label={<FormattedMessage id={`${msgKey}.label`} />}
                onClick={() => setVisible(!visible)}
                active={visible}
            />
        </PopOver>
    );
};
