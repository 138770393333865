import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./ConfirmActionButton.scss";
import { PopupYesNo } from "../../notify/Popup";
import { Button } from "../Button";
import { Row } from "../../grid/Row/Row";
import { Col } from "../../grid/Col/Col";
import { FormattedMessage } from "react-intl";

const msgKey = "confirmActionButton";

export const ConfirmActionButton = (props) => {
    const { label, size, busy, fill, silent, icon, square, disabled } = props;
    const [promptConfirm, setPromptConfirm] = useState(false);
    return (
        <div
            className={classNames(styles.base, {
                [styles.silent]: silent
            })}
        >
            {promptConfirm ? (
                <ConfirmPrompt {...props} onClose={() => setPromptConfirm(false)} />
            ) : (
                <Button
                    type="submit"
                    fill={fill}
                    color="silentError"
                    size={size}
                    icon={icon}
                    className={styles.button}
                    onClick={() => setPromptConfirm(true)}
                    busy={busy}
                    disabled={disabled}
                    square={square}
                >
                    {label}
                </Button>
            )}
        </div>
    );
};

const ConfirmPrompt = ({ busy, popup, label, question, size, onClose, onConfirm }) => {
    return popup ? (
        <PopupYesNo title={label} busy={busy} onNo={onClose} onYes={() => onClose() | onConfirm()}>
            {question}
        </PopupYesNo>
    ) : (
        <div className={styles.prompt}>
            <div className={styles.question}>{question}</div>
            <Row gutter={["small"]}>
                <Col span={12}>
                    <Button
                        fill
                        size={size}
                        icon="check"
                        iconColor="success"
                        onClick={() => {
                            onClose();
                            onConfirm();
                        }}
                    >
                        <FormattedMessage id={`${msgKey}.yes`} />
                    </Button>
                </Col>
                <Col span={12}>
                    <Button fill size={size} icon="close" iconColor="error" onClick={onClose}>
                        <FormattedMessage id={`${msgKey}.no`} />
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

ConfirmActionButton.propTypes = {
    /**
     * Button label
     */
    label: PropTypes.any,

    /**
     * Question to confirm
     */
    question: PropTypes.any,

    /**
     * Confirm (yes) handler
     */
    onConfirm: PropTypes.func,

    /**
     * Size of buttons
     */
    size: PropTypes.oneOf(["small", "big"]),

    /**
     * Display confirmation as a popup
     */
    popup: PropTypes.bool,

    /**
     * Busy indicator
     */
    busy: PropTypes.bool,

    /**
     * Button should fill with of container
     */
    fill: PropTypes.bool
};
