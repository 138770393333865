import React from "react";
import classNames from "classnames";
import style from "./style.scss";

import { FormattedMessage } from "react-intl";
import { Button } from "../../button/Button";
import { Text } from "../../typography/Text";
import { ShieldLogo } from "../../graphic/ShieldLogo/ShieldLogo";

const msgKey = "vesselPricePlan";

export const VesselPricePlan = ({
    product,
    price,
    current,
    onSelect,
    status,
    selectLabel,
    silent,
    addTax,
    children
}) => {
    const { planLevel, userCountTo, vesselLengthFrom, vesselLengthTo, storageMbQuota } = product?.metadata || {};

    // Pricing
    const unitPriceTens = parseInt(price?.unitAmount / 100, 10);
    const unitPriceDecimals = ((price?.unitAmount / 100) % 1).toFixed(2).slice(-2);

    return product && price ? (
        <div
            className={classNames(style.base, {
                [style.current]: current,
                [style.silent]: silent
            })}
        >
            {/* HEADER */}
            <div className={style.header}>
                {/* Status */}
                {status === "canceled" ? (
                    <div className={classNames(style.statusBadge, style[status])}>
                        <FormattedMessage id={`${msgKey}.canceled`} />
                    </div>
                ) : null}

                {/* Logo */}
                <div className={style.logo}>
                    <ShieldLogo silent={silent} outline />
                </div>

                {/* Title*/}
                <div className={style.title}>
                    {planLevel ? <FormattedMessage id={`planLevels.LEVEL-${planLevel}`} /> : product?.name}
                </div>

                {/* Pricing */}
                <div className={style.pricing}>
                    <div className={style.price}>
                        <div className={style.currency}>
                            <FormattedMessage id={`currency.${price?.currency.toUpperCase()}`} />
                        </div>
                        <div className={style.amount}>{unitPriceTens}</div>

                        {unitPriceDecimals !== "00" ? <div className={style.decimals}>{unitPriceDecimals}</div> : null}

                        {addTax ? (
                            <div className={style.vat}>
                                <FormattedMessage id={`${msgKey}.exVat`} />
                            </div>
                        ) : null}
                    </div>
                    <div className={style.description}>
                        <FormattedMessage
                            id={`${msgKey}.priceInterval-${price.recurring.interval}`}
                            values={{ intervalCount: price.recurring.intervalCount }}
                        />
                    </div>
                </div>
            </div>

            {/* FEATURES */}
            <ul className={style.features}>
                {/* Users */}
                <li>
                    <Text block size="large">
                        <FormattedMessage
                            id={`${msgKey}.numberOfUsers`}
                            values={{ number: <strong>{userCountTo}</strong> }}
                        />
                    </Text>
                </li>

                {/* Storage */}
                <li>
                    <Text block size="large">
                        <FormattedMessage
                            id={`${msgKey}.storageQuota`}
                            values={{ number: <strong>{storageMbQuota}</strong> }}
                        />
                    </Text>
                </li>

                {/* Vessel length */}
                <li>
                    <Text block color="base60">
                        <FormattedMessage id={`${msgKey}.vesselLength`} />
                    </Text>
                    {!vesselLengthTo ? (
                        <FormattedMessage
                            id={`${msgKey}.vesselLengthGreaterThan`}
                            values={{ from: vesselLengthFrom, strong: (msg) => <strong>{msg}</strong> }}
                        />
                    ) : (
                        <FormattedMessage
                            id={`${msgKey}.vesselLengthRange`}
                            values={{
                                from: vesselLengthFrom + "",
                                to: vesselLengthTo + "",
                                strong: (msg) => <strong>{msg}</strong>
                            }}
                        />
                    )}
                </li>

                <li>
                    <FormattedMessage id={`${msgKey}.allFeatures`} />
                </li>

                <li>
                    <FormattedMessage id={`${msgKey}.fullSupport`} />
                </li>
            </ul>

            {/* ACTION */}
            <div className={style.action}>
                {children ? (
                    children
                ) : silent ? (
                    <Button fill disabled>
                        <FormattedMessage id={`${msgKey}.notEligible`} />
                    </Button>
                ) : current ? (
                    <Button fill icon="edit" iconColor="success" onClick={() => onSelect({ product, price })}>
                        <FormattedMessage id="vesselPricePlan.editPlan" />
                    </Button>
                ) : (
                    <Button fill icon="check" color="success" onClick={() => onSelect({ product, price })}>
                        {selectLabel ? selectLabel : <FormattedMessage id={`${msgKey}.selectThisPlan`} />}
                    </Button>
                )}
            </div>
        </div>
    ) : null;
};
