import React from "react";
import classNames from "classnames";
import style from "./style.scss";

import { Circle } from "rc-progress";

export const Progress = ({
    circle,
    percent,
    size,
    spacing = true,
    animation = true,
    primary,
    success,
    warning,
    contrast,
    gradual,
    error,
    rankLow,
    rankMid,
    rankHigh,
    strokeWidth = 4,
    trailWidth = 4,
    className,
    children
}) => {
    return circle ? (
        <div
            className={classNames(
                style.progressCircle,
                {
                    [style.success]: success,
                    [style.error]: error,
                    [style.contrast]: contrast,
                    [style.rankLow]: rankLow,
                    [style.rankMid]: rankMid,
                    [style.rankHigh]: rankHigh,

                    [style.gradual_20]: gradual && percent <= 20,
                    [style.gradual_40]: gradual && percent > 20 && percent <= 40,
                    [style.gradual_60]: gradual && percent > 40 && percent <= 60,
                    [style.gradual_80]: gradual && percent > 60 && percent <= 80,
                    [style.gradual_100]: gradual && percent > 80
                },
                className
            )}
        >
            <Circle
                className={style.circle}
                percent={percent}
                strokeWidth={strokeWidth}
                trailWidth={trailWidth}
                strokeLinecap="round"
            />

            {children ? <div className={style.label}>{children}</div> : null}
        </div>
    ) : (
        <div
            className={classNames(
                style.progressBar,
                {
                    [style.sizeSmall]: size === "small",
                    [style.spacing]: spacing,
                    [style.animation]: animation,

                    [style.primary]: primary,
                    [style.success]: success,
                    [style.warning]: warning,
                    [style.error]: error,

                    [style.gradual_20]: gradual && percent <= 20,
                    [style.gradual_40]: gradual && percent > 20 && percent <= 40,
                    [style.gradual_60]: gradual && percent > 40 && percent <= 60,
                    [style.gradual_80]: gradual && percent > 60 && percent <= 80,
                    [style.gradual_100]: gradual && percent > 80
                },
                className
            )}
        >
            <div className={style.bar}>
                <div className={style.info}>
                    <div className={style.label}>{children}</div>
                    <div className={style.percent}>{percent}%</div>
                </div>
                <div className={style.trail}>
                    <div style={{ width: percent + "%" }} className={style.indicator} />
                </div>
            </div>
        </div>
    );
};
