import React, { Suspense, lazy } from "react";
import { PopupLoader } from "../../notify/PopupLoader";
const Component = lazy(() => import(/* webpackChunkName: "ui-rte" */ "./RichTextEditor"));
export const RichTextEditor = (props) => {
    return (
        <Suspense fallback={<PopupLoader />}>
            <Component {...props} />
        </Suspense>
    );
};
