import { Editor, Transforms } from "slate";
import { splittedTable } from "../selection";
import { splitCell } from "./splitCell";
import { addTableHeaderRow, removeTableHeaderRow, tableHasHeaderRow } from "./tableHeader";

export function deleteRow(tablePath, editor) {
    const { selection } = editor;
    if (!selection || !tablePath) return;
    const hasHeaderRow = tableHasHeaderRow(tablePath, editor);
    if (hasHeaderRow) {
        removeTableHeaderRow(tablePath, editor);
    }

    const { gridTable, getCol } = splittedTable(editor, tablePath);
    const yIndex = tablePath.length;

    const [start, end] = Editor.edges(editor, selection);
    const [startNode] = Editor.nodes(editor, {
        match: (n) => n.type === "table-cell",
        at: start
    });

    const [endNode] = Editor.nodes(editor, {
        match: (n) => n.type === "table-cell",
        at: end
    });

    const [startCol] = getCol((col) => col.cell.key === startNode[0].key);
    const [endCol] = getCol((col) => col.cell.key === endNode[0].key);

    const yTop = startCol.path[yIndex];
    const yBottom = endCol.path[yIndex];

    const topLeftCol = gridTable[yTop][0];
    const bottomRight = gridTable[yBottom][gridTable[yBottom].length - 1];

    Transforms.setSelection(editor, {
        anchor: Editor.point(editor, topLeftCol.originPath),
        focus: Editor.point(editor, bottomRight.originPath)
    });

    splitCell(tablePath, editor);

    const { gridTable: splittedGridTable } = splittedTable(editor, tablePath);

    const removeCols = splittedGridTable.slice(yTop, yBottom + 1).reduce((p, c) => [...p, ...c], []);

    removeCols.forEach((col) => {
        Transforms.removeNodes(editor, {
            at: tablePath,
            match: (n) => n.key === col.cell.key
        });
    });

    Transforms.removeNodes(editor, {
        at: tablePath,
        match: (n) => {
            if (n.type !== "table-row") {
                return false;
            }

            if (!n.children || n.children.findIndex((cell) => cell.type === "table-cell") < 0) {
                return true;
            }

            return false;
        }
    });

    // Remove table if empty
    const [contentAfterRemove] = Editor.nodes(editor, {
        at: tablePath,
        match: (n) => n.type === "table-row"
    });
    if (!contentAfterRemove) {
        const parentNode = Editor.parent(editor, tablePath);
        if (parentNode) {
            Transforms.removeNodes(editor, {
                at: parentNode[1]
            });
        }
    }

    if (hasHeaderRow) {
        addTableHeaderRow(tablePath, editor);
    }
}
