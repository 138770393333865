import moment from "moment";
import React, { useEffect, useState } from "react";
import { Spinner } from "../../../loaders/Spinner";
import styles from "./TimeTable.scss";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { filterHourEntry, sumEntryHours } from "./helpers";
import classNames from "classnames";
import { ValueStep } from "./parts/ValueStep";
import { USER_LOG_ENTRY_TYPES } from "../../../../api/constants";
import { Text } from "../../../typography/Text";

const msgKey = "timeTable";

export const TimeTable = ({ extended, printable, busy, activeMonth, entries, onClickHour, onClickEntry }) => {
    const [dateList, setDateList] = useState([]);

    useEffect(() => {
        if (!activeMonth) {
            return;
        }
        let newDateList = [];
        let loopDate = new Date(activeMonth);
        while (loopDate.getMonth() === activeMonth.getMonth()) {
            const tempDate = loopDate;
            newDateList.push({
                date: loopDate,
                entries: entries?.filter(
                    (e) =>
                        moment(e.startTime).isSame(tempDate, "day") ||
                        moment(e.endTime).isSame(tempDate, "day") ||
                        moment(tempDate).isBetween(e.startTime, e.endTime)
                )
            });
            loopDate = new Date(moment(loopDate).add(1, "days"));
        }
        setDateList(newDateList);
    }, [activeMonth, entries]);

    return (
        <div className={classNames(styles.base, { [styles.extended]: extended, [styles.printable]: printable })}>
            {busy && (
                <div className={styles.loader}>
                    <Spinner />
                </div>
            )}
            <div className={styles.table}>
                <table>
                    {/* Headings */}
                    <thead>
                        <tr>
                            <th className={styles.cornerCell}>
                                <FormattedMessage id={`${msgKey}.hourShort`} />
                            </th>
                            {new Array(24).fill(0).map((_, hour) => (
                                <th key={hour} className={styles.hourCell}>
                                    <div className={styles.label}>{hour}</div>
                                    <div className={styles.endLine} />
                                    <div className={styles.midLine} />
                                </th>
                            ))}
                            {extended && (
                                <React.Fragment>
                                    {Object.values(USER_LOG_ENTRY_TYPES).map((type) => (
                                        <th style={{ width: "1rem" }} key={type.value}>
                                            <FormattedMessage id={type.shortLabel} />
                                        </th>
                                    ))}
                                    <th>
                                        <FormattedMessage id={`${msgKey}.remarks`} />
                                    </th>
                                </React.Fragment>
                            )}
                        </tr>
                    </thead>

                    {/* Days */}
                    <tbody>
                        {dateList.map((row, index) => (
                            <tr
                                key={index}
                                className={classNames({
                                    [styles.rowWeekend]: row.date.getDay() === 6 || row.date.getDay() === 0,
                                    [styles.rowToday]: !printable && moment(row.date).isSame(new Date(), "day")
                                })}
                            >
                                {/* DATE */}
                                <td className={styles.dateCell}>{row.date.getDate()}.</td>

                                {/* HOURS */}
                                {new Array(24).fill(0).map((_, hour) => (
                                    <HourCell
                                        key={hour}
                                        hour={hour}
                                        row={row}
                                        onClickHour={onClickHour}
                                        onClickEntry={onClickEntry}
                                    />
                                ))}

                                {/* EXTENDED */}
                                {extended && (
                                    <React.Fragment>
                                        {Object.values(USER_LOG_ENTRY_TYPES).map((type) => (
                                            <HourSumDayCell
                                                key={type.value}
                                                type={type.value}
                                                date={row.date}
                                                entries={row.entries}
                                            />
                                        ))}
                                        <RemarksCell entries={row.entries} />
                                    </React.Fragment>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const HourCell = ({ hour, row, onClickHour, onClickEntry }) => {
    const valueEntries = row.entries?.filter((entry) => filterHourEntry(row.date, hour, entry));
    return (
        <td
            className={classNames(styles.valueCell, {
                [styles.clickHour]: onClickHour && valueEntries?.length === 0
            })}
            onClick={() =>
                valueEntries?.length === 1
                    ? onClickEntry && onClickEntry(valueEntries[0])
                    : valueEntries?.length === 0
                    ? onClickHour && onClickHour(row.date, hour)
                    : null
            }
        >
            {/* Draw entry hours */}
            {valueEntries?.map((e, idx) => (
                <ValueStep key={idx} entry={e} date={row.date} hour={hour} isClickable={!!onClickEntry} />
            ))}

            {/* Day label */}
            {hour === 0 && <div className={styles.dayLabel}>{moment(row.date).format("dddd").substring(0, 3)}</div>}

            <div className={styles.endLine} />
            <div className={styles.midLine} />
        </td>
    );
};

const HourSumDayCell = ({ type, date, entries }) => {
    const hoursSum = sumEntryHours({ date, entries, type });
    return (
        <td>
            <Text color={hoursSum <= 0 ? "base20" : null}>
                <FormattedNumber value={hoursSum} />
            </Text>
        </td>
    );
};

const RemarksCell = ({ entries }) => {
    return (
        <td className={styles.remarksCell}>
            {entries
                ?.reduce((remarks, entry, idx) => {
                    return entry.remarks
                        ? `${remarks} ${entry.remarks} ${idx < entries.length - 1 ? " /" : ""}`
                        : remarks;
                }, "")
                .trim()}
        </td>
    );
};
