import React from "react"
import classNames from "classnames"
import style from "./style.scss"


export default class Paragraph extends React.Component {
    render(){

        // Properties
        const { lead, align, noMargin } = this.props;

        // Classes
        const classes = classNames(style.base, {
            [style.lead]: lead,
            [style.centerAlign]: align === "center",
            [style.rightAlign]: align === "right",
            [style.noMargin]: noMargin
        });

        return (
            <p className={classes}>
                {this.props.children}
            </p>
        )
    }
}
