import React from "react";
import style from "./style.scss";
import moment from "moment";

import { BUILD_VERSION, BUILD_TIMESTAMP } from "../../../constants";
import config from "../../../config";

export default class Disclaimer extends React.Component {
    render() {
        return (
            <div className={style.base}>
                Vessel Plus AS &copy; {new Date().getFullYear()}
                <br />v{BUILD_VERSION} ({moment(parseInt(BUILD_TIMESTAMP, 10)).format("DD-MM-YYYY HH:mm")}) [
                {config.ENV}]
            </div>
        );
    }
}
