import React, { useContext } from "react";
import { EditorContext } from "../RichTextEditor";

export const LinkComponent = ({ attributes, children, element }) => {
    const { setShowPrompt } = useContext(EditorContext);
    return (
        <a onClick={() => setShowPrompt("link")} {...attributes} href={element.url} title={element.url}>
            {children}
        </a>
    );
};
