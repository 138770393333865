import React from "react";
import classNames from "classnames";
import style from "./style.scss";

import { Clickable } from "../../button/Clickable";
import { Icon } from "../../graphic/Icon";
import { Figure } from "../../graphic/Figure";
import Checkbox from "../../form/Checkbox";
import { Progress } from "../../form/Progress";

export default class FileItem extends React.Component {
    render() {
        // Properties
        const {
            title,
            type,
            description,
            link,
            locked,
            onClick,
            onCheck,
            checked,
            actions,
            selected,
            progress,
            custom,
            indent
        } = this.props;

        // Icon
        let icon = null;
        let iconClass = null;
        switch (type) {
            // Other
            case "return":
                icon = "level-up";
                iconClass = style.returnIcon;
                break;
            case "folder":
                icon = "doc-folder";
                break;
            case "paper":
                icon = "doc-paper";
                break;

            // Images
            case "image/jpeg":
                icon = "doc-jpg";
                break;
            case "image/jpg":
                icon = "doc-jpg";
                break;
            case "image/png":
                icon = "doc-png";
                break;
            case "image/gif":
                icon = "doc-gif";
                break;
            case "image/svg+xml":
                icon = "doc-svg";
                break;

            // PDF
            case "application/pdf":
                icon = "doc-pdf";
                break;

            // Word
            case "application/msword":
                icon = "doc-word";
                break;
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                icon = "doc-word";
                break;
            case "application/rtf":
                icon = "doc-word";
                break;
            case "text/rtf":
                icon = "doc-word";
                break;
            case "text/plain":
                icon = "doc-txt";
                break;

            // Spreadsheets
            case "application/vnd.ms-excel":
                icon = "doc-xls";
                break;
            case "application/excel":
                icon = "doc-xls";
                break;
            case "application/x-msexcel":
                icon = "doc-xls";
                break;
            case "application/x-excel":
                icon = "doc-xls";
                break;
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                icon = "doc-xls";
                break;

            // Presentations
            case "application/vnd.ms-powerpoint":
                icon = "doc-ppt";
                break;
            case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
                icon = "doc-ppt";
                break;

            default:
                icon = "doc-default";
        }

        // Classes
        const classes = classNames(style.item, {
            [style.selected]: selected,
            [style.clickable]: onClick || link,
            [style.indent]: indent
        });

        return (
            <div className={classes} title={typeof title === "string" ? title : null}>
                {/* Icon */}
                <Clickable link={link} onClick={onClick} className={classNames(style.icon, iconClass)}>
                    <Figure name={icon} />
                    {locked ? (
                        <div title={locked.fullName} className={style.locked}>
                            <Icon name="lock" />
                        </div>
                    ) : null}
                </Clickable>

                {/* Details */}
                {custom ? (
                    <div className={style.custom}>
                        <div className={style.customWrapper}>{custom}</div>
                    </div>
                ) : (
                    <Clickable link={link} onClick={onClick} className={style.details}>
                        {progress === 0 || progress ? (
                            <Progress
                                percent={progress}
                                success={progress >= 100}
                                animation={progress > 0 && progress < 100}
                                spacing={false}
                                size="small"
                            >
                                {title}
                            </Progress>
                        ) : (
                            <React.Fragment>
                                <div className={style.title}>{title}</div>
                                <div className={style.description}>{description}</div>
                            </React.Fragment>
                        )}
                    </Clickable>
                )}

                {/* Actions */}
                {actions ? (
                    <div className={style.action}>
                        <div className={style.wrapper}>{actions}</div>
                    </div>
                ) : null}

                {/* Checkbox */}
                {type !== "return" && onCheck ? (
                    <div className={style.checker}>
                        <div className={style.wrapper}>
                            <Checkbox noMargin checked={checked} onToggle={onCheck} />
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}
