import React from "react";
import classNames from "classnames";
import style from "./hazard-card.scss";

import { FormattedMessage } from "react-intl";
import { Icon } from "../../../graphic/Icon";
import { Box } from "../../../box/Box";

export const HazardCard = ({ hazard, onClick, preview }) => {
    return (
        <Box
            className={classNames(style.base, {
                [style.preview]: preview,
                [style.high]: hazard?.assessment?.rank === 3,
                [style.mid]: hazard?.assessment?.rank === 2,
                [style.low]: hazard?.assessment?.rank === 1,
                [style.missing]: hazard?.assessment?.rank === null || isNaN(hazard?.assessment?.rank)
            })}
            onClick={() => (!preview ? onClick(hazard) : null)}
        >
            <div className={style.rank}>
                <div className={style.label}>
                    <div className={style.text}>
                        <FormattedMessage id={`riskTable.rank-${getRankValue(hazard?.assessment?.rank)}`} />
                    </div>
                </div>
            </div>
            <div className={style.details}>
                <div className={style.description}>{hazard.description}</div>

                {hazard?.assessment?.measures ? (
                    <div className={style.measures}>
                        <Icon name="arrow-down-right" className={style.icon} />
                        {hazard?.assessment?.measures}
                    </div>
                ) : null}
            </div>
            <div className={style.line} />
        </Box>
    );
};

const getRankValue = (rank) => {
    switch (rank) {
        case 3:
            return "high";
        case 2:
            return "mid";
        case 1:
            return "low";
        case 0:
            return "none";
        default:
            return "missing";
    }
};
