import moment from "moment";
import React from "react";
import { Button } from "../../../button/Button";
import styles from "./MonthYearBrowser.scss";
import { ButtonGroup } from "../../../button/ButtonGroup";
import { Row } from "../../../grid/Row/Row";
import { Col } from "../../../grid/Col/Col";

export const MonthYearBrowser = ({ selected, setSelected, date, setDate }) => {
    const nextYear = () => {
        const newDate = new Date(date);
        newDate.setFullYear(newDate.getFullYear() + 1);
        setDate(newDate);
    };

    const previousYear = () => {
        const newDate = new Date(date);
        newDate.setFullYear(newDate.getFullYear() - 1);
        setDate(newDate);
    };

    const nextMonth = () => {
        const newDate = new Date(date);
        if (newDate.getMonth() >= 11) {
            newDate.setMonth(0);
            newDate.setFullYear(date.getFullYear() + 1);
            setDate(newDate);
        } else {
            newDate.setMonth(newDate.getMonth() + 1);
            setDate(newDate);
        }
    };

    const previousMonth = () => {
        const newDate = new Date(date);
        if (newDate.getMonth() <= 0) {
            newDate.setMonth(11);
            newDate.setFullYear(date.getFullYear() - 1);
            setDate(newDate);
        } else {
            newDate.setMonth(newDate.getMonth() - 1);
            setDate(newDate);
        }
    };

    return (
        <div className={styles.base}>
            <Row gutter={["none", "none"]}>
                <Col span={4} shrink>
                    <Button
                        circle
                        icon="arrow-left"
                        iconColor="primary"
                        onClick={selected === "MONTH" ? previousMonth : previousYear}
                    />
                </Col>
                <Col span={16}>
                    <ButtonGroup>
                        <Button
                            pill
                            color={selected === "MONTH" ? "primary" : "silent"}
                            onClick={() => setSelected("MONTH")}
                        >
                            {moment(date).format("MMMM")}
                        </Button>
                        <Button
                            pill
                            color={selected === "YEAR" ? "primary" : "silent"}
                            onClick={() => setSelected("YEAR")}
                        >
                            {date.getFullYear()}
                        </Button>
                    </ButtonGroup>
                </Col>
                <Col span={4} shrink align={["right", "center"]}>
                    <Button
                        circle
                        icon="arrow-right"
                        iconColor="primary"
                        onClick={selected === "MONTH" ? nextMonth : nextYear}
                    />
                </Col>
            </Row>
        </div>
    );
};
