import React from "react"
import classNames from "classnames"
import style from "./style.scss"


export default class Section extends React.Component {
    render(){

        // Properties
        const { divide, align } = this.props;

        // Classes
        const classes = classNames(style.base, {
            [style.divideNone]: divide === "none",
            [style.divideTiny]: divide === "tiny",
            [style.divideSmall]: divide === "small",
            [style.divideMedium]: divide === "medium",
            [style.divideLarge]: divide === "large",

            [style.alignCenter]: align === "center"
        }, this.props.className);

        return (
            <section className={classes}>
                {this.props.children}
            </section>
        )
    }
}
