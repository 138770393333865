import React, { useContext, useState } from "react";
import classNames from "classnames";
import style from "./style.scss";

import { FormattedMessage } from "react-intl";
import { TextLogo } from "../../graphic/TextLogo";
import { Clickable } from "../../button/Clickable";
import { Icon } from "../../graphic/Icon";
import { AuthContext } from "../../../contexts/Auth";
import { MeMenu } from "../../layout/Layout/MeMenu";

export const FrontBar = ({ mode, color }) => {
    const { me } = useContext(AuthContext);
    const [menuVisible, setMenuVisible] = useState(false);

    return (
        <div
            className={classNames(
                classNames(style.base, {
                    [style.courseMode]: mode === "course",
                    [style.colorContrast]: color === "contrast"
                })
            )}
        >
            <Clickable link="/" className={style.logo}>
                <TextLogo color={mode === "course" ? "contrast" : "primary"} />
            </Clickable>

            {/* Navigation */}
            {me ? (
                <div className={style.nav}>
                    <MeMenu showName contrast={mode === "course"} />
                </div>
            ) : (
                <div className={style.nav}>
                    <Clickable onClick={() => setMenuVisible(true)} className={style.toggle}>
                        <Icon name="menu" />
                    </Clickable>
                    <div className={classNames(style.menu, { [style.visible]: menuVisible })}>
                        <div className={style.logo}>
                            <TextLogo />
                        </div>
                        <Clickable onClick={() => setMenuVisible(false)} className={style.close}>
                            <Icon name="close" />
                        </Clickable>
                        <div className={style.wrapper}>
                            <Clickable
                                className={classNames(style.item, {
                                    [style.active]: window.location.pathname === "/pricing"
                                })}
                                link={`/pricing`}
                            >
                                <FormattedMessage id="front.pricing" />
                            </Clickable>
                            <Clickable
                                className={classNames(style.item, {
                                    [style.active]: window.location.pathname === "/contact"
                                })}
                                activeClassName={style.active}
                                link={`/contact`}
                            >
                                <FormattedMessage id="front.contact" />
                            </Clickable>
                            <Clickable
                                className={classNames(style.item, style.variant, {
                                    [style.active]: window.location.pathname === "/login"
                                })}
                                activeClassName={style.active}
                                link={`/me`}
                            >
                                <FormattedMessage id="front.signIn" />
                            </Clickable>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
