import React from "react";
import classNames from "classnames";
import styles from "./Button.scss";
import { Icon } from "../../graphic/Icon";
import Flag from "../../graphic/Flag";
import { useHistory } from "react-router-dom";
import { Spinner } from "../../loaders/Spinner";
import { LoaderHorizontal } from "../../loaders/LoaderHorizontal";

export const Button = React.forwardRef(
    (
        {
            name,
            title,
            type,
            link,
            href,
            onClick,

            icon,
            flag,

            color,
            size,
            fill,

            circle,
            square,
            pill,

            iconRight,
            iconColor,
            hideIcon,

            disabled,
            active,
            busy,

            children,
            propagate = false,
            className
        },
        ref
    ) => {
        const history = useHistory();
        const handleClick = (event) => {
            // Event propagation
            if (!propagate) {
                event.stopPropagation();
            }

            // Link
            if (link) {
                history.push(link);
            }

            // Href
            else if (href) {
                var win = window.open(href, "_blank");
                win.focus();
            }

            // Handler
            else if (onClick) {
                onClick(event);
            }
        };

        return (
            <button
                ref={ref}
                name={name}
                type={type || "button"}
                onClick={handleClick}
                disabled={busy || disabled}
                title={title}
                className={classNames(
                    styles.base,
                    styles.colorDefault,
                    {
                        [styles.colorPrimary]: color === "primary",
                        [styles.colorSuccess]: color === "success",
                        [styles.colorError]: color === "error",
                        [styles.colorLight]: color === "light",
                        [styles.colorSilent]: color === "silent",
                        [styles.colorSilentError]: color === "silentError",
                        [styles.colorToolbar]: color === "toolbar",

                        [styles.sizeSmall]: size === "small",
                        [styles.sizeBig]: size === "big",

                        [styles.fill]: fill,
                        [styles.circle]: circle,
                        [styles.square]: square,
                        [styles.pill]: pill,

                        [styles.iconPrimary]: iconColor === "primary",
                        [styles.iconSuccess]: iconColor === "success",
                        [styles.iconWarning]: iconColor === "warning",
                        [styles.iconError]: iconColor === "error",

                        [styles.active]: active && !disabled,

                        [styles.iconRight]: iconRight,
                        [styles.hideIcon]: hideIcon,

                        [styles.busy]: busy
                    },
                    className
                )}
            >
                {/* Busy */}
                {busy ? (
                    <div
                        className={classNames(styles.loader, {
                            [styles.spinner]: square || circle
                        })}
                    >
                        {square || circle ? <Spinner primary /> : <LoaderHorizontal />}
                    </div>
                ) : null}

                {/* Icon left */}
                {icon && !iconRight ? <Icon className={styles.icon} name={icon} /> : null}

                {/* Flag */}
                {flag ? <Flag className={styles.flag} code={flag} muted={disabled} /> : null}

                {/* Children */}
                {!square && !circle ? <span className={styles.label}>{children}</span> : null}

                {/* Icon right */}
                {icon && iconRight ? <Icon className={styles.icon} name={icon} /> : null}
            </button>
        );
    }
);
