import React, { useState } from "react";

import { FormattedMessage } from "react-intl";
import { Button } from "../../../button/Button";
import Form from "../../../form/Form";
import { FormItem } from "../../../form/FormItem";
import { Textarea } from "../../../form/Textarea";
import { Popup, PopupButtons, PopupContent, PopupTitle } from "../../../notify/Popup";

const msgKey = "riskTable";

export const NewTaskPopup = ({ onCreate, onClose, context }) => {
    const [text, setText] = useState(null);
    const [busyCreating, setBusyCreating] = useState(false);

    const handleCreate = () => {
        setBusyCreating(true);
        onCreate(text, () => {
            onClose();
        });
    };

    return (
        <Popup onClose={onClose}>
            <PopupTitle subtitle={context.title}>
                <FormattedMessage id={`${msgKey}.newTask`} />
            </PopupTitle>
            <PopupContent>
                <Form>
                    <FormItem compact>
                        <Textarea
                            value={text}
                            maxLength={255}
                            placeholder={<FormattedMessage id={`${msgKey}.writeHere`} />}
                            onChange={(e) => setText(e.target.value)}
                        />
                    </FormItem>
                </Form>
            </PopupContent>
            <PopupButtons>
                <Button
                    type="submit"
                    onClick={handleCreate}
                    color="primary"
                    disabled={!text?.trim().length}
                    busy={busyCreating}
                >
                    <FormattedMessage id={`${msgKey}.createTask`} />
                </Button>
                <Button onClick={onClose}>
                    <FormattedMessage id={`${msgKey}.cancel`} />
                </Button>
            </PopupButtons>
        </Popup>
    );
};
