import React from "react";
import { FormattedMessage } from "react-intl";
import { CustomSelect, OptionItem } from "../../form/CustomSelect";
import { Icon } from "../../graphic/Icon";
import { Text } from "../../typography/Text";
import { ColorDot } from "../../graphic/ColorDot";

const msgKey = "deviationCategorySelect";

export const DeviationCategorySelect = ({ value, onChange, categoryList, editLink, placeholder, busy }) => {
    return (
        <CustomSelect
            value={value}
            onChange={onChange}
            busy={busy}
            placeholder={placeholder || <FormattedMessage id={`${msgKey}.allCategories`} />}
            isClearable={true}
            isSearchable={false}
        >
            {categoryList?.map((category) => (
                <OptionItem
                    key={category.id}
                    value={category.id}
                    symbol={<ColorDot color={category.color} />}
                    label={
                        <React.Fragment>
                            {category.name}{" "}
                            {category.sensitive && (
                                <Text color="base40">
                                    (<FormattedMessage id={`${msgKey}.sensitive`} />)
                                </Text>
                            )}
                        </React.Fragment>
                    }
                />
            ))}
            {editLink ? (
                <OptionItem
                    link={editLink}
                    symbol={<Icon color="base40" name="edit" />}
                    label={<FormattedMessage id={`${msgKey}.editCategories`} />}
                />
            ) : null}
        </CustomSelect>
    );
};
