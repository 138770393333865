import React from "react";
import classNames from "classnames";
import style from "./style.scss";
import { Spinner } from "../../loaders/Spinner";

export default class FileList extends React.Component {
    render() {
        // Properties
        const { bordered, embedded, busy } = this.props;

        // Classes
        const classes = classNames(style.base, {
            [style.bordered]: bordered,
            [style.embedded]: embedded
        });

        return (
            <div className={classes}>
                {this.props.children}

                {busy && (
                    <div className={style.loader}>
                        <Spinner />
                    </div>
                )}
            </div>
        );
    }
}
