import React from "react";
import style from "./style.scss";
import { FormItem } from "../FormItem";

export const Form = ({ id, action, method, onSubmit, spacing, children }) => {
    const handleFormSubmit = (event) => {
        if (!action && !method) {
            event.preventDefault();
        }

        onSubmit && onSubmit(event);
    };

    return (
        <form id={id} className={style.base} action={action} method={method} onSubmit={handleFormSubmit}>
            {React.Children.map(children, (child) => {
                // Check if child is null
                if (!child) {
                    return null;
                }

                // Child properties
                let props = {};

                // Check if form item
                if (child.type === FormItem) {
                    props.spacing = spacing;
                }

                return React.cloneElement(child, props);
            })}
        </form>
    );
};

export default Form;
