import React from "react";
import style from "./style.scss";

import { Spinner } from "../../loaders/Spinner";

export default class Checklist extends React.Component {
    _handleItemToggle = (item) => {
        if (this.props.onItemToggle) {
            this.props.onItemToggle(item);
        }
    };

    render() {
        const childrenWithProps = React.Children.map(this.props.children, (child, index) =>
            React.cloneElement(child, {
                onChange: (item) => this._handleItemToggle(item)
            })
        );

        return (
            <div className={style.base}>
                {/* Loader */}
                {this.props.busy ? (
                    <div className={style.loader}>
                        <Spinner primary className={style.spinner} />
                    </div>
                ) : null}

                {childrenWithProps}
            </div>
        );
    }
}
