import React from "react";
import classNames from "classnames";
import styles from "./BoxImage.scss";

import Image from "../../media/Image";

export const BoxImage = ({ url, icon, iconColor, corner }) => {
    return (
        <div className={classNames(styles.base)}>
            <Image src={url} size="fit" icon={icon} iconColor={iconColor} />
        </div>
    );
};
