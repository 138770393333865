import React from "react";
import classNames from "classnames";
import style from "./style.scss";
import { ScrollableArea } from "../../layout/ScrollableArea";

export default class PopupContent extends React.Component {
    render() {
        // Properties
        const { padding, border, noShrink, fill } = this.props;

        const classes = classNames(style.content, {
            [style.paddingNone]: padding === "none",
            [style.paddingMedium]: padding === "medium",
            [style.borderTop]: border === "top",
            [style.borderBottom]: border === "bottom",
            [style.noShrink]: noShrink,
            [style.fill]: fill
        });

        return (
            <div className={classes}>
                {/* ScrollableArea is added here in favor of native scroll due to a bug in mobile Safari 17.5 where scrolling does not work inside flex-box:
                 https://bugs.webkit.org/show_bug.cgi?id=275209. When this gets fixed, we can revert back to native scroll. */}
                <ScrollableArea>
                    <div className={style.inner}>{this.props.children}</div>
                </ScrollableArea>
            </div>
        );
    }
}
