import React from "react";
import classNames from "classnames";
import styles from "./KeyValueList.scss";

import { Item } from "./Item";

export const KeyValueList = ({ horizontal, compact, wrap, children }) => {
    const items = React.Children.toArray(children)?.filter((child) => child.type === Item);
    return items?.length ? (
        <table
            className={classNames(styles.base, {
                [styles.horizontal]: horizontal,
                [styles.wrap]: wrap,
                [styles.compact]: compact
            })}
        >
            {!horizontal ? (
                <tbody>
                    {items.map((child, index) => (
                        <tr key={index}>
                            <td className={styles.label}>{child.props.label}</td>
                            <td className={styles.value}>{child.props.children}</td>
                        </tr>
                    ))}
                </tbody>
            ) : (
                <tbody>
                    <tr>
                        {items.map((item, index) => (
                            <td key={index}>
                                <div className={styles.label}>{item.props.label}</div>
                                <div className={styles.value}>{item.props.children}</div>
                            </td>
                        ))}
                    </tr>
                </tbody>
            )}
        </table>
    ) : null;
};
