import React from "react";
import styles from "./LayoutFull.scss";
import { FrontBar } from "../../front/FrontBar";

export const LayoutFull = ({ children }) => {
    return (
        <div className={styles.base}>
            <div className={styles.wrapper}>
                <FrontBar />
                <div className={styles.content}>{children}</div>
            </div>
        </div>
    );
};
