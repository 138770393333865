import React from "react";
import classNames from "classnames";
import style from "./style.scss";

import { Icon } from "../../graphic/Icon";

export default class Select extends React.Component {
    _handleOnChange = (e) => {
        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    };

    render() {
        // Properties
        const { name, big, error, className, selectClassName, children, disabled, placeholder, value } = this.props;

        // Select classes
        const baseClasses = classNames(
            style.base,
            {
                [style.placeholder]: value === "" || value == null,
                [style.error]: error,
                [style.big]: big
            },
            className
        );

        // Select classes
        const selectClasses = classNames(style.select, selectClassName);

        // Placeholder
        const placeholderElement =
            placeholder && typeof placeholder === "object" ? (
                React.cloneElement(placeholder, {
                    children: (message) => {
                        return <option value={""}>{message}</option>;
                    }
                })
            ) : placeholder ? (
                <option value={""}>{placeholder}</option>
            ) : null;

        return (
            <div className={baseClasses}>
                <div className={style.arrow}>
                    <Icon className={style.icon} name="caret-down" />
                </div>
                <select
                    name={name}
                    value={value == null ? "" : value}
                    onChange={this._handleOnChange}
                    className={selectClasses}
                    disabled={disabled}
                >
                    {!value || value === "" ? placeholderElement ? placeholderElement : <option value={""} /> : null}
                    {children}
                </select>
            </div>
        );
    }
}
