import React from "react";
import classNames from "classnames";
import style from "./style.scss";

import { Clickable } from "../../button/Clickable";

export default class Radio extends React.Component {
    /**
     * Handle toggle
     */
    _handleToggle = (e) => {
        e.stopPropagation();
        if (this.props.onToggle) {
            this.props.onToggle(this.props.value);
        }
    };

    render() {
        // Properties
        const { value, groupValue, className, children, cy } = this.props;

        // Base class
        const baseClasses = classNames(
            style.radio,
            {
                [style.selected]: groupValue === value
            },
            className
        );

        return (
            <Clickable block className={baseClasses} onClick={this._handleToggle} cy={cy}>
                <div className={style.symbol}>
                    <div className={style.dot}></div>
                </div>

                {/* Label */}
                {children ? <div className={style.label}>{children}</div> : null}
            </Clickable>
        );
    }
}
