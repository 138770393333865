import { Editor, Transforms } from "slate";
import { createRow } from "../creator";
import { splittedTable } from "../selection";
import {
    addTableHeaderColumn,
    addTableHeaderRow,
    removeTableHeaderRow,
    tableHasHeaderColumn,
    tableHasHeaderRow
} from "./tableHeader";

export function insertAbove(tablePath, editor) {
    const { selection } = editor;
    if (!selection || !tablePath) return;
    const hasHeaderRow = tableHasHeaderRow(tablePath, editor);
    const hasHeaderColumn = tableHasHeaderColumn(tablePath, editor);
    if (hasHeaderRow) {
        removeTableHeaderRow(tablePath, editor);
    }
    const yIndex = tablePath.length;

    const { gridTable, getCol } = splittedTable(editor, tablePath);

    const [startCell] = Editor.nodes(editor, {
        match: (n) => n.type === "table-cell"
    });

    const [insertPositionCol] = getCol((c) => c.cell.key === startCell[0].key && c.isReal);

    let checkInsertEnable = true;
    const insertYIndex = insertPositionCol.path[yIndex];
    const insertCols = new Map();

    gridTable[insertYIndex].forEach((col) => {
        if (!col.isReal) {
            const [originCol] = getCol((c) => c.isReal && c.cell.key === col.cell.key);

            if (originCol.path[yIndex] === insertYIndex) {
                insertCols.set(originCol.cell.key, originCol);
            } else {
                checkInsertEnable = false;
                return;
            }
        } else {
            insertCols.set(col.cell.key, col);
        }
    });

    if (!checkInsertEnable) {
        return;
    }

    const newRow = createRow(insertCols.size);

    [...insertCols.values()].forEach((col, index) => {
        newRow.children[index].colspan = col.cell.colspan || 1;
    });

    const [[, path]] = Editor.nodes(editor, {
        match: (n) => n.type === "table-row"
    });

    Transforms.insertNodes(editor, newRow, {
        at: path
    });

    if (hasHeaderRow) {
        addTableHeaderRow(tablePath, editor);
    }

    if (hasHeaderColumn) {
        addTableHeaderColumn(tablePath, editor);
    }
}
