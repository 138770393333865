import React from "react"
import style from "./style.scss"


export default class Pre extends React.Component {
    render(){
        return (
            <pre className={style.base}>
                {this.props.children}
            </pre>
        )
    }
}
