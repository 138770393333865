// Based on: https://github.com/rootsher/react-auto-mosaic

import React, { Fragment, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    container: {
        display: "flex",
        flexWrap: "wrap",
        margin: ({ gutter }) => `0 ${-gutter / 2}px`
    },
    column: {
        width: "100%",
        boxSizing: "border-box",
        flex: ({ columnSize }) => `0 0 ${columnSize}%`,
        maxWidth: ({ columnSize }) => `${columnSize}%`,
        padding: ({ gutter }) => `0 ${gutter / 2}px`,

        "& > *": {
            marginBottom: ({ gutter }) => `${gutter}px`
        }
    }
});

function bucketAlgorithm(columns, list) {
    const buckets = [];
    let bucketIndex = 0;

    list.forEach((element) => {
        if (!buckets[bucketIndex]) {
            buckets[bucketIndex] = [];
        }

        buckets[bucketIndex++].push(element);

        if (bucketIndex === columns) {
            bucketIndex = 0;
        }
    });

    return buckets;
}

export const AutoMosaic = ({ children, gridColumns, gridGutterWidth, gridBreakpoints }) => {
    const [columns, setColumns] = useState(0);
    const classes = useStyles({ columnSize: 100 / columns, gutter: gridGutterWidth });
    const buckets = bucketAlgorithm(columns, children);

    useEffect(() => {
        const resizeListener = () => {
            const breakpoints = Object.keys(gridBreakpoints);
            const { innerWidth: windowSize } = window;

            for (let breakpoint = 0; breakpoint < breakpoints.length; breakpoint++) {
                if (Number(breakpoints[breakpoint]) > windowSize) {
                    const columns = gridBreakpoints[breakpoints[breakpoint]];

                    setColumns(gridColumns / columns);

                    return;
                }
            }
        };

        resizeListener();

        window.addEventListener("resize", resizeListener);

        return () => window.removeEventListener("resize", resizeListener);
    }, [gridColumns, gridBreakpoints]);

    return (
        <div className={classes.container}>
            {Array.from({ length: columns }).map((_column, index) => {
                return (
                    <div key={index} className={classes.column}>
                        {buckets[index].map((element, index) => {
                            return <Fragment key={index}>{element}</Fragment>;
                        })}
                    </div>
                );
            })}
        </div>
    );
};
