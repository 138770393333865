import classNames from "classnames";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Editor, Range } from "slate";
import { ReactEditor, useFocused, useSlate, useSlateSelection } from "slate-react";
import { useDebounce } from "../../../../utils/hooks";
import { Progress } from "../../../form/Progress";
import { HiddenFileSelectTrigger } from "../../../misc/HiddenFileSelectTrigger";
import Popup, { PopupContent, PopupTitle } from "../../../notify/Popup";
import { EditorContext, msgKey } from "../RichTextEditor";
import { insertImage } from "../image/commands";
import styles from "./Toolbar.scss";
import { AddElement } from "./parts/AddElement";
import { Colors } from "./parts/Colors";
import { Link } from "./parts/Link";
import { TextAlignment } from "./parts/TextAlignment";
import { TextFormatting } from "./parts/TextFormatting";
import { UndoRedo } from "./parts/UndoRedo";

export const Toolbar = () => {
    const { showToolbar, setShowToolbar, setShowPrompt, onImageUpload } = useContext(EditorContext);
    const selection = useSlateSelection();
    const baseRef = useRef();
    const uploadRef = useRef();
    const editor = useSlate();
    const focused = useFocused();
    const [expanded, setExpanded] = useState(null);
    const [progress, setProgress] = useState(null);

    const handleUploadImage = async (files) => {
        setProgress(1);
        onImageUpload(files[0], setProgress, ({ imageId, imageUrl }) => {
            setProgress(null);
            insertImage(editor, { imageId, imageUrl });
        });
    };

    useDebounce(
        () => {
            const hasDisabledBlocks = [...Editor.nodes(editor, { match: (n) => ["image"].includes(n.type) })]?.length;
            const element = baseRef.current;
            if (!element || hasDisabledBlocks || ((!selection || Range.isCollapsed(selection)) && !focused)) {
                return setShowToolbar(false);
            }

            const editorRect = ReactEditor.toDOMNode(editor, editor).getBoundingClientRect();
            const domSelection = window.getSelection();
            const domRange = domSelection.getRangeAt(0);
            const rect = domRange.getBoundingClientRect();

            // Calculate the position relative to the parent
            const relativeTop = rect.y - editorRect.y + rect.height;

            const minLeft = 0;
            const maxLeft = editorRect.width - element.offsetWidth;
            const relativeLeft = rect.x - editorRect.x - element.offsetWidth / 2 + rect.width / 2;

            element.style.top = `${relativeTop}px`;
            element.style.left = `${Math.min(Math.max(relativeLeft, minLeft), maxLeft)}px`;

            setShowToolbar(true);
        },
        [selection, editor, focused, setShowToolbar],
        100
    );

    useEffect(() => {
        if (!showToolbar) {
            setExpanded(null);
        }
    }, [showToolbar]);

    return progress ? (
        <Popup>
            <PopupTitle>
                <FormattedMessage id={`${msgKey}.image`} />
            </PopupTitle>
            <PopupContent>
                <Progress percent={progress}>
                    <FormattedMessage id={`${msgKey}.uploading`} />
                </Progress>
            </PopupContent>
        </Popup>
    ) : (
        <div
            ref={baseRef}
            className={classNames(styles.base, {
                [styles.visible]: showToolbar
            })}
            onMouseDown={(e) => e.preventDefault()}
        >
            <div className={styles.wrapper}>
                <AddElement
                    expanded={expanded}
                    setExpanded={setExpanded}
                    handleAddImage={onImageUpload ? () => uploadRef.current.click() : null}
                />
                <TextFormatting expanded={expanded} setExpanded={setExpanded} />
                <Link expanded={expanded} setExpanded={setExpanded} handleShowPrompt={setShowPrompt} />
                <TextAlignment expanded={expanded} setExpanded={setExpanded} />
                <Colors expanded={expanded} setExpanded={setExpanded} />
                <UndoRedo expanded={expanded} setExpanded={setExpanded} />
            </div>
            <HiddenFileSelectTrigger ref={uploadRef} accept="images" onSelect={handleUploadImage} />
        </div>
    );
};
