import { Text } from "slate";
import { ElementType, tagElementTypeMap } from "../elements";
import { jsx } from "slate-hyperscript";
import { getElementStyle } from "../elements";
import { rgb2hex } from "./colors";
import { escapeHtml } from "./escapeHtml";

/**
 * Slate nodes to HTML
 * @param {*} node
 * @returns
 */
export const slateNodesToHtml = (nodes) => {
    const serializeNode = (node) => {
        if (!node) {
            return;
        } else if (Text.isText(node)) {
            let string = escapeHtml(node.text).replace(/\n/g, "<br/>");
            if (node.bold) {
                string = `<strong>${string}</strong>`;
            }
            if (node.italic) {
                string = `<i>${string}</i>`;
            }
            if (node.underline) {
                string = `<u>${string}</u>`;
            }
            if (node.strikeThrough) {
                string = `<s>${string}</s>`;
            }
            if (node.color) {
                string = `<span style="color:${node.color}">${string}</span>`;
            }
            if (node.backgroundColor) {
                string = `<span style="background-color:${node.backgroundColor}">${string}</span>`;
            }
            return string;
        }

        const children = node.children?.map((n) => serializeNode(n)).join("");
        const element = ElementType[node.type];

        const elementStyle = getElementStyle(node);
        const stringifiedStyle =
            Object.keys(elementStyle).length > 0
                ? ` style="${Object.entries(elementStyle)
                      .map(([k, v]) => `${k.replace(/[A-Z]/g, (m) => "-" + m.toLowerCase())}:${v}`)
                      .join(";")};"`
                : "";
        if (element) {
            return element.toHtml({ node, children, style: stringifiedStyle });
        } else {
            return children;
        }
    };
    return (Array.isArray(nodes) ? nodes : [nodes]).map((n) => serializeNode(n)).join("");
};

/**
 * HTML to Slate nodes
 * @param {*} html
 * @param {*} markAttributes
 * @returns
 */
export const htmlToSlateNodes = (html) => {
    const deserializeNode = (el, markAttributes = {}) => {
        if (el.nodeType === Node.TEXT_NODE) {
            return jsx("text", markAttributes, el.textContent);
        } else if (el.nodeType !== Node.ELEMENT_NODE) {
            return null;
        }

        const nodeAttributes = { ...markAttributes };

        // TABLE FIX
        // Check if node is table and check if <tbody> wraps all rows. Fix if not.
        if (el.nodeName === "TABLE") {
            const newTbody = document.createElement("tbody");
            Array.from(el.childNodes).forEach((node) => {
                if (["TBODY", "THEAD"].includes(node.nodeName)) {
                    Array.from(node.childNodes).forEach((row) => {
                        newTbody.appendChild(row);
                    });
                } else {
                    newTbody.appendChild(node);
                }
            });
            el.innerHTML = newTbody.innerHTML;
        }

        // define attributes for text nodes
        switch (el.nodeName) {
            case "STRONG":
                nodeAttributes.bold = true;
                break;
            case "U":
                nodeAttributes.underline = true;
                break;
            case "I":
                nodeAttributes.italic = true;
                break;
            case "S":
                nodeAttributes.strikeThrough = true;
                break;
            default:
        }

        if (el.nodeName === "SPAN") {
            const colorValue = el.style.color;
            if (colorValue) {
                nodeAttributes.color = rgb2hex(colorValue);
            }

            const backgroundColorValue = el.style.backgroundColor;
            if (backgroundColorValue) {
                nodeAttributes.backgroundColor = rgb2hex(backgroundColorValue);
            }
        }

        const children = Array.from(el.childNodes)
            .map((node) => deserializeNode(node, nodeAttributes))
            .flat();

        if (children.length === 0) {
            children.push(jsx("text", nodeAttributes, ""));
        }

        switch (el.nodeName) {
            case "BODY":
                return jsx("fragment", {}, children);
            default:
                const elementType = tagElementTypeMap[el.nodeName];
                if (elementType) {
                    return elementType.toNode({ el, children, properties: getPropertiesFromElement(el) });
                } else {
                    return children;
                }
        }
    };

    const document = new DOMParser().parseFromString(html?.length ? html : "<p></p>", "text/html");
    const el = document.body;

    return deserializeNode(el);
};

const getPropertiesFromElement = (el) => {
    const properties = {};
    if (el.style?.textAlign) {
        properties.align = el.style.textAlign;
    }
    return properties;
};
