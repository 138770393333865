import React, { useState } from "react";
import style from "./style.scss";

import { TaskCard } from "./task/TaskCard";
import { TaskAdder } from "./task/TaskAdder";
import { NewTaskPopup } from "./task/NewTaskPopup";
import { EditTaskPopup } from "./task/EditTaskPopup";
import { HazardCard } from "./hazard/HazardCard";
import { HazardAdder } from "./hazard/HazardAdder";
import { NewHazardPopup } from "./hazard/NewHazardPopup";
import { EditHazardPopup } from "./hazard/EditHazardPopup";
import { calculateRiskPercentage, sortHazardList, sortTaskList } from "./helpers";

export const RiskTable = ({
    tasks,
    context,
    contextList,
    preview,
    onCreateTask,
    onSaveTask,
    onDeleteTask,
    onCreateHazard,
    onSaveHazard,
    onDeleteHazard
}) => {
    const [selectedTask, setSelectedTask] = useState(null);
    const [newHazardOnTask, setNewHazardOnTask] = useState(null);
    const [selectedHazard, setSelectedHazard] = useState(null);
    const [promptNewTask, setPromptNewTask] = useState(false);

    return (
        <div className={style.base}>
            {/* Table */}
            <div className={style.frame}>
                <table className={style.table}>
                    <tbody>
                        <tr>
                            {/* Task list */}
                            {calculateRiskPercentage(tasks)
                                ?.sort(sortTaskList)
                                .map((entry, index) => (
                                    <td key={index}>
                                        <div className={style.wrapper}>
                                            <TaskCard
                                                number={index + 1}
                                                task={entry.task}
                                                rank={entry.rank}
                                                onClick={setSelectedTask}
                                                preview={preview}
                                            />

                                            {/* Hazards */}
                                            {entry.hazards?.sort(sortHazardList).map((hazard, index) => (
                                                <HazardCard
                                                    key={index}
                                                    hazard={hazard}
                                                    onClick={setSelectedHazard}
                                                    preview={preview}
                                                />
                                            ))}

                                            {!preview ? (
                                                <HazardAdder onClick={() => setNewHazardOnTask(entry.task)} />
                                            ) : null}
                                        </div>
                                    </td>
                                ))}

                            {/* New task */}
                            {!preview ? (
                                <td>
                                    <div className={style.wrapper}>
                                        <TaskAdder onClick={() => setPromptNewTask(true)} />
                                    </div>
                                </td>
                            ) : null}
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* New task popup */}
            {promptNewTask ? (
                <NewTaskPopup context={context} onClose={() => setPromptNewTask(false)} onCreate={onCreateTask} />
            ) : null}

            {/* Edit task popup */}
            {selectedTask ? (
                <EditTaskPopup
                    context={context}
                    contextList={contextList}
                    task={selectedTask}
                    onClose={() => setSelectedTask(null)}
                    onSave={onSaveTask}
                    onDelete={onDeleteTask}
                />
            ) : null}

            {/* New hazard popup */}
            {newHazardOnTask ? (
                <NewHazardPopup
                    task={newHazardOnTask}
                    onClose={() => setNewHazardOnTask(null)}
                    onCreate={onCreateHazard}
                />
            ) : null}

            {/* Edit hazard popup */}
            {selectedHazard ? (
                <EditHazardPopup
                    hazard={selectedHazard}
                    onSave={onSaveHazard}
                    onDelete={onDeleteHazard}
                    onClose={() => setSelectedHazard(null)}
                />
            ) : null}
        </div>
    );
};
