import React from "react";
import classNames from "classnames";
import style from "./style.scss";

import { Clickable } from "../Clickable";

export default class Link extends React.Component {
    render() {
        // Properties
        const { onClick, to, href, target, children, silent, block } = this.props;

        // Classes
        const classes = classNames(style.base, {
            [style.silent]: silent,
            [style.block]: block
        });

        return (
            <Clickable onClick={onClick} link={to} href={href} target={target} className={classes}>
                {children}
            </Clickable>
        );
    }
}
