import { Transforms } from "slate";
import { getBlockInsertPath } from "../table/utils";

export const insertImage = (editor, { imageId, imageUrl }) => {
    const insertPath = getBlockInsertPath(editor);
    Transforms.insertNodes(
        editor,
        {
            type: "image",
            src: imageUrl,
            refId: imageId,
            children: [{ text: "" }]
        },
        {
            at: insertPath,
            select: true
        }
    );
};

export const updateImage = (editor, values) => {
    Transforms.setNodes(editor, values);
};

export const deleteImage = (editor) => {
    Transforms.removeNodes(editor);
};
