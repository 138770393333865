import React from "react";

export const Cell = ({ element, attributes, children }) => {
    return React.createElement(
        element.isHeader ? "th" : "td",
        {
            ...attributes,
            "slate-table-element": element.isHeader ? "th" : "td",
            "data-key": element.key
        },
        children
    );
};
