import React from "react";
import classNames from "classnames";
import style from "./style.scss";

import { Icon } from "../../graphic/Icon";

export default class OnOffSwitch extends React.Component {
    _toggleSwitch = () => {
        // Check if disabled
        if (this.props.disabled) {
            return false;
        }

        // Callback
        if (this.props.onChange) {
            this.props.onChange(this.props.active);
        }
    };

    render() {
        const classes = classNames(style.base, {
            [style.isOff]: !this.props.active,
            [style.disabled]: this.props.disabled,
            [style.noLabel]: !this.props.children,
            [style.silent]: this.props.silent
        });

        return (
            <div className={classes} onClick={this._toggleSwitch} title={this.props.title}>
                <div className={style.wrapper}>
                    <div className={style.switch}>
                        <div className={style.handle}></div>
                        <Icon className={style.onIcon} name="check" size="small" />
                        <Icon className={style.offIcon} name="close" size="small" />
                    </div>

                    {/* Label */}
                    {this.props.children ? <div className={style.label}>{this.props.children}</div> : null}
                </div>
            </div>
        );
    }
}
