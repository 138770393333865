import { Editor, Range, Transforms } from "slate";
import { deleteSelectionText } from "./commands/deleteSelectionText";
import { isInSameTable } from "./utils";

// BASED ON: https://github.com/lqs469/slate-table/

export const withTable = (editor) => {
    const { deleteBackward, deleteFragment } = editor;

    editor.deleteFragment = (...args) => {
        // Check if in the same table
        if (editor.selection && isInSameTable(editor)) {
            deleteSelectionText(editor, deleteFragment);
            return;
        }

        Transforms.removeNodes(editor, {
            match: (n) => n.type === "table"
        });

        deleteFragment(...args);
    };

    editor.deleteBackward = (...args) => {
        const { selection } = editor;

        // Prevent deleting table cells when at the start of the cell
        if (selection && Range.isCollapsed(selection)) {
            const isInTable = Editor.above(editor, {
                match: (n) => n.type === "table"
            });

            if (isInTable) {
                const [startPoint] = Range.edges(selection);
                const isStart = Editor.isStart(editor, startPoint, selection);
                const currCell = Editor.above(editor, {
                    match: (n) => n.type === "table-cell"
                });

                if (currCell && isStart && startPoint.offset === 0) {
                    return;
                }
            }
        }

        // Check if going backwards into table
        if (selection && Range.isCollapsed(selection) && selection.focus.offset === 0) {
            const [, parentPath] = Editor.parent(editor, selection.focus.path);
            const pointBefore = Editor.before(editor, parentPath);

            // Set selection inside table cell
            if (pointBefore) {
                const isTable = Editor.above(editor, {
                    match: (n) => n.type === "table",
                    at: pointBefore.path
                });
                if (isTable) {
                    if (Editor.string(editor, selection.anchor.path) === "") {
                        Transforms.removeNodes(editor, {
                            at: editor.selection
                        });
                    }
                    Transforms.select(editor, pointBefore);
                    return;
                }
            }
        }

        deleteBackward(...args);
    };

    return editor;
};
