import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { AuthContext } from "../../../contexts/Auth";
import { VesselContext } from "../../../routes/vessel/VesselRoot";
import { CustomSelect, OptionItem } from "../../form/CustomSelect";
import Image from "../../media/Image";
import { Text } from "../../typography/Text";

const msgKey = "vesselSelect";

export const VesselSelect = ({ orgId, value, onChange, isCrewMember, menuPlacement }) => {
    const { me } = useContext(AuthContext);
    const { vessel } = useContext(VesselContext);
    const [vesselList, setVesselList] = useState(null);

    useEffect(() => {
        if (isCrewMember) {
            setVesselList(me?.vesselList.filter(({ crewRole }) => !!crewRole));
        } else {
            setVesselList(
                orgId ? me?.vesselList.filter(({ vessel }) => vessel.organization?.id === orgId) : me?.vesselList
            );
        }
    }, [orgId, isCrewMember, me?.vesselList]);

    return (
        <CustomSelect
            busy={!vesselList}
            value={value}
            onChange={onChange}
            isClearable
            placeholder={<FormattedMessage id={`${msgKey}.selectVessel`} />}
            menuPlacement={menuPlacement}
        >
            {vesselList?.map(({ vessel: entry }) => (
                <OptionItem
                    key={entry.id}
                    symbol={<Image size="fit" src={entry.picture?.url} icon="vessel" />}
                    value={entry.id}
                    label={
                        <>
                            {entry.name}
                            {vessel?.id === entry.id ? (
                                <Text color="base60">
                                    &nbsp;(
                                    <FormattedMessage id={`${msgKey}.this`} />)
                                </Text>
                            ) : null}
                        </>
                    }
                />
            ))}
        </CustomSelect>
    );
};
