import classNames from "classnames";
import React from "react";
import SortableList from "react-easy-sort";
import { FormattedMessage } from "react-intl";
import { Spinner } from "../../loaders/Spinner";
import { Text } from "../../typography/Text";
import { Item } from "./Item";
import styles from "./ItemList.scss";

const msgKey = "itemList";

export const ItemList = ({
    onItemClick,
    onSort,
    busy,
    heading,
    headingAction,
    hideIfEmpty,
    children,
    emptyMessage,
    embedded,
    bordered
}) => {
    const handleSortEnd = (oldIndex, newIndex) => {
        if (typeof onSort === "function") {
            onSort({ oldIndex, newIndex });
        }
    };

    const items = React.Children.map(children, (item, idx) => {
        if (item !== null) {
            return React.cloneElement(item, {
                index: idx,
                onClick: onItemClick,
                sortable: !!onSort
            });
        }
    });

    return (
        <div
            className={classNames(styles.base, {
                [styles.hidden]: hideIfEmpty && !busy && !items?.length,
                [styles.embedded]: embedded,
                [styles.bordered]: bordered
            })}
        >
            {/* Heading */}
            {heading ? (
                <div className={styles.heading}>
                    <div className={styles.label}>{heading}</div>

                    {headingAction ? (
                        <div className={styles.action}>
                            <div className={styles.wrapper}>{headingAction}</div>
                        </div>
                    ) : null}
                </div>
            ) : null}

            {/* Items */}
            {!!onSort ? (
                <SortableList onSortEnd={handleSortEnd} draggedItemClassName={styles.dragging}>
                    <ListContent items={items} busy={busy} emptyMessage={emptyMessage} />
                </SortableList>
            ) : (
                <ListContent items={items} busy={busy} emptyMessage={emptyMessage} />
            )}
        </div>
    );
};

const ListContent = ({ items, busy, emptyMessage }) => {
    return (
        <div className={styles.list}>
            {busy ? (
                <div
                    className={classNames(styles.loader, {
                        [styles.empty]: !items?.length
                    })}
                >
                    <Spinner primary />
                </div>
            ) : !items?.length ? (
                <Item>
                    <Text color="base40">{emptyMessage || <FormattedMessage id={`${msgKey}.emptyList`} />}</Text>
                </Item>
            ) : null}
            {items}
        </div>
    );
};
