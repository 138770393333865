import React from "react";

import { FormattedMessage } from "react-intl";
import { Button } from "../../button/Button";
import { ShieldLogo } from "../../graphic/ShieldLogo/ShieldLogo";
import Col from "../../grid/Col";
import Row from "../../grid/Row";
import { PageMessage } from "../../page/PageMessage";
import { Text } from "../../typography/Text";

const msgKey = "trialActiveMessage";

export default class TrialActiveMessage extends React.Component {
    render() {
        // Properties
        const { vessel, days } = this.props;

        return (
            <PageMessage
                title={<FormattedMessage id={`${msgKey}.trialActiveTitle`} />}
                figure={<ShieldLogo size={100} />}
            >
                <Row gutter={["large", "large"]}>
                    <Col span={24}>
                        <Text size="xlarge" weight="semiBold" color="success">
                            <FormattedMessage id={`${msgKey}.trialDaysLeft`} values={{ days: days }} />
                        </Text>
                    </Col>
                    <Col span={24}>
                        <Text block size="medium" color="base80">
                            <FormattedMessage
                                id={`${msgKey}.trialActiveMessage`}
                                values={{
                                    vesselName: vessel.name,
                                    days: days,
                                    strong: (msg) => <strong>{msg}</strong>
                                }}
                            />
                        </Text>
                    </Col>
                    <Col span={24}>
                        <Button
                            link={`/vessels/${vessel.id}/subscription/choose`}
                            color="success"
                            icon="account"
                            size="big"
                        >
                            <FormattedMessage id={`${msgKey}.chooseSubscription`} />
                        </Button>
                    </Col>
                </Row>
            </PageMessage>
        );
    }
}
