import classNames from "classnames";
import React from "react";
import style from "./style.scss";

import Dropzone from "react-dropzone";
import { mimeTypesMap } from "../../../utils/fileTypeUtils";
import { LoaderHorizontal } from "../../loaders/LoaderHorizontal";
import { Button } from "../Button";

export default class FileSelectButton extends React.Component {
    /**
     * Open file selector
     * @private
     */
    _openFileSelector = () => {
        this.dropzone.open();
    };

    /**
     * Callback when files are selected
     * @private
     */
    _handleFilesSelected = (acceptedFiles) => {
        // Callback
        if (this.props.onSelect) {
            this.props.onSelect(acceptedFiles);
        }
    };

    /**
     * RENDER
     * @returns {XML}
     */
    render() {
        // Properties
        const { name, children, accept, className, busy, disabled, multiple } = this.props;

        // Accept file types
        let acceptTypes = null;
        if (accept) {
            acceptTypes = "";
            const acceptArray = accept.split(",");
            acceptArray.map((typeName) => {
                const type = mimeTypesMap[typeName];
                if (type) {
                    acceptTypes += type + ",";
                }
                return typeName;
            });
            acceptTypes = acceptTypes.slice(0, -1);
        }

        // Classes
        const classes = classNames(style.base, className);

        return (
            <Button
                name={name}
                onClick={this._openFileSelector}
                className={classes}
                disabled={busy || disabled}
                {...this.props}
            >
                {busy ? <LoaderHorizontal /> : children}

                <Dropzone
                    className={style.dropzone}
                    ref={(node) => {
                        this.dropzone = node;
                    }}
                    onDrop={this._handleFilesSelected}
                    multiple={multiple}
                    accept={acceptTypes}
                />
            </Button>
        );
    }
}
