import classNames from "classnames";
import React, { useState } from "react";
import { Icon } from "../../../graphic/Icon";
import Image from "../../../media/Image";
import { Typewriter } from "../../../typography/Typewriter";
import styles from "./CourseCard.scss";

export const CourseCard = ({ editable, card, cardResult, onAnswer, showing, onClickText, onClickOption }) => {
    const { text, options, file } = card || {};
    const [backgroundVariant] = useState(Math.floor(Math.random() * 4) + 1);
    const [hideContent, setHideContent] = useState(false);

    return (
        <div
            className={classNames(styles.base, {
                [styles.editable]: editable
            })}
        >
            {showing && (options?.length || text) && (
                <div
                    className={classNames(styles.content, {
                        [styles.hidden]: hideContent,
                        [styles.fill]: !file
                    })}
                >
                    <div className={styles.wrapper}>
                        {!editable && (
                            <button className={styles.handle} onClick={() => setHideContent(!hideContent)}>
                                <Icon name={hideContent ? "caret-up" : "caret-down"} />
                            </button>
                        )}
                        <div className={styles.text} onClick={editable ? onClickText : null}>
                            {!!cardResult || editable ? text : <Typewriter text={text} />}
                        </div>

                        {/* Options */}
                        {options?.length > 0 ? (
                            <div
                                className={classNames(styles.options, {
                                    [styles.answered]: !!cardResult,
                                    [styles.editable]: editable
                                })}
                            >
                                {(!editable ? cardResult?.options || options : options)
                                    .sort((a, b) => editable && a.id - b.id)
                                    .map((option) => {
                                        return (
                                            <OptionButton
                                                key={option.id}
                                                option={option}
                                                selected={option.selected}
                                                truthy={option.correct}
                                                answered={!!cardResult}
                                                editable={editable}
                                                onSelect={() => (editable ? onClickOption(option) : onAnswer(option))}
                                            />
                                        );
                                    })}
                            </div>
                        ) : null}
                    </div>
                </div>
            )}
            <div
                className={classNames(styles.background, {
                    [styles[`variant${backgroundVariant}`]]: !file
                })}
            >
                {!!file && <Image src={file?.url} size="fit" squared />}
            </div>
        </div>
    );
};

const OptionButton = ({ option, selected, truthy, onSelect, answered, editable }) => {
    return (
        <button
            className={classNames(styles.option, {
                [styles.correct]: truthy,
                [styles.wrong]: !truthy,
                [styles.selected]: selected
            })}
            onClick={onSelect}
            disabled={!editable && answered}
        >
            <span className={styles.symbol}>{selected ? <Icon name="check" /> : <span className={styles.dot} />}</span>
            <span className={styles.label}>{option.text}</span>
        </button>
    );
};
