import React, { useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { AppContext } from "../../../../App";
import coverImage from "../../../../assets/images/misc/course-cover.webp";
import { ShieldLogo } from "../../../graphic/ShieldLogo";
import { TextLogo } from "../../../graphic/TextLogo";
import { Background } from "../../layout/Background";
import styles from "./CourseIntroCard.scss";

const msgKey = "courseIntroCard";

export const CourseIntroCard = ({ course, numberOfQuestions }) => {
    return (
        <div className={styles.base}>
            <div className={styles.text}>
                <div className={styles.title}>
                    <AdaptableText text={course.title} />
                </div>
                <div className={styles.description}>
                    <span>{course.description}</span>
                </div>
                {numberOfQuestions > 0 && (
                    <div className={styles.questionCount}>
                        <FormattedMessage id={`${msgKey}.questionCount`} values={{ count: numberOfQuestions }} />
                    </div>
                )}
            </div>
            <div className={styles.branding}>
                <div className={styles.shield}>
                    <ShieldLogo />
                </div>
                <div className={styles.textLogo}>
                    <TextLogo color="contrast" size="small" />
                </div>
            </div>
            <Background imageUrl={course.cover?.url || coverImage} />
        </div>
    );
};

const AdaptableText = ({ text }) => {
    const { pageInfo } = useContext(AppContext);
    const [words, setWords] = useState([]);
    const wordsRef = useRef(null);
    const [style, setStyle] = useState(null);
    const [fontsReady, setFontsReady] = useState(false);
    const [redrawKey, setRedrawKey] = useState(null);

    // Wait for fonts
    useEffect(() => {
        document.fonts.ready?.then(() => {
            setFontsReady(true);
        });
    }, []);

    // Split text into words
    useEffect(() => {
        const words = text.split(" ");
        // Iterate words and split those over 20 characters
        for (let i = 0; i < words.length; i++) {
            const word = words[i];
            if (word.length > 20) {
                words.splice(i, 1, `${word.substring(0, 18)}-`, word.substring(18));
            }
        }
        setWords(words);
    }, [text]);

    // Calculate font size
    useEffect(() => {
        if (!wordsRef?.current || !text?.length || !fontsReady) {
            return;
        }
        const containerWidth = wordsRef.current?.getBoundingClientRect().width;

        // Iterate children of wordsRef and calculate their width
        let wordWidthMax = 0;
        for (let i = 0; i < wordsRef.current?.children?.length; i++) {
            const word = wordsRef.current?.children[i];
            const wordWidth = word.getBoundingClientRect().width;
            if (wordWidth > containerWidth && wordWidth > wordWidthMax) {
                wordWidthMax = wordWidth;
            }
        }

        if (wordWidthMax > 0) {
            const factor = 1;
            setStyle({
                fontSize: `${Math.max(
                    Math.abs(100 * (1 - ((wordWidthMax - containerWidth) / containerWidth) * factor)),
                    50
                )}%`
            });
        }
    }, [text, wordsRef?.current?.children?.length, fontsReady, redrawKey]);

    const handleResize = () => {
        setStyle(null);
        setTimeout(() => {
            setRedrawKey(Date.now());
        }, 200);
    };
    useEffect(handleResize, [pageInfo]);

    return (
        <div ref={wordsRef} style={style}>
            {words?.map((word, index) => (
                <span key={index}>{word}</span>
            ))}
        </div>
    );
};
