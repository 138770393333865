import React from "react";
import style from "./style.scss";

import { FormattedMessage } from "react-intl";
import { Figure } from "../../graphic/Figure";
import Col from "../../grid/Col";
import Row from "../../grid/Row";
import { Text } from "../../typography/Text";
import { getEnginePowerUnit } from "../../../routes/vessel/engine/common/helpers";
import { getEngineFigure } from "../../../utils/engineUtils";

const msgKey = "engineCard";

export const EngineCard = ({ hours, engine }) => {
    return (
        <div className={style.base}>
            <Row>
                <Col sm={11}>
                    <div className={style.figure}>
                        <Figure name={getEngineFigure(engine)} color="primary" />
                    </div>
                    <div className={style.label}>
                        <FormattedMessage id={`${msgKey}.engineHours`} />:
                    </div>
                    <div className={style.number}>{hours || 0}</div>
                </Col>
                <Col sm={13}>
                    <div style={{ height: "230px", overflow: "hidden", display: "flex", alignItems: "center" }}>
                        <Row>
                            <Col span={24}>
                                <Text block color="base60">
                                    <FormattedMessage id={`${msgKey}.engineType`} />:
                                </Text>
                                <Text block weight="semiBold">
                                    <FormattedMessage id={`engineTypes.${engine.type}`} />
                                </Text>
                            </Col>
                            {(engine.model || engine.brand) && (
                                <Col span={24}>
                                    <Text block color="base60">
                                        <FormattedMessage id={`${msgKey}.engine`} />:
                                    </Text>
                                    {engine.brand ? (
                                        <Text weight="semiBold" size="medium">
                                            {engine.brand.name}&nbsp;
                                        </Text>
                                    ) : null}
                                    <Text weight="semiBold" size={!engine.brand ? "medium" : null}>
                                        {engine.model}
                                    </Text>
                                </Col>
                            )}

                            {engine.buildYear && (
                                <Col span={24}>
                                    <Text block color="base60">
                                        <FormattedMessage id={`${msgKey}.buildYear`} />:
                                    </Text>
                                    <Text block weight="semiBold">
                                        {engine.buildYear}
                                    </Text>
                                </Col>
                            )}

                            {engine.power && (
                                <Col span={12}>
                                    <Text block color="base60">
                                        <FormattedMessage id={`${msgKey}.enginePower`} />:
                                    </Text>
                                    <Text block weight="semiBold">
                                        {engine.power}{" "}
                                        <FormattedMessage id={`enginePowerUnit.${getEnginePowerUnit(engine)}`} />
                                    </Text>
                                </Col>
                            )}

                            {engine.fuel && (
                                <Col span={12}>
                                    <Text block color="base60">
                                        <FormattedMessage id={`${msgKey}.engineFuel`} />:
                                    </Text>
                                    <Text block weight="semiBold">
                                        <FormattedMessage id={`engineFuel.${engine.fuel}`} />
                                    </Text>
                                </Col>
                            )}
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
