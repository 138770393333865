import React from "react";
import classNames from "classnames";
import style from "./style.scss";

import { Icon } from "../../graphic/Icon";

export default class Badge extends React.Component {
    render() {
        const {
            color,
            customColor,
            fill,
            number,
            size,
            leftSpacing,
            rightSpacing,
            className,
            icon,
            iconColor,
            dot,
            stripped,
            spacing,
            onClick,
            placement,
            children
        } = this.props;

        const classes = classNames(
            style.base,
            {
                [style.primary]: color === "primary",
                [style.silent]: color === "silent",
                [style.info]: color === "info",
                [style.success]: color === "success",
                [style.warning]: color === "warning",
                [style.error]: color === "error",
                [style.contrast]: color === "contrast",
                [style.rankLow]: color === "rank-low",
                [style.rankMid]: color === "rank-mid",
                [style.rankHigh]: color === "rank-high",
                [style.attention]: color === "attention",

                [style.iconError]: iconColor === "error",
                [style.iconInfo]: iconColor === "info",

                [style.number]: number,

                [style.small]: size === "small",
                [style.large]: size === "large",

                [style.leftSpacing]: leftSpacing,
                [style.rightSpacing]: rightSpacing,
                [style.listSpacing]: spacing === "list",

                [style.fill]: fill,
                [style.stripped]: stripped,
                [style.clickable]: !!onClick,

                [style.placeTopRight]: placement === "top-right"
            },
            className
        );

        return (
            <span
                className={classes}
                onClick={onClick}
                style={
                    customColor && fill
                        ? { backgroundColor: customColor, borderColor: customColor }
                        : customColor && !fill
                        ? {
                              borderColor: customColor,
                              color: customColor
                          }
                        : null
                }
            >
                {dot ? <span className={style.dot} style={{ backgroundColor: dot }} /> : null}

                {icon ? <Icon name={icon} className={style.icon} /> : null}

                {children !== undefined ? <span className={style.label}>{children}</span> : null}
            </span>
        );
    }
}
