import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/Auth";

export const Restricted = ({ admin, user, children }) => {
    const { me } = useContext(AuthContext);
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        if (admin) {
            setVisible(me?.user.roles.indexOf("admin") >= 0);
        } else if (user) {
            setVisible(me?.user.roles.indexOf("user") >= 0);
        }
    }, [me, admin, user]);

    return visible ? children : null;
};
