import classNames from "classnames";
import React from "react";
import style from "./style.scss";

export const Menu = ({ primary, children, hidden, size, className }) => {
    return !hidden ? (
        <nav
            className={classNames(
                style.base,
                {
                    [style.primary]: primary,
                    [style.medium]: size === "medium"
                },
                className
            )}
        >
            {React.Children.map(children, (child) => {
                if (child?.props.hidden) {
                    return null;
                } else {
                    return child;
                }
            })}
        </nav>
    ) : null;
};
