import React from "react";
import style from "./hazard-adder.scss";

import { FormattedMessage } from "react-intl";
import { Adder } from "../../../button/Adder";

export const HazardAdder = ({ onClick }) => {
    return (
        <div className={style.base}>
            <Adder onClick={onClick} label={<FormattedMessage id="riskTable.addHazard" />} />
        </div>
    );
};
