import React from "react"
import classNames from "classnames"
import style from "./style.scss"


export default class PopupTitle extends React.Component {
    render(){

        // Properties
        const { subtitle, size, align } = this.props;

        const classes = classNames(style.title, {
            [style.sizeMedium]: size === "medium",
            [style.alignCenter]: align === "center"
        });

        return (
            <div className={classes}>
                {this.props.children}

                {subtitle ? (
                    <div className={style.subtitle}>
                        {subtitle}
                    </div>
                ) : null}
            </div>
        )
    }
}
