import React from "react"
import style from "./style.scss"


export default class FormDivider extends React.Component {
    render(){
        return (
            <div className={style.base}></div>
        )
    }
}
