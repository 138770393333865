import moment from "moment";

export const filterHourEntry = (current, hour, entry) => {
    current.setHours(hour);
    current.setMinutes(0);
    current.setSeconds(0);

    const startTime = new Date(entry.startTime);
    startTime.setMinutes(0);
    startTime.setSeconds(0);

    const endTime = new Date(entry.endTime);
    endTime.setSeconds(0);

    return startTime.getTime() <= current.getTime() && current.getTime() < endTime.getTime();
};

export const sumEntryHours = ({ date, entries, type }) => {
    if (!entries || !entries.length) {
        return 0;
    }
    const startOfDay = moment(date).startOf("day").toDate();
    const endOfDay = moment(date).endOf("day").toDate();

    return entries.reduce((sum, entry) => {
        const startTime = new Date(entry.startTime);
        const endTime = new Date(entry.endTime);
        if (entry.type === type) {
            const hours =
                Math.round(((Math.min(endTime, endOfDay) - Math.max(startTime, startOfDay)) / 1000 / 60 / 60) * 10) /
                10;
            sum += hours;
        }
        return sum;
    }, 0);
};
