import React from "react";

import { Page } from "../Page";
import { Spinner } from "../../loaders/Spinner";

export default class PageLoader extends React.Component {
    render() {
        return (
            <Page>
                <Spinner large primary />
            </Page>
        );
    }
}
