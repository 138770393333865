import { Editor, Range, Transforms } from "slate";

export const enterKeyHandler = (editor, event) => {
    if (event.key !== "Enter") return;

    // Check if not collapsed
    const isCollapsed = Range.isCollapsed(editor.selection);
    if (!isCollapsed) {
        return;
    }

    // Get current node
    const currentNode = Editor.above(editor);

    // Insert new paragraph
    if (
        !event.shiftKey &&
        ["headingOne", "headingTwo", "headingThree", "headingFour", "blockQuote"].includes(currentNode[0].type)
    ) {
        event.preventDefault();
        Transforms.insertNodes(editor, {
            type: "paragraph",
            children: [{ text: "" }]
        });
        return;
    }

    // Check if empty list item
    if (
        currentNode[0].type === "listItem" &&
        currentNode[0].children[0].text === "" &&
        currentNode[0].children.length === 1
    ) {
        event.preventDefault();
        // Find parent list
        const parentList = Editor.parent(editor, currentNode[1]);

        // Remove empty item
        Transforms.unwrapNodes(editor, { at: currentNode[1] });

        // Insert new paragraph below
        const newParagraphPath = [parentList[1][0] + 1];
        Transforms.insertNodes(
            editor,
            {
                type: "paragraph",
                children: [{ text: "" }]
            },
            {
                at: newParagraphPath,
                select: true
            }
        );
        return;
    }

    // Soft Enter when editing text
    if (event.shiftKey) {
        event.preventDefault();
        editor.insertText("\n");
    }
};
