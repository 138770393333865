import React from "react";
import styles from "./Background.scss";
import Image from "../../../media/Image";

export const Background = ({ imageUrl }) => {
    return (
        <div className={styles.base}>
            <Image src={imageUrl} size="fit" squared />
        </div>
    );
};
