import { Editor, Path, Transforms } from "slate";
import { deleteSelectionText } from "./commands/deleteSelectionText";

class TableElement {
    static init(editor) {
        const table = Editor.above(editor, {
            match: (n) => n.type === "table"
        });
        if (!table) return null;
        return new TableElement(editor, table);
    }

    constructor(editor, table) {
        this.editor = editor;
        this.tableNode = table[0];
        this.tablePath = table[1];

        this.cells = [];
        const cellNodes = Editor.nodes(this.editor, {
            at: this.tablePath,
            match: (n) => n.type === "table-cell"
        });
        for (const node of cellNodes) {
            const [cell, path] = node;
            this.cells.push({
                cell,
                path
            });
        }
    }

    getNextCell() {
        const { selection } = this.editor;
        if (!selection) return;

        const nextPoint = Editor.after(this.editor, selection?.anchor?.path);
        if (nextPoint) {
            const nextCell = Editor.above(this.editor, {
                match: (n) => n.type === "table-cell",
                at: nextPoint
            });
            if (nextCell) {
                return nextPoint;
            }
        }
        return null;
    }

    getPreviousCell() {
        const { selection } = this.editor;
        if (!selection) return;

        const previousPoint = Editor.before(this.editor, selection?.anchor?.path);
        if (previousPoint) {
            const previousCell = Editor.above(this.editor, {
                match: (n) => n.type === "table-cell",
                at: previousPoint
            });
            if (previousCell) {
                return previousPoint;
            }
        }
        return null;
    }
}

/**
 * TABLE KEY HANDLER
 */
export const tableKeyHandler = (editor, event) => {
    // Check if inside a table
    const table = TableElement.init(editor);
    if (!table) return;

    // Tab key (go to next cell)
    if ((event.key === "Enter" || event.key === "Tab") && !event.shiftKey) {
        event.preventDefault();
        const nextCell = table.getNextCell();
        if (nextCell) {
            Transforms.select(editor, nextCell);
        } else {
            Transforms.insertNodes(
                editor,
                {
                    type: "paragraph",
                    children: [{ text: "" }]
                },
                {
                    at: Path.next(table.tablePath),
                    select: true
                }
            );
        }
        return;
    }

    // Shift + Tab key (go to previous cell)
    if (event.key === "Tab" && event.shiftKey) {
        const previousCell = table.getPreviousCell();
        if (previousCell) {
            event.preventDefault();
            Transforms.select(editor, previousCell);
            return;
        }
    }

    // Check if Backspace in an empty cell
    if (event.key === "Backspace") {
        const tableCell = Editor.above(editor, {
            match: (n) => n.type === "table-cell"
        });
        if (!tableCell) return;
        const cellText = Editor.string(editor, tableCell[1]);
        if (cellText === "") {
            event.preventDefault();
            const previousCell = table.getPreviousCell();
            if (previousCell) {
                Transforms.select(editor, previousCell);
            }
        }
        return;
    }

    // Delete selection text
    if (!["ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDown"].includes(event.key)) {
        deleteSelectionText(editor, editor.deleteFragment);
    }
};
