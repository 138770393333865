import React from "react";
import styles from "./BigButton.scss";
import { Clickable } from "../Clickable";

export const BigButton = ({ symbol, label, onClick, link }) => {
    return (
        <Clickable block onClick={onClick} link={link} className={styles.base}>
            <div className={styles.symbol}>{symbol}</div>
            <div className={styles.label}>{label}</div>
        </Clickable>
    );
};
