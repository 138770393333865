import React from "react";

import { FormattedMessage } from "react-intl";

import { LayoutFull } from "../../layout/LayoutFull";
import { CenterBox } from "../../layout/CenterBox";
import { CenterBoxTitle } from "../../layout/CenterBoxTitle";
import { CenterBoxContent } from "../../layout/CenterBoxContent";
import { CenterBoxBottom } from "../../layout/CenterBoxBottom";
import { Text } from "../../typography/Text";

export default class ErrorScreen extends React.Component {
    render() {
        return (
            <LayoutFull>
                <CenterBox>
                    <CenterBoxTitle>
                        <FormattedMessage id={this.props.title} />
                    </CenterBoxTitle>
                    <CenterBoxContent>
                        <Text block size="medium" color="base80" align="center">
                            <FormattedMessage id={this.props.message} />
                        </Text>
                    </CenterBoxContent>
                    <CenterBoxBottom>{this.props.children}</CenterBoxBottom>
                </CenterBox>
            </LayoutFull>
        );
    }
}
