import classNames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Col } from "../../grid/Col/Col";
import { Row } from "../../grid/Row/Row";
import { ContentWrapper } from "../../layout/ContentWrapper";
import styles from "./FrontVesselPictures.scss";

const msgKey = "frontVesselPictures";

function importAll(r) {
    let images = {};
    r.keys().forEach((item) => {
        images[item.replace("./", "")] = r(item);
    });
    return images;
}

const images = importAll(require.context("../../../assets/images/vessels", true));

export const FrontVesselPictures = ({ isMobile }) => {
    return (
        <ContentWrapper>
            <div className={styles.base}>
                <div className={styles.messageOver}>
                    <FormattedMessage
                        id={`${msgKey}.messageOver`}
                        values={{ strong: (msg) => <strong>{msg}</strong> }}
                    />
                </div>
                <div className={styles.imageList}>
                    <Row gutter={isMobile ? ["small", "small"] : ["regular", "regular"]}>
                        {[...Array(23).keys()].map((idx) => (
                            <Col key={idx} xs={6} md={3}>
                                <div className={styles.image}>
                                    <img
                                        loading="lazy"
                                        alt={`vessel-${idx < 9 ? "0" : ""}${idx + 1}.webp`}
                                        src={images[`v-${idx < 9 ? "0" : ""}${idx + 1}.webp`]}
                                        size="fill"
                                    />
                                </div>
                            </Col>
                        ))}
                        <Col xs={6} md={3}>
                            <div className={classNames(styles.image, styles.counter)}>+99</div>
                        </Col>
                    </Row>
                </div>
                <div className={styles.messageUnder}>
                    <FormattedMessage
                        id={`${msgKey}.messageUnder`}
                        values={{ strong: (msg) => <strong>{msg}</strong>, u: (msg) => <u>{msg}</u> }}
                    />
                </div>
            </div>
        </ContentWrapper>
    );
};
