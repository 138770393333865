import { DataTable } from "./DataTable";
import { Column } from "./Column";
import { Cell } from "./Cell";
import { Tr } from "./Tr";
import { Td } from "./Td";

DataTable.Column = Column;
DataTable.Cell = Cell;
DataTable.Tr = Tr;
DataTable.Td = Td;

export { DataTable, Column, Cell, Tr, Td };
