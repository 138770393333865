import React from "react"
import style from "./style.scss"


export default class PopupFooter extends React.Component {
    render(){
        const { children } = this.props;
        return (
            <div className={style.footer}>
                {children}
            </div>
        )
    }
}
