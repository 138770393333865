import { Editor } from "slate";

export const splittedTable = (editor, tablePath, startKey) => {
    const tableDepth = tablePath.length;

    const cells = [];

    const nodes = Editor.nodes(editor, {
        at: tablePath,
        match: (n) => n.type === "table-cell"
    });

    for (const node of nodes) {
        const [cell, path] = node;
        cells.push({
            cell,
            path,
            realPath: [...path]
        });
    }

    const gridTable = [];
    let insertPosition = null;

    for (let i = 0; i < cells.length; i++) {
        const { cell, path, realPath } = cells[i];
        const { rowspan = 1, colspan = 1 } = cell;
        let y = path[tableDepth];
        let x = path[tableDepth + 1];

        if (!gridTable[y]) {
            gridTable[y] = [];
        }

        while (gridTable[y][x]) {
            x++;
        }

        for (let j = 0; j < rowspan; j++) {
            for (let k = 0; k < colspan; k++) {
                let _y = y + j;
                let _x = x + k;

                if (!gridTable[_y]) {
                    gridTable[_y] = [];
                }

                gridTable[_y][_x] = {
                    cell,
                    path: [...realPath.slice(0, tableDepth), _y, _x],
                    isReal: (rowspan === 1 && colspan === 1) || (_y === y && _x === x),
                    originPath: path
                };

                if (!insertPosition && cell.key === startKey) {
                    insertPosition = gridTable[_y][_x];
                    gridTable[_y][_x].isInsertPosition = true;
                }
            }
        }
    }

    const getCol = (match) => {
        const result = [];

        gridTable.forEach((row) => {
            row.forEach((col) => {
                if (match && match(col)) {
                    result.push(col);
                }
            });
        });

        return result;
    };

    return {
        gridTable,
        tableDepth,
        getCol
    };
};

// export function addSelection(editor, tablePath, startNode, endNode) {
//     removeSelection(editor);

//     if (!tablePath) return [];

//     const { gridTable, getCol } = splittedTable(editor, tablePath, startNode[0]?.key);

//     if (!getCol || !gridTable) return [];

//     let [head] = getCol((n) => Path.equals(Editor.path(editor, n.originPath), startNode[1]) && n.isReal);
//     let [tail] = getCol((n) => Path.equals(Editor.path(editor, n.originPath), endNode[1]) && n.isReal);

//     if (!tail || !head) return [];

//     const { path: tailPath } = tail;
//     const { path: headPath } = head;

//     headPath.forEach((item, index) => {
//         headPath[index] = Math.min(item, tailPath[index]);
//         tailPath[index] = Math.max(item, tailPath[index]);
//     });

//     const coverCols = [];

//     gridTable.forEach((row) => {
//         row.forEach((col) => {
//             const { path } = col;

//             const isOver = path.findIndex((item, index) => {
//                 if (item < headPath[index] || item > tailPath[index]) {
//                     return true;
//                 }
//                 return false;
//             });

//             if (isOver < 0) {
//                 coverCols.push(col);
//             }
//         });
//     });

//     coverCols.forEach(({ originPath }) => {
//         Transforms.setNodes(
//             editor,
//             {
//                 selectedCell: true
//             },
//             {
//                 at: originPath,
//                 match: (n) => n.type === "table-cell"
//             }
//         );
//     });

//     return coverCols;
// }

// export function removeSelection(editor) {
//     Transforms.unsetNodes(editor, "selectedCell", {
//         at: [],
//         match: (n) => !!n.selectedCell
//     });
// }
