import React from "react";

import { FormattedMessage } from "react-intl";

import { Button } from "../../button/Button";
import Col from "../../grid/Col";
import Row from "../../grid/Row";
import { Text } from "../../typography/Text";
import { Popup, PopupContent, PopupFooter, PopupTitle } from "./";

export default class PopupYesNo extends React.Component {
    _onYes = () => {
        if (this.props.onYes) {
            this.props.onYes();
        }
    };

    _onNo = () => {
        if (this.props.onNo) {
            this.props.onNo();
        }
    };

    render() {
        // Properties
        const { title, children, busy } = this.props;

        return (
            <Popup onClose={this.props.onNo}>
                <PopupTitle>{title}</PopupTitle>
                <PopupContent>
                    <Text size="medium">{children}</Text>
                </PopupContent>
                <PopupFooter>
                    <Row gutter={["small", "small"]}>
                        <Col>
                            <Button name="yes" icon="check" iconColor="success" onClick={this._onYes} busy={busy}>
                                <FormattedMessage id="popupYesNo.yes" />
                            </Button>
                        </Col>
                        <Col>
                            <Button name="no" icon="close" iconColor="error" onClick={this._onNo} disabled={busy}>
                                <FormattedMessage id="popupYesNo.no" />
                            </Button>
                        </Col>
                    </Row>
                </PopupFooter>
            </Popup>
        );
    }
}
