import React from "react";
import style from "./task-adder.scss";

import { FormattedMessage } from "react-intl";
import { Adder } from "../../../button/Adder";

const msgKey = "riskTable";

export const TaskAdder = ({ onClick }) => {
    return (
        <div className={style.base}>
            <Adder onClick={onClick} label={<FormattedMessage id={`${msgKey}.newTask`} />} />
        </div>
    );
};
