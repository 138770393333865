import { Editor, Transforms } from "slate";
import { createCell } from "../creator";
import { splittedTable } from "../selection";
import {
    addTableHeaderColumn,
    addTableHeaderRow,
    removeTableHeaderColumn,
    tableHasHeaderColumn,
    tableHasHeaderRow
} from "./tableHeader";

export function insertLeft(tablePath, editor) {
    const { selection } = editor;
    if (!selection || !tablePath) return;
    const hasHeaderRow = tableHasHeaderRow(tablePath, editor);
    const hasHeaderColumn = tableHasHeaderColumn(tablePath, editor);
    if (hasHeaderColumn) {
        removeTableHeaderColumn(tablePath, editor);
    }

    const xIndex = tablePath.length + 1;

    const { gridTable, getCol } = splittedTable(editor, tablePath);

    const [startCell] = Editor.nodes(editor, {
        match: (n) => n.type === "table-cell"
    });

    const [insertPositionCol] = getCol((c) => c.cell.key === startCell[0].key && c.isReal);

    const x = insertPositionCol.path[xIndex];

    const insertCols = new Map();
    let checkInsertable = true;

    gridTable.forEach((row) => {
        const col = row[x];

        if (col.isReal) {
            insertCols.set(col.cell.key, col);
        } else {
            const [originCol] = getCol((n) => n.cell.key === col.cell.key && n.isReal);
            const { cell, path } = originCol;

            if (path[xIndex] === x) {
                insertCols.set(cell.key, originCol);
            } else {
                checkInsertable = false;
                return;
            }
        }
    });

    if (!checkInsertable) {
        return;
    }

    insertCols.forEach((col) => {
        const newCell = createCell({
            rowspan: col.cell.rowspan || 1
        });

        Transforms.insertNodes(editor, newCell, {
            at: col.originPath
        });
    });

    if (hasHeaderRow) {
        addTableHeaderRow(tablePath, editor);
    }

    if (hasHeaderColumn) {
        addTableHeaderColumn(tablePath, editor);
    }
}
