import React from "react";

import { FormattedMessage } from "react-intl";
import { Button } from "../../button/Button";
import { ShieldLogo } from "../../graphic/ShieldLogo/ShieldLogo";
import Col from "../../grid/Col";
import Row from "../../grid/Row";
import { PageMessage } from "../../page/PageMessage";
import { Text } from "../../typography/Text";

const msgKey = "freeVesselAccountMessage";

export const FreeVesselAccountMessage = ({ days }) => {
    return (
        <PageMessage title={<FormattedMessage id={`${msgKey}.title`} />} figure={<ShieldLogo size={100} />}>
            <Row gutter={["large", "large"]}>
                <Col span={24}>
                    <Text size="xlarge" weight="semiBold" color="success">
                        <FormattedMessage id={`${msgKey}.freeDaysLeft`} values={{ days: days }} />
                    </Text>
                    <Text block size="medium" color="base80">
                        <FormattedMessage id={`${msgKey}.message`} />
                    </Text>
                </Col>
                <Col span={24}>
                    <Button link={`/me/help`} color="success" icon="account" size="big">
                        <FormattedMessage id={`${msgKey}.askForExtension`} />
                    </Button>
                </Col>
            </Row>
        </PageMessage>
    );
};
