import React from "react";
import styles from "./TimeIntervalSelect.scss";

import { FormattedMessage } from "react-intl";
import Row from "../../grid/Row";
import Col from "../../grid/Col";
import { Select, Option } from "../Select";
import { SingleDatePicker } from "../../time/SingleDatePicker";

const msgKey = "timeIntervalSelect";
const units = {
    DAILY: {
        everyLabel: <FormattedMessage id={`${msgKey}.everyForDay`} />,
        unitLabel: <FormattedMessage id={`${msgKey}.day`} />
    },
    WEEKLY: {
        everyLabel: <FormattedMessage id={`${msgKey}.everyForWeek`} />,
        unitLabel: <FormattedMessage id={`${msgKey}.week`} />
    },
    MONTHLY: {
        everyLabel: <FormattedMessage id={`${msgKey}.everyForMonth`} />,
        unitLabel: <FormattedMessage id={`${msgKey}.month`} />
    },
    YEARLY: {
        everyLabel: <FormattedMessage id={`${msgKey}.everyForYear`} />,
        unitLabel: <FormattedMessage id={`${msgKey}.year`} />
    }
};

export const TimeIntervalSelect = ({ unit, frequency, date, onChange, nullable = false, dateUnit = false }) => {
    const handleUnitChange = (unit) => {
        onChange &&
            onChange({
                unit: unit !== "NONE" ? unit : null,
                frequency: null,
                date: null
            });
    };

    const handleFrequencyChange = (frequency) => {
        onChange &&
            onChange({
                unit,
                frequency,
                date: null
            });
    };

    const handleDateChange = (date) => {
        onChange &&
            onChange({
                unit: "DATE",
                frequency: null,
                date: date
            });
    };

    return (
        <Row className={styles.base} gutter={["small", "small"]}>
            <Col sm={{ span: 10 }}>
                <Select
                    value={unit}
                    onChange={handleUnitChange}
                    placeholder={<FormattedMessage id={`${msgKey}.none`} />}
                >
                    {/* Be able to select none */}
                    {nullable && unit ? (
                        <Option value="NONE">
                            <FormattedMessage id={`${msgKey}.none`} />
                        </Option>
                    ) : null}

                    <Option value="DAILY">
                        <FormattedMessage id={`${msgKey}.daily`} />
                    </Option>
                    <Option value="WEEKLY">
                        <FormattedMessage id={`${msgKey}.weekly`} />
                    </Option>
                    <Option value="MONTHLY">
                        <FormattedMessage id={`${msgKey}.monthly`} />
                    </Option>
                    <Option value="YEARLY">
                        <FormattedMessage id={`${msgKey}.yearly`} />
                    </Option>

                    {/* Static date as option */}
                    {dateUnit ? (
                        <Option value="DATE">
                            <FormattedMessage id={`${msgKey}.date`} />
                        </Option>
                    ) : null}
                </Select>
            </Col>

            {/* Units */}
            {unit && unit !== "DATE" && unit !== "NONE" ? (
                <Col sm={{ span: 14 }}>
                    <Row>
                        <Col className={styles.textAlignRight} sm={{ span: 6 }} xs={{ span: 8 }}>
                            {units[unit].everyLabel}
                        </Col>
                        <Col className={styles.textAlignLeft} sm={{ span: 8 }} xs={{ span: 8 }}>
                            <Select value={frequency} onChange={handleFrequencyChange}>
                                {Array.apply(null, new Array(50)).map((_, index) => (
                                    <Option key={index} value={index + 1}>
                                        {index + 1}.
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col className={styles.textAlignLeft} sm={{ span: 10 }} xs={{ span: 8 }}>
                            {units[unit].unitLabel}
                        </Col>
                    </Row>
                </Col>
            ) : null}

            {/* Fixed date */}
            {unit && unit === "DATE" ? (
                <Col sm={{ span: 14 }}>
                    <SingleDatePicker value={date} onChange={handleDateChange} />
                </Col>
            ) : null}
        </Row>
    );
};
