import classNames from "classnames";
import React, { useRef, useState, useCallback, useEffect } from "react";
import Dropzone from "react-dropzone";
import { Icon } from "../../../graphic/Icon";
import styles from "./Tools.scss";
import { Arrow } from "../../../graphic/Arrow";

export const Tools = ({ children }) => {
    return <div className={styles.base}>{children}</div>;
};

export const ToolItem = ({ icon, label, content, onClick, onFileSelect, disabled }) => {
    const dropZoneRef = useRef();
    const buttonRef = useRef();
    const contentRef = useRef();
    const [showContent, setShowContent] = useState(false);

    const handleClick = (e) => {
        if (content) {
            setShowContent(!showContent);
        } else if (onFileSelect) {
            dropZoneRef.current.open();
        } else {
            onClick && onClick(e);
        }
    };

    const handleClickOutside = useCallback((e) => {
        setTimeout(() => {
            setShowContent(false);
        }, 100);
    }, []);

    useEffect(() => {
        if (showContent) {
            document.addEventListener("mousedown", handleClickOutside);
            document.addEventListener("touchstart", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        };
    }, [showContent, handleClickOutside]);

    return (
        <div className={styles.item}>
            <button
                ref={buttonRef}
                className={classNames(styles.button, {
                    [styles.active]: showContent && !!content
                })}
                onClick={handleClick}
                disabled={disabled}
            >
                <Icon name={icon} />
                {label && <div className={styles.label}>{label}</div>}
                {onFileSelect && (
                    <Dropzone
                        className={styles.dropzone}
                        ref={dropZoneRef}
                        onDrop={(files) => onFileSelect(files?.[0] || null)}
                        multiple={false}
                        accept={"image/*"}
                    />
                )}
            </button>
            {!!content && (
                <div
                    ref={contentRef}
                    className={classNames(styles.content, {
                        [styles.visible]: showContent
                    })}
                >
                    <Arrow className={styles.arrow} color="contrast" />
                    <div>{content}</div>
                </div>
            )}
        </div>
    );
};
