import classNames from "classnames";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { Clickable } from "../../../button/Clickable";
import { Icon } from "../../../graphic/Icon";
import { Menu, MenuItem } from "../../../nav/Menu";
import { PopOver } from "../../../nav/PopOver";
import styles from "./Choices.scss";

const msgKey = "pageHeader";

export const Choices = ({ choices, pinned }) => {
    const history = useHistory();
    const [visible, setVisible] = useState(false);

    const handleChoiceClick = (choice) => {
        setVisible(false);
        if (choice.link) {
            history.push(choice.link);
        } else if (choice.handler) {
            choice.handler();
        }
    };

    return (
        <PopOver
            visible={visible}
            onClose={() => setVisible(false)}
            layer="nav"
            align="end"
            content={
                <Menu className={styles.nav}>
                    {choices &&
                        choices
                            .filter((c) => !c.hidden)
                            .map((choice, index) => (
                                <MenuItem
                                    key={index}
                                    icon={choice.icon}
                                    label={choice.label}
                                    onClick={() => handleChoiceClick(choice)}
                                />
                            ))}
                </Menu>
            }
        >
            <Clickable
                className={classNames(styles.button, { [styles.active]: visible, [styles.pinned]: pinned })}
                onClick={() => setVisible(!visible)}
                cy="actions"
            >
                <div className={styles.label}>
                    <FormattedMessage id={`${msgKey}.choices`} />
                </div>
                <div className={styles.symbol}>
                    <Icon className={styles.icon} name={visible ? "close" : "more"} />
                </div>
            </Clickable>
        </PopOver>
    );
};
