import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { AuthContext } from "../../../../contexts/Auth";
import { Clickable } from "../../../button/Clickable";
import { Icon } from "../../../graphic/Icon";
import { TextLogo } from "../../../graphic/TextLogo";
import Image from "../../../media/Image";
import { ContentWrapper } from "../../ContentWrapper";
import styles from "./UserPane.scss";
const msgKey = "userPane";

export const UserPane = ({ children }) => {
    const { me } = useContext(AuthContext);

    const hours = new Date().getHours();
    let partOfDay;
    if (hours >= 5 && hours < 12) {
        partOfDay = "MORNING";
    } else if (hours >= 12 && hours < 17) {
        partOfDay = "AFTERNOON";
    } else if (hours >= 17) {
        partOfDay = "EVENING";
    } else {
        partOfDay = "NIGHT";
    }

    return (
        <div className={styles.base}>
            <div className={styles.bar}>
                <ContentWrapper>
                    <div className={styles.logo}>
                        <TextLogo color="contrast" />
                    </div>
                </ContentWrapper>
            </div>

            <div className={styles.center}>
                <ContentWrapper>
                    <div className={styles.user}>
                        <Clickable link="/me/profile" className={styles.picture}>
                            {me?.user.picture ? (
                                <Image size="fill" alt={me?.user.fullName} src={me?.user.picture?.url} />
                            ) : (
                                <Icon name="user" className={styles.icon} />
                            )}
                            <div className={styles.shine} />
                        </Clickable>
                        <div className={styles.details}>
                            <div className={styles.greeting}>
                                <FormattedMessage id={`${msgKey}.${partOfDay}`} />,
                            </div>
                            <div className={styles.name}>{me?.user.firstName}</div>
                        </div>
                    </div>
                </ContentWrapper>
            </div>

            {/* Menu */}
            <div className={styles.menu}>
                <ContentWrapper>{children}</ContentWrapper>
            </div>
        </div>
    );
};
