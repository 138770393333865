import React from "react";
import classNames from "classnames";
import style from "./style.scss";

import { Spinner } from "../../loaders/Spinner";

export default class Curtain extends React.Component {
    state = {
        visible: false
    };

    componentDidMount() {
        this.showTimeout = setTimeout(() => {
            this.setState({
                visible: true
            });
        }, 500);
    }

    componentWillUnmount() {
        clearTimeout(this.showTimeout);
    }

    render() {
        // Variables
        const { visible } = this.state;

        // Classes
        const baseClasses = classNames(style.base, {
            [style.visible]: visible
        });

        return (
            <div className={baseClasses}>
                <div className={style.box}>
                    <Spinner large contrast />
                </div>
            </div>
        );
    }
}
