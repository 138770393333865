import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";
import { BREAKPOINTS } from "../../../constants";
import { useDebounce } from "../../../utils/hooks";

export const WindowResizeListener = () => {
    const { pageInfo, setPageInfo } = useContext(AppContext);
    const [debounceKey, setDebounceKey] = useState(null);

    const calculateBreakpoint = () => {
        const windowWidth = window.innerWidth;
        let breakpointKeys = Object.keys(BREAKPOINTS);
        const breakpoint = breakpointKeys.reduce((agg, key) => {
            const keyNumber = parseInt(key, 10);
            if (windowWidth <= keyNumber && (agg === null || keyNumber < agg)) {
                return keyNumber;
            } else {
                return agg;
            }
        }, breakpointKeys[breakpointKeys.length - 1]);
        return BREAKPOINTS[breakpoint];
    };

    useDebounce(
        () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            setPageInfo({
                ...pageInfo,
                windowWidth: window.innerWidth,
                windowHeight: window.innerHeight,
                breakpoint: calculateBreakpoint()
            });
        },
        [debounceKey],
        100,
        false
    );

    useEffect(() => {
        const handleResize = () => {
            setDebounceKey(Math.random());
        };
        window?.addEventListener("resize", handleResize, false);
        return () => {
            window?.removeEventListener("resize", handleResize, false);
        };
    }, []);

    return null;
};
