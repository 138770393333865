import React from "react"
import style from "./style.scss"


export default class PageBar extends React.Component {
    render(){
        return (
            <div className={style.base}>
                <div className={style.wrapper}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}
