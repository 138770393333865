import React from "react";
import { FormattedMessage } from "react-intl";
import Badge from "../../misc/Badge";

const statusMap = {
    incomplete: {
        color: "error"
    },
    incomplete_expired: {
        color: "error"
    },
    trialing: {
        color: "info"
    },
    active: {
        color: "success"
    },
    past_due: {
        color: "error"
    },
    canceled: {
        color: "error"
    },
    unpaid: {
        color: "error"
    }
};


export const SubscriptionStatus = ({status}) => {
    return statusMap[status] ? (
        <Badge color={statusMap[status].color}>
            <FormattedMessage id={`subscriptionStatus.${status}`} />
        </Badge>
    ) : null;
}
