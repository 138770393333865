import React, { useEffect, useRef, useState } from "react";
import style from "./Steps.scss";

const STEP_MIN_WIDTH = 240;

export const Steps = ({ children, activeStep }) => {
    const [baseWidth, setBaseWidth] = useState(0);
    const [contentHeight, setContentHeight] = useState(0);

    const containerRef = useRef(null);
    const baseRef = useRef(null);
    const updateDimensionsTimeout = useRef(null);

    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => {
            window.removeEventListener("resize", updateDimensions);
            clearTimeout(updateDimensionsTimeout.current);
        };
    }, []);

    const updateDimensions = () => {
        clearTimeout(updateDimensionsTimeout.current);
        updateDimensionsTimeout.current = setTimeout(() => {
            // Content height
            if (containerRef.current) {
                setContentHeight(containerRef.current.getBoundingClientRect().height);
            }

            // Base width
            if (baseRef.current) {
                setBaseWidth(baseRef.current.getBoundingClientRect().width);
            }
        }, 100);
    };

    const numberOfSteps = children.length;

    let stepWidth = baseWidth / numberOfSteps;
    stepWidth = stepWidth < STEP_MIN_WIDTH ? STEP_MIN_WIDTH : stepWidth;
    const pixelsNeeded = activeStep * stepWidth;

    let offset = 0;
    if (pixelsNeeded > baseWidth) {
        offset = pixelsNeeded - baseWidth + 30;
    }

    return (
        <div ref={baseRef} className={style.base} style={{ height: `${contentHeight + 10}px` }}>
            <div className={style.leftFade} style={{ opacity: offset === 0 ? 0 : 1 }} />
            <div className={style.rightFade} />

            <div ref={containerRef} className={style.container} style={{ marginLeft: -offset + "px" }}>
                {React.Children.map(children, (child, index) =>
                    React.cloneElement(child, {
                        current: activeStep,
                        number: index + 1
                    })
                )}
            </div>
        </div>
    );
};
