import React, { forwardRef, useEffect, useState } from "react";
import { mimeTypesMap } from "../../../utils/fileTypeUtils";

export const HiddenFileSelectTrigger = forwardRef(({ accept = "images", multiple = false, onSelect }, ref) => {
    const [acceptTypes, setAcceptTypes] = useState(null);

    useEffect(() => {
        if (accept) {
            setAcceptTypes(() => {
                const acceptTypes = accept.split(",").reduce((agg, typeName) => {
                    const type = mimeTypesMap[typeName];
                    if (type) {
                        agg += type + ",";
                    }
                    return agg;
                }, "");
                return acceptTypes.slice(0, -1);
            });
        }
    }, [accept]);

    return (
        <input
            style={{ display: "none" }}
            type="file"
            accept={acceptTypes}
            ref={ref}
            onChange={(e) => onSelect && onSelect(Array.from(e.target.files || []))}
            multiple={multiple}
        />
    );
});
