import { Editor, Path, Transforms } from "slate";
import { createCell } from "../creator";
import { splittedTable } from "../selection";
import { addTableHeaderRow, removeTableHeaderRow, tableHasHeaderRow } from "./tableHeader";

export function insertRight(tablePath, editor) {
    const { selection } = editor;
    if (!selection || !tablePath) return;
    const hasHeaderRow = tableHasHeaderRow(tablePath, editor);
    const xIndex = tablePath.length + 1;

    const { gridTable, getCol } = splittedTable(editor, tablePath);
    const [startCell] = Editor.nodes(editor, {
        match: (n) => n.type === "table-cell"
    });

    const [insertPositionCol] = getCol((c) => c.cell.key === startCell[0].key && c.isReal);

    const x = insertPositionCol.path[xIndex] + (insertPositionCol.cell.colspan || 1) - 1;

    const insertCols = new Map();
    let checkInsertable = true;

    gridTable.forEach((row) => {
        const col = row[x];

        const [originCol] = getCol((n) => n.cell.key === col.cell.key && n.isReal);

        const { cell, path } = originCol;

        if (!row[x + 1] || (col.isReal && (!col.cell.colspan || col.cell.colspan === 1))) {
            insertCols.set(cell.key, originCol);
        } else {
            if (path[xIndex] + (cell.colspan || 1) - 1 === x) {
                insertCols.set(cell.key, originCol);
            } else {
                checkInsertable = false;
                return;
            }
        }
    });

    if (!checkInsertable) {
        return;
    }

    insertCols.forEach((col) => {
        const newCell = createCell({
            rowspan: col.cell.rowspan || 1
        });

        Transforms.insertNodes(editor, newCell, {
            at: Path.next(col.originPath)
        });
    });

    if (hasHeaderRow) {
        removeTableHeaderRow(tablePath, editor);
        addTableHeaderRow(tablePath, editor);
    }
}
