import classNames from "classnames";
import React from "react";
import styles from "./PillSelector.scss";
import { Icon } from "../../graphic/Icon";
import { Clickable } from "../Clickable";

export const PillSelector = React.forwardRef(({ label, prominent, active, onClick }, ref) => {
    return (
        <div
            ref={ref}
            className={classNames(styles.base, {
                [styles.active]: active,
                [styles.prominent]: prominent
            })}
        >
            <Clickable className={styles.button} onClick={onClick}>
                <div className={styles.arrow}>
                    <Icon name="chevron-right" />
                </div>
                <div className={styles.label}>{label}</div>
            </Clickable>
        </div>
    );
});
