import React from "react";
import style from "./style.scss";

export const BoxFooter = ({ children }) => {
    return (
        <div className={style.footer}>
            <div className={style.wrapper}>{children}</div>
        </div>
    );
};
